import React from 'react'
import { SalesInsightInfo } from './sales-insight-info/sales-insight-info'
import styles from './sales-insight.scss'

export const SalesInsight = () => {
  return (
    <div className={styles.root}>
      <SalesInsightInfo />
    </div>
  )
}
