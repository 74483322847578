import React, { useMemo } from 'react'
import { useSelector } from 'react-redux'
import { ChartBarCompare } from 'src/component/molecule/chart-bar-compare/chart-bar-compare'
import { ChartBarMonthly } from 'src/component/molecule/chart-bar-monthly/chart-bar-monthly'
import { ChartLine } from 'src/component/molecule/chart-line/chart-line'
import { DataTable, DataTableColumnDefsType } from 'src/component/molecule/data-table/data-table'
import { ExpantionPanel } from 'src/component/molecule/expantion-panel/expantion-panel'
import { dashboardSelector } from 'src/redux/dashboard/dashboard.state'
import { DashboardCompanyType } from 'src/type/dashboard.type'
import styles from './transaction-analysis.scss'

type PropsType = {
  dsCompany: DashboardCompanyType
}

export const TransactionAnalysis = ({ dsCompany }: PropsType) => {
  const dsCompanyRanks = useSelector(dashboardSelector.dashboardCompanyRanks)
  const dsCompanyGradeCumulativeRates = useSelector(dashboardSelector.dashboardCompanyGradeCumuldativeRates)
  const dashboardCompanyGradeInfoRecords = useSelector(dashboardSelector.dashboardCompanyGradeInfoRecords)

  const loan1TableDatas = useMemo(() => {
    let prevGrade = 0
    const resDatas = dsCompanyGradeCumulativeRates
      ? dsCompanyGradeCumulativeRates.loan1CumulativeRate.map((rate, index) => {
          const res = {
            grade: `${10 - index}`,
            expectedRatio:
              dashboardCompanyGradeInfoRecords?.shortTermGrades[`${10 - index}`]?.expectedRatio !== null ||
              dashboardCompanyGradeInfoRecords?.shortTermGrades[`${10 - index}`]?.expectedRatio !== undefined
                ? `${new Intl.NumberFormat('ko-KR', {
                    maximumFractionDigits: 2,
                  }).format(
                    Number(dashboardCompanyGradeInfoRecords?.shortTermGrades[`${10 - index}`]?.expectedRatio) * 100,
                  )} %`
                : '-',
            scoreRank: `${prevGrade} ~ ${rate.percentage} %`,
          }
          prevGrade = rate.percentage

          return res
        })
      : []

    resDatas.push({
      grade: '全体平均',
      expectedRatio:
        dashboardCompanyGradeInfoRecords?.shortTermGrades['total']?.expectedRatio !== null ||
        dashboardCompanyGradeInfoRecords?.shortTermGrades['total']?.expectedRatio !== undefined
          ? `${new Intl.NumberFormat('ko-KR', {
              maximumFractionDigits: 2,
            }).format(Number(dashboardCompanyGradeInfoRecords?.shortTermGrades['total']?.expectedRatio) * 100)} %`
          : '-',
      scoreRank: '-',
    })

    return resDatas
  }, [dsCompanyGradeCumulativeRates])

  const loan2TableDatas = useMemo(() => {
    let prevGrade = 0
    const resDatas = dsCompanyGradeCumulativeRates
      ? dsCompanyGradeCumulativeRates.loan2CumulativeRate.map((rate, index) => {
          const res = {
            grade: `${10 - index}`,
            expectedRatio:
              dashboardCompanyGradeInfoRecords?.longTermGrades[`${10 - index}`]?.expectedRatio !== null ||
              dashboardCompanyGradeInfoRecords?.longTermGrades[`${10 - index}`]?.expectedRatio !== undefined
                ? `${new Intl.NumberFormat('ko-KR', {
                    maximumFractionDigits: 2,
                  }).format(
                    Number(dashboardCompanyGradeInfoRecords?.longTermGrades[`${10 - index}`]?.expectedRatio) * 100,
                  )} %`
                : '-',
            scoreRank: `${prevGrade} ~ ${rate.percentage} %`,
          }
          prevGrade = rate.percentage

          return res
        })
      : []

    resDatas.push({
      grade: '全体平均',
      expectedRatio:
        dashboardCompanyGradeInfoRecords?.longTermGrades['total']?.expectedRatio !== null ||
        dashboardCompanyGradeInfoRecords?.longTermGrades['total']?.expectedRatio !== undefined
          ? `${new Intl.NumberFormat('ko-KR', {
              maximumFractionDigits: 2,
            }).format(Number(dashboardCompanyGradeInfoRecords?.longTermGrades['total']?.expectedRatio) * 100)} %`
          : '-',
      scoreRank: '-',
    })

    return resDatas
  }, [dsCompanyGradeCumulativeRates])

  return (
    <>
      <ExpantionPanel title='融資営業に関するインサイト' contentHeight={1880} initailExpantion={true}>
        <div className={styles.chartDesc}>
          <span>
            {`※ 下記に記載されている期待成約率はReady AI内の検証データ上の結果であり、実運用時の成約率を約束するものではありません。あくまでどの程度の成約可能性があるかの参考情報としてご利用ください。`}
          </span>
          <div className={styles.gradeSelectDesc}>
            <div className={styles.selectedColorBullet} />
            <span>{`: この取引先が該当するニーズ等級`}</span>
          </div>
        </div>
        <div className={styles.chartWrapper}>
          <ChartBarCompare
            chartHight={300}
            title='AIスコア（短期）'
            yAxisTitle='スコア'
            yUnit={0.0001}
            valueDecimals={2}
            xTickValues={dsCompany.dsCompanyDetails?.dsCompanyDetailTransactions?.chartLoan1Score?.xValues}
            series={dsCompany.dsCompanyDetails?.dsCompanyDetailTransactions?.chartLoan1Score?.series}
          />
          <div className={styles.dataTableWrapper}>
            <DataTable
              dataColumnDefs={gradeColumnDefObjs}
              dataList={loan1TableDatas}
              rowHeight={23}
              selectedRowIndex={dsCompany.loan1Grade ? 10 - +dsCompany.loan1Grade : undefined}
            />
          </div>
        </div>
        {dsCompanyRanks?.loan1 && (
          <div className={styles.solutionDescription}>{`${
            dsCompany.isMainBank === 'true' ? '融資取引がいま存在する' : '融資取引がいま存在しない'
          }取引先${new Intl.NumberFormat('ko-KR', {
            maximumFractionDigits: 2,
          }).format(
            Number(dsCompanyRanks.loan1.total),
          )}社に絞った場合、当社の短期融資ニーズスコアは${new Intl.NumberFormat('ko-KR', {
            maximumFractionDigits: 2,
          }).format(
            Number(dsCompanyRanks.loan1.rank),
          )}位となります。一方で上記表は、融資取引あり・なし関係なく集計した数値となります。`}</div>
        )}
        <div className={styles.chartWrapper}>
          <ChartBarCompare
            chartHight={300}
            title='AIスコア（長期）'
            yAxisTitle='スコア'
            yUnit={0.0001}
            valueDecimals={2}
            xTickValues={dsCompany.dsCompanyDetails?.dsCompanyDetailTransactions?.chartLoan2Score?.xValues}
            series={dsCompany.dsCompanyDetails?.dsCompanyDetailTransactions?.chartLoan2Score?.series}
          />
          <div className={styles.dataTableWrapper}>
            <DataTable
              dataColumnDefs={gradeColumnDefObjs}
              dataList={loan2TableDatas}
              rowHeight={23}
              selectedRowIndex={dsCompany.loan2Grade ? 10 - +dsCompany.loan2Grade : undefined}
            />
          </div>
        </div>
        {dsCompanyRanks?.loan2 && (
          <div className={styles.solutionDescription}>{`${
            dsCompany.isMainBank === 'true' ? '融資取引がいま存在する' : '融資取引がいま存在しない'
          }取引先${new Intl.NumberFormat('ko-KR', {
            maximumFractionDigits: 2,
          }).format(
            Number(dsCompanyRanks.loan2.total),
          )}社に絞った場合、当社の長期融資ニーズスコアは${new Intl.NumberFormat('ko-KR', {
            maximumFractionDigits: 2,
          }).format(
            Number(dsCompanyRanks.loan2.rank),
          )}位となります。一方で上記表は、融資取引あり・なし関係なく集計した数値となります。`}</div>
        )}
        <div className={styles.chartWrapper}>
          <ChartBarMonthly
            chartHight={300}
            title='資金不足までの残り期間（単位：か月）'
            subTitle='※ 月末残高÷直近6か月の月次平均出金額にて計算。'
            yAxisTitle='か月'
            valueDecimals={0}
            minYvalue={0}
            xTickValues={dsCompany.dsCompanyDetails?.dsCompanyDetailTransactions?.chartRunway?.xValues}
            series={dsCompany.dsCompanyDetails?.dsCompanyDetailTransactions?.chartRunway?.series}
          />
          <ChartLine
            chartHight={300}
            title='残高推移（単位：千円）'
            yAxis1Title='千円(当社)'
            yAxis2Title='千円(同業平均)'
            y1Unit={1000}
            y2Unit={1000}
            valueDecimals={0}
            xTickValues={dsCompany.dsCompanyDetails?.dsCompanyDetailTransactions?.chartBalanceTrend?.xValues}
            series={dsCompany.dsCompanyDetails?.dsCompanyDetailTransactions?.chartBalanceTrend?.series}
          />
          <ChartLine
            chartHight={300}
            title='月次入金額の推移（単位：千円）'
            yAxis1Title='千円(当社)'
            yAxis2Title='千円(同業平均)'
            y1Unit={1000}
            y2Unit={1000}
            valueDecimals={0}
            xTickValues={dsCompany.dsCompanyDetails?.dsCompanyDetailTransactions?.chartDepositTrend?.xValues}
            series={dsCompany.dsCompanyDetails?.dsCompanyDetailTransactions?.chartDepositTrend?.series}
          />
          <ChartLine
            chartHight={300}
            title='月次出金額の推移（単位：千円）'
            yAxis1Title='千円(当社)'
            yAxis2Title='千円(同業平均)'
            y1Unit={1000}
            y2Unit={1000}
            valueDecimals={0}
            xTickValues={dsCompany.dsCompanyDetails?.dsCompanyDetailTransactions?.chartWithdrawalTrend?.xValues}
            series={dsCompany.dsCompanyDetails?.dsCompanyDetailTransactions?.chartWithdrawalTrend?.series}
          />
          <ChartLine
            chartHight={300}
            title='月次損益（月次入金-月次出金）（単位：千円）'
            yAxis1Title='千円(当社)'
            yAxis2Title='千円(同業平均)'
            y1Unit={1000}
            y2Unit={1000}
            valueDecimals={0}
            xTickValues={dsCompany.dsCompanyDetails?.dsCompanyDetailTransactions?.chartGain?.xValues}
            series={dsCompany.dsCompanyDetails?.dsCompanyDetailTransactions?.chartGain?.series}
          />
        </div>
      </ExpantionPanel>
      <ExpantionPanel title='事業支援に関するインサイト' contentHeight={670} initailExpantion={true}>
        <div className={styles.chartWrapper}>
          <ChartBarCompare
            chartHight={300}
            title='代表者年齢'
            xTickValues={dsCompany.dsCompanyDetails?.dsCompanyDetailTransactions?.chartCeoAge?.xValues}
            yAxisTitle='年齢'
            series={dsCompany.dsCompanyDetails?.dsCompanyDetailTransactions?.chartCeoAge?.series}
          />
          <ChartLine
            chartHight={300}
            title='給与支払額の推移（単位：千円）'
            yAxis1Title='千円(当社)'
            yAxis2Title='千円(同業平均)'
            y1Unit={1000}
            y2Unit={1000}
            valueDecimals={0}
            xTickValues={dsCompany.dsCompanyDetails?.dsCompanyDetailTransactions?.chartSalary?.xValues}
            series={dsCompany.dsCompanyDetails?.dsCompanyDetailTransactions?.chartSalary?.series}
          />
          <ChartLine
            chartHight={300}
            title='社会保険料支払額の推移（単位：千円）'
            yAxis1Title='千円(当社)'
            yAxis2Title='千円(同業平均)'
            y1Unit={1000}
            y2Unit={1000}
            valueDecimals={0}
            xTickValues={dsCompany.dsCompanyDetails?.dsCompanyDetailTransactions?.chartInsuranceFee?.xValues}
            series={dsCompany.dsCompanyDetails?.dsCompanyDetailTransactions?.chartInsuranceFee?.series}
          />
          <ChartLine
            chartHight={300}
            title='法人税の支払い比率（%)'
            subTitle='※ 月次損益対比での法人税の支払額の比率'
            yAxis1Title='%'
            valueDecimals={2}
            minYvalue={0}
            xTickValues={dsCompany.dsCompanyDetails?.dsCompanyDetailTransactions?.chartCorporateTax?.xValues}
            series={dsCompany.dsCompanyDetails?.dsCompanyDetailTransactions?.chartCorporateTax?.series}
          />
        </div>
      </ExpantionPanel>
    </>
  )
}

const gradeColumnDefObjs: DataTableColumnDefsType[] = [
  {
    dataKey: 'grade',
    label: 'ニーズ等級',
    width: 110,
    headerBold: true,
    headerAlign: 'center',
    cellAlign: 'center',
  },
  {
    dataKey: 'scoreRank',
    label: 'ニーズ上位N%',
    flexRate: 1,
    headerBold: true,
    headerAlign: 'center',
    cellAlign: 'center',
  },
  {
    dataKey: 'expectedRatio',
    label: '期待成約率',
    flexRate: 1,
    headerBold: true,
    headerAlign: 'center',
    cellAlign: 'center',
  },
]
