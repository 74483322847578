import {
  ProjectType,
  ProjectTypesEnum,
  ProjectDetailType,
  ProjectGoalType,
  SegmentType,
  ResultTargetsType,
  FilterContentType,
} from 'src/type/project.type'
import { httpApi } from 'src/util/http.module'

const fetchProjects = () => httpApi.get<ProjectType[]>({ url: '/project' })

export type FetchProjectDetailParamsType = {
  id: number
}
const fetchProjectDetail = (params: FetchProjectDetailParamsType) =>
  httpApi.get<ProjectDetailType>({ url: `/project/${params.id}` })

export type CreateProjectParamsType = {
  type: ProjectTypesEnum
  name: string
}
const createProject = (params: CreateProjectParamsType) =>
  httpApi.post<ProjectDetailType>({ url: '/project', body: params })

export type DeleteProjectParamsType = {
  id: number
}
const deleteProject = (params: DeleteProjectParamsType) => httpApi.delete({ url: `/project/${params.id}` })

export type UpdateProjectParamsType = {
  id: number
  name: string
}
const updateProject = (params: UpdateProjectParamsType) =>
  httpApi.put<ProjectType>({
    url: `/project/${params.id}`,
    body: {
      name: params.name,
    },
  })

export type FetchProjectGoalParamsType = {
  projectType: ProjectTypesEnum
}
const fetchProjectGoal = (params: FetchProjectGoalParamsType) =>
  httpApi.get<ProjectGoalType[]>({ url: '/projectgoal', queryParams: params })

export type UpdateProjectSettingParamsType = {
  projectId: number
  goalId: number
  value: number | string
}
const updateProjectSetting = (params: UpdateProjectSettingParamsType) =>
  httpApi.post({
    url: `/project/${params.projectId}/goal/${params.goalId}`,
    body: { value: params.value },
  })

export type DeleteProjectSettingParamsType = {
  projectId: number
  goalId: number
}
const deleteProjectSetting = (params: DeleteProjectSettingParamsType) =>
  httpApi.delete({
    url: `/project/${params.projectId}/goal/${params.goalId}`,
  })

export type TrainingProjectParamsType = {
  projectId: number
}
const trainingProject = (params: TrainingProjectParamsType) =>
  httpApi.post({
    url: `/project/${params.projectId}/preprocess`,
  })

export type FetchProjectResultTargetsParamsType = {
  id: number
  limit?: number
  filters?: FilterContentType[]
}
const fetchProjectResultTargets = (params: FetchProjectResultTargetsParamsType) =>
  httpApi.post<ResultTargetsType>({
    url: `/project/${params.id}/targets`,
    body: { limit: params.limit, filters: params.filters },
  })

export type FetchProjectResultSegmentsParamsType = {
  id: number
}
const fetchProjectResultSegments = (params: FetchProjectResultSegmentsParamsType) =>
  httpApi.get<SegmentType[]>({ url: `/project/${params.id}/segments` })

export type DownloadProjectResultTargetsParamsType = {
  projectId: number
  filters: FilterContentType[]
}
const downloadProjectResultTargets = (params: DownloadProjectResultTargetsParamsType) =>
  httpApi.post({ url: `/project/${params.projectId}/resulttargetsfile`, body: { filters: params.filters } })

// TEMPORARY
export type TempCallCreateDashboardParamsType = {
  projectId: number
}
const tempCallCreateDashboard = (params: TempCallCreateDashboardParamsType) =>
  httpApi.post({ url: `/project/${params.projectId}/dashboardtask` })

export const projectService = {
  fetchProjects,
  fetchProjectDetail,
  createProject,
  deleteProject,
  updateProject,
  fetchProjectGoal,
  updateProjectSetting,
  deleteProjectSetting,
  trainingProject,
  fetchProjectResultTargets,
  fetchProjectResultSegments,
  downloadProjectResultTargets,
  tempCallCreateDashboard,
}
