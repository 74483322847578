import Highcharts from 'highcharts'
import HighchartsReact from 'highcharts-react-official'
import HighchartsMore from 'highcharts/highcharts-more'
import HighchartsAccessibility from 'highcharts/modules/accessibility'
import HighchartsNoDataToDisplay from 'highcharts/modules/no-data-to-display'
import HighchartsSeriesLabel from 'highcharts/modules/series-label'
import React, { useEffect, useRef } from 'react'
import styles from './chart-bubble.scss'

type PropsType = {
  title?: string
  subTitle?: string
  chartHight?: number
  yAxisTitle?: string
  xAxisTitle?: string
  zSizeTitle?: string
  yUnit?: number
  valueDecimals?: number
  xTickValues?: string[]
  selectedItemName?: string
  itemColors?: { [name: string]: string }
  series?: { data: { x: number; y: number; z: number; name: string }[] }
  onChangeSelectedItem?(itemName?: string): void
}

export const ChartBubble = (props: PropsType) => {
  HighchartsMore(Highcharts)
  HighchartsSeriesLabel(Highcharts)
  HighchartsNoDataToDisplay(Highcharts)
  HighchartsAccessibility(Highcharts)

  const {
    title,
    subTitle,
    chartHight,
    xAxisTitle,
    yAxisTitle,
    zSizeTitle,
    yUnit = 1,
    valueDecimals,
    xTickValues,
    selectedItemName,
    itemColors,
    series,
    onChangeSelectedItem,
  } = props

  const chartTitle: Highcharts.TitleOptions = {
    text: title,
    align: 'left',
    margin: subTitle ? 40 : 55,
    style: {
      fontSize: '16px',
      fontWeight: '600',
    },
  }
  const chartYAxis: Highcharts.YAxisOptions = {
    plotLines: [{ width: 1, color: '#888', value: 0, zIndex: 1 }],
    title: {
      text: yAxisTitle,
    },
  }
  const chartXAxis: Highcharts.XAxisOptions = {
    categories: xTickValues,
    tickmarkPlacement: 'on',
    tickLength: 10,
    tickWidth: 1,
    gridLineWidth: 1,
    plotLines: [{ width: 1, color: '#888', value: 0, zIndex: 1 }],
    title: {
      text: xAxisTitle,
    },
  }
  const dataSeries: (Highcharts.SeriesOptionsType & { colorByPoint: boolean })[] = [
    {
      type: 'bubble',
      data: series
        ? series.data.map((item) => ({ ...item, color: itemColors ? itemColors[item.name] : undefined }))
        : [],
      colorByPoint: true,
    },
  ]

  const options: Highcharts.Options = {
    credits: { enabled: false },
    lang: {
      noData: '該当するデータが存在しません。',
    },
    chart: {
      type: 'bubble',
      height: chartHight,
      style: {
        fontFamily: 'Roboto, NotoSansJP',
      },
      zooming: {
        type: 'xy',
      },
    },
    title: chartTitle,
    subtitle: {
      text: subTitle || ' ',
      align: 'left',
    },
    legend: {
      enabled: false,
    },
    yAxis: chartYAxis,
    xAxis: chartXAxis,
    tooltip: {
      useHTML: true,
      formatter(this: any) {
        return `
        <table>
          <tr>
            <th colspan="2">
            <h3>${this.point.name}</h3>
            </th>
          </tr>
          <tr>
            <th>${xAxisTitle}:</th>
            <td style="text-align: right;">${new Intl.NumberFormat('ko-KR', {
              maximumFractionDigits: 2,
            }).format(Number(this.point.x))}</td>
          </tr>
          <tr>
            <th>${yAxisTitle}:</th>
            <td style="text-align: right;">${new Intl.NumberFormat('ko-KR', {
              maximumFractionDigits: 2,
            }).format(Number(this.point.y))}</td>
          </tr>
          <tr>
            <th>${zSizeTitle}:</th>
            <td style="text-align: right;">${new Intl.NumberFormat('ko-KR', {
              maximumFractionDigits: 2,
            }).format(Number(this.point.z))}</td>
          </tr>
        </table>`
      },
    },
    plotOptions: {
      bubble: {
        stickyTracking: false,
        marker: {
          lineWidth: 0,
        },
        events: {
          click(e) {
            onChangeSelectedItem && onChangeSelectedItem(e.point.name)
          },
        },
      },
    },
    series: dataSeries,
  }

  const chartComponentRef = useRef<HighchartsReact.RefObject>(null)

  useEffect(() => {
    const chart = chartComponentRef.current?.chart
    if (chart && selectedItemName) {
      const point = chart.series[0].data.find((item) => item.name === selectedItemName)
      if (point) {
        chart.tooltip.refresh(point)
      }
    }
  }, [selectedItemName])

  return (
    <div className={styles.root}>
      <HighchartsReact ref={chartComponentRef} highcharts={Highcharts} options={options} />
    </div>
  )
}
