import { createSelector } from '@reduxjs/toolkit'
import { DatasetTypesEnum } from 'src/type/dataset.type'
import { TaskProgressType, TASKS } from 'src/type/task.type'
import { datasetSelector } from '../dataset/dataset.state'
import { projectSelector } from '../project/project.state'
import { taskSelector } from '../task/task.state'

const selectedProjectDatasetProgresses = createSelector(
  projectSelector.selectedProjectDetail,
  datasetSelector.datasetObjs,
  taskSelector.taskProgresses,
  (selectedProjectDetail, datasetObjs, taskProgresses) => {
    return selectedProjectDetail
      ? taskProgresses.reduce<{ [datasetId: number]: TaskProgressType }>((res, progress) => {
          if ([TASKS.EXTRACT_DATA, TASKS.VALIDATE_DATA, TASKS.SORT_DATA, TASKS.DESCRIBE_DATA].includes(progress.name)) {
            const dataset = datasetObjs[progress.instanceId]
            const datasetTypesList = [
              DatasetTypesEnum.CUSTOMER,
              DatasetTypesEnum.TARGET_CUSTOMER,
              DatasetTypesEnum.LOAN,
              DatasetTypesEnum.SHORT_TERM_LOAN,
              DatasetTypesEnum.LONG_TERM_LOAN,
              DatasetTypesEnum.TRANSACTION,
            ]
            if (dataset && datasetTypesList.includes(dataset.type)) {
              res[progress.instanceId] = progress
            }
          }
          return res
        }, {})
      : {}
  },
)

const selectedProjectTrainingProgresses = createSelector(
  projectSelector.selectedProject,
  datasetSelector.datasetObjs,
  taskSelector.taskProgresses,
  (selectedProjectDetail, datasetObjs, taskProgresses) => {
    return selectedProjectDetail
      ? taskProgresses
          .map((progress) => {
            // preprocessed dataset validate, describe
            if (
              [TASKS.VALIDATE_DATA, TASKS.SORT_DATA, TASKS.DESCRIBE_DATA, TASKS.CREATE_EXPERIMENT_PATH].includes(
                progress.name,
              )
            ) {
              const dataset = datasetObjs[progress.instanceId]
              const datasetTypesList = [DatasetTypesEnum.TEST, DatasetTypesEnum.TRAIN, DatasetTypesEnum.DASHBOARD]

              if (
                dataset &&
                selectedProjectDetail.id === dataset.project.id &&
                datasetTypesList.includes(dataset.type)
              ) {
                return progress
              }
            } else if (selectedProjectDetail.id === progress.instanceId) {
              return progress
            }
          })
          .filter((v): v is TaskProgressType => !!v)
      : undefined
  },
)

const currentTrainingProgress = createSelector(selectedProjectTrainingProgresses, (state) => {
  if (!state || state.length === 0) return

  const sortedState = [...state].sort((a, b) => a.progress.current - b.progress.current)

  return sortedState[0]
})

export const unionSelector = {
  selectedProjectDatasetProgresses,
  selectedProjectTrainingProgresses,
  currentTrainingProgress,
}
