/* eslint-disable no-magic-numbers */
import classNames from 'classnames'
import _ from 'lodash'
import React, { CSSProperties, FC, useEffect, useMemo, useRef, useState } from 'react'
import InlineSVG from 'react-inlinesvg'
import { AutoSizer, Grid, GridCellProps, ScrollSync, SortDirection, SortDirectionType } from 'react-virtualized'
import iconBullet from 'src/style/icon/icon-bullet.svg'
import styles from './data-table.scss'

export type CellDataType = string | number | boolean | undefined
export type DataListRowType = { [key: string]: CellDataType }
export type CellRenderProps = {
  cellData: CellDataType
  rowData: DataListRowType
  rowIdx: number
}
export type DataTableColumnDefsType = {
  dataKey: string
  width?: number
  label?: string
  headerBold?: boolean
  headerAlign?: 'right' | 'center'
  headerTextWrap?: boolean
  cellBold?: boolean
  cellAlign?: 'right' | 'center'
  flexRate?: number
  enableSort?: boolean
  headerStyle?: CSSProperties
  cellDataFormat?(celldata: CellDataType): CellDataType
  cellRenderer?(cellRenderProps: CellRenderProps): JSX.Element
}

type PropsType = {
  dataList: DataListRowType[]
  dataColumnDefs: DataTableColumnDefsType[]
  theme?: 'default' | 'light'
  headerHeight?: number
  headerFontSize?: 'sm' | 'md' | 'lg'
  headerPaddingH?: 10 | 20
  rowHeight?: number
  tableWidth?: number | 'auto'
  tableHeight?: number | 'auto'
  bodyBorder?: boolean
  bodyFontSize?: 'sm' | 'md' | 'lg'
  bodyPaddingH?: 10 | 20
  columnSeperator?: boolean
  rowSeperator?: boolean
  colorizedRow?: boolean
  selectable?: boolean
  selectedRowIndex?: number
  isRank?: boolean
  noDataRenderer?(): JSX.Element
  onChangeSelectedRow?(selecetedRow: DataListRowType | undefined): void
  onClickLastRow?(): void
}
export const DataTable: FC<PropsType> = (props: PropsType) => {
  const {
    dataList,
    dataColumnDefs,
    theme = 'default',
    headerHeight = 25,
    headerFontSize = 'md',
    headerPaddingH = 20,
    rowHeight = 40,
    tableWidth = 'auto',
    tableHeight = headerHeight + rowHeight * dataList.length,
    bodyBorder,
    bodyFontSize = 'md',
    bodyPaddingH = 20,
    columnSeperator,
    rowSeperator = true,
    colorizedRow,
    selectable,
    selectedRowIndex,
    isRank,
    noDataRenderer,
    onChangeSelectedRow,
    onClickLastRow,
  } = props

  const defaultColumnWidth = 200
  const headRef = useRef<Grid>(null)
  const bodyRef = useRef<Grid>(null)
  const _dataColumnDefs = isRank
    ? [
        {
          dataKey: 'rank',
          label: '',
          width: 35,
          headerAlign: 'center',
          headerBold: true,
          cellAlign: 'center',
        } as DataTableColumnDefsType,
        ...dataColumnDefs,
      ]
    : dataColumnDefs

  const [sort, setSort] = useState<{
    sortBy: string
    sortDirection: SortDirectionType
  }>({
    sortBy: '',
    sortDirection: SortDirection.ASC,
  })

  const [selectedCellRowIdx, setSelectedCellRowIdx] = useState(selectedRowIndex ?? NaN)
  const [hoverCellRowIdx, setHoverCellRowIdx] = useState(NaN)

  const sortedList = useMemo(() => {
    const newList = _.sortBy(dataList, [sort.sortBy])
    if (sort.sortDirection === SortDirection.DESC) {
      newList.reverse()
    }

    if (onClickLastRow && dataList.length > 0) {
      newList.push({})
    }

    return newList
  }, [sort, dataList])

  const selectedRow = useMemo(() => sortedList[selectedCellRowIdx], [selectedCellRowIdx])

  const colWidthRate = useMemo(
    () =>
      _dataColumnDefs.reduce<{ totalFlex: number; totalFixedWidth: number; totalFixedCnt: number }>(
        (res, colDef) => {
          if (colDef.flexRate) {
            res.totalFlex += colDef.flexRate
          } else if (colDef.width) {
            res.totalFixedWidth += colDef.width
          } else {
            res.totalFixedWidth += defaultColumnWidth
            res.totalFixedCnt++
          }

          return res
        },
        { totalFlex: 0, totalFixedWidth: 0, totalFixedCnt: 0 },
      ),
    [_dataColumnDefs],
  )

  useEffect(() => {
    headRef.current?.recomputeGridSize()
    bodyRef.current?.recomputeGridSize()
  }, [sortedList])

  useEffect(() => {
    const selectedRowIdx = sortedList.findIndex((rowItem) => JSON.stringify(rowItem) === JSON.stringify(selectedRow))
    setSelectedCellRowIdx(selectedRowIdx)
  }, [sortedList])

  const headerCellRenderer = (gridProps: GridCellProps) => {
    const colDef = _dataColumnDefs[gridProps.columnIndex]

    const onClickHeader = () => {
      return colDef.enableSort && onSort(colDef.dataKey)
    }

    return (
      <div
        key={gridProps.key}
        onClick={onClickHeader}
        className={classNames(styles.gridHeaderCell, {
          [styles.lightTheme]: theme === 'light',
          [styles.headerFontSm]: headerFontSize === 'sm',
          [styles.headerFontMd]: headerFontSize === 'md',
          [styles.headerFontLg]: headerFontSize === 'lg',
          [styles.headerPadding10]: headerPaddingH === 10,
          [styles.headerPadding20]: headerPaddingH === 20,
          [styles.flexAlignCenter]: colDef.headerAlign === 'center',
          [styles.flexAlignRight]: colDef.headerAlign === 'right',
          [styles.fontBold]: colDef.headerBold,
          [styles.columnSeperator]: columnSeperator,
          [styles.pointerCursor]: colDef.enableSort,
        })}
        style={colDef.headerStyle ? { ...gridProps.style, ...colDef.headerStyle } : gridProps.style}
      >
        <span className={classNames(styles.cellData, { [styles.headerNoWrap]: colDef.headerTextWrap })}>
          {colDef.label}
        </span>
        {colDef.enableSort && (
          <>
            <div className={styles.iconSortArea}>
              {(sort.sortBy !== colDef.dataKey || sort.sortDirection === SortDirection.DESC) && (
                <InlineSVG
                  className={classNames(styles.iconDesc, { [styles.lightTheme]: theme === 'light' })}
                  src={iconBullet}
                  height={5}
                  width={8}
                />
              )}
              {(sort.sortBy !== colDef.dataKey ||
                (sort.sortBy === colDef.dataKey && sort.sortDirection === SortDirection.ASC)) && (
                <InlineSVG
                  className={classNames(styles.iconAsc, { [styles.lightTheme]: theme === 'light' })}
                  src={iconBullet}
                  height={5}
                  width={8}
                />
              )}
            </div>
          </>
        )}
      </div>
    )
  }

  const bodyCellRenderer = (gridProps: GridCellProps) => {
    const colDef = _dataColumnDefs[gridProps.columnIndex]
    const formatedCellData =
      colDef.dataKey === 'rank' && isRank
        ? gridProps.rowIndex + 1
        : colDef.cellDataFormat
        ? colDef.cellDataFormat(sortedList[gridProps.rowIndex][colDef.dataKey])
        : sortedList[gridProps.rowIndex][colDef.dataKey]

    if (onClickLastRow) {
      if (gridProps.rowIndex === sortedList.length - 1) {
        if (gridProps.columnIndex === 0) {
          return (
            <div
              key={gridProps.key}
              className={styles.lastRow}
              style={{
                ...gridProps.style,
                width: '100%',
              }}
              onClick={onClickLastRow}
            >
              Load more
            </div>
          )
        } else {
          return null
        }
      }
    }

    return (
      <div
        key={gridProps.key}
        className={classNames(styles.gridBodyCell, {
          [styles.lightTheme]: theme === 'light',
          [styles.bodyFontSm]: bodyFontSize === 'sm',
          [styles.bodyFontMd]: bodyFontSize === 'md',
          [styles.bodyFontLg]: bodyFontSize === 'lg',
          [styles.bodyPadding10]: bodyPaddingH === 10,
          [styles.bodyPadding20]: bodyPaddingH === 20,
          [styles.flexAlignCenter]: colDef.cellAlign === 'center',
          [styles.flexAlignRight]: colDef.cellAlign === 'right',
          [styles.fontBold]: colDef.cellBold,
          [styles.columnSeperator]: columnSeperator,
          [styles.rowSeperator]: rowSeperator,
          [styles.colorizedRow]: gridProps.rowIndex % 2 === 1 && theme !== 'light' && colorizedRow,
          [styles.colorizedRow]: gridProps.rowIndex % 2 === 0 && theme === 'light' && colorizedRow,
          [styles.rowHover]: selectable && gridProps.rowIndex === hoverCellRowIdx,
          [styles.selectedRow]:
            JSON.stringify(selectedRow) === JSON.stringify(sortedList[gridProps.rowIndex]) &&
            !!(onChangeSelectedRow || selectedRowIndex !== undefined),
        })}
        style={gridProps.style}
        onClick={() => selectRow(gridProps.rowIndex)}
        onMouseOver={() => {
          setHoverCellRowIdx(gridProps.rowIndex)
        }}
        onMouseLeave={() => {
          setHoverCellRowIdx(NaN)
        }}
      >
        {colDef.cellRenderer ? (
          colDef.cellRenderer({
            cellData: formatedCellData,
            rowData: sortedList[gridProps.rowIndex],
            rowIdx: gridProps.rowIndex,
          })
        ) : (
          <span className={styles.cellData}>{formatedCellData}</span>
        )}
      </div>
    )
  }

  const noContents = () => <div className={styles.noContents}>{noDataRenderer ? noDataRenderer() : 'no Data'}</div>

  function onSort(sortBy: string) {
    if (sort.sortBy === '' || sort.sortBy !== sortBy) {
      setSort({ sortBy, sortDirection: SortDirection.ASC })
    } else if (sort.sortBy === sortBy && sort.sortDirection === SortDirection.ASC) {
      setSort({ sortBy, sortDirection: SortDirection.DESC })
    } else {
      setSort({ sortBy: '', sortDirection: SortDirection.ASC })
    }
  }

  function selectRow(rowIndex: number) {
    if (selectable) {
      setSelectedCellRowIdx(rowIndex)
      if (onChangeSelectedRow) {
        onChangeSelectedRow(sortedList[rowIndex])
      }
    }
  }

  return (
    <div
      style={{
        width: tableWidth,
        height: tableHeight,
      }}
    >
      <ScrollSync>
        {({ onScroll, scrollLeft }) => (
          <AutoSizer
            onResize={() => {
              headRef.current?.recomputeGridSize()
              bodyRef.current?.recomputeGridSize()
            }}
          >
            {({ width, height }) => {
              const isSideScroll =
                height < sortedList.length * rowHeight + headerHeight &&
                (navigator.userAgent.toUpperCase().includes('WIN') || /Linux/.test(navigator.userAgent))

              const widthExceptFixed = width - (isSideScroll ? 20 : 0) - colWidthRate.totalFixedWidth
              const cntExceptFixed = _dataColumnDefs.length - colWidthRate.totalFixedCnt

              const fullfillDefaultColWidth =
                colWidthRate.totalFlex === 0 && widthExceptFixed > cntExceptFixed * defaultColumnWidth
                  ? (width - (isSideScroll ? 20 : 0)) / _dataColumnDefs.length
                  : defaultColumnWidth

              const colRenderInfo = _dataColumnDefs.map((col) => {
                let colWidth: number

                if (col.flexRate) {
                  colWidth = (col.flexRate / colWidthRate.totalFlex) * widthExceptFixed
                } else if (col.width) {
                  colWidth = col.width
                } else {
                  colWidth = fullfillDefaultColWidth
                }

                return { ...col, width: colWidth }
              })

              return (
                <>
                  <div className={styles.canvasHack} />
                  <Grid
                    ref={headRef}
                    className={classNames(styles.gridHeader, {
                      [styles.scrollbarPadding]: isSideScroll,
                      [styles.lightTheme]: theme === 'light',
                    })}
                    width={width}
                    height={headerHeight}
                    rowHeight={headerHeight}
                    rowCount={1}
                    columnCount={_dataColumnDefs.length}
                    columnWidth={({ index }) => colRenderInfo[index].width}
                    cellRenderer={headerCellRenderer}
                    onScroll={onScroll}
                    scrollLeft={scrollLeft}
                  />
                  <Grid
                    ref={bodyRef}
                    className={classNames(styles.gridBody, { [styles.bodyBorder]: bodyBorder })}
                    width={width}
                    height={sortedList.length > 0 ? height - headerHeight : rowHeight}
                    rowHeight={rowHeight}
                    rowCount={sortedList.length}
                    columnCount={_dataColumnDefs.length}
                    columnWidth={({ index }) => colRenderInfo[index].width}
                    cellRenderer={bodyCellRenderer}
                    onScroll={onScroll}
                    scrollLeft={scrollLeft}
                    noContentRenderer={noContents}
                  />
                </>
              )
            }}
          </AutoSizer>
        )}
      </ScrollSync>
    </div>
  )
}
