import classNames from 'classnames'
import React, { useEffect, useMemo, useState } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { Button } from 'src/component/atom/button/button'
import { ChartBubble } from 'src/component/molecule/chart-bubble/chart-bubble'
import { DataTable, DataTableColumnDefsType } from 'src/component/molecule/data-table/data-table'
import { DropdownSingleSelector } from 'src/component/molecule/dropdown-single-selector/dropdown-single-selector'
import { dashboardAction } from 'src/redux/dashboard/dashboard.action'
import { dashboardSelector } from 'src/redux/dashboard/dashboard.state'
import { OptionType } from 'src/type/common.type'
import { DashboardCompanyType } from 'src/type/dashboard.type'
import { demoData, totalData } from './demo.data'
import styles from './partner-analysis.scss'

type itemType = { partnersName: string; prevVolume: number; volume: number }

type PropsType = {
  dsCompany: DashboardCompanyType
}

export const PartnerAnalysis = ({ dsCompany }: PropsType) => {
  const dispatch = useDispatch()

  const [selectedPartnerItem, setSelectedPartnerItem] = useState<itemType>()
  const [selectedUnit, setSelectedUnit] = useState<OptionType>(unitOptions[1])
  const [selectedComparison, setSelectedComparison] = useState<OptionType>(comparisonOptions[0])
  const [selectedFrequency, setSelectedFrequency] = useState<OptionType>(depositFrequencyOptions[0])
  const [selectedTopN, setSelectedTopN] = useState<OptionType>(topNOptions[0])
  const [selectedAnalysisTarget, setSelectedAnalysisTarget] = useState<
    OptionType<'volume' | 'changeRate' | 'frequency'>
  >(analysisTargetOptions[0])

  const selectedComapnyMainPartnerDatas = useSelector(dashboardSelector.dashboardCompanyMainPartnerDatas)
  const selectedComapnyNewPartnerDatas = useSelector(dashboardSelector.dashboardCompanyNewPartnerDatas)
  const selectedComapnyExistPartnerDatas = useSelector(dashboardSelector.dashboardCompanyExistPartnerDatas)
  const mainPartnersColors = useSelector(dashboardSelector.mainPartnersColor)

  const { newTotalData, existTotalData } = useMemo(() => {
    const newTotalData =
      selectedComparison.value === 'previous' ? totalData.previousTotal.new : totalData.lastYearTotal.new
    const existTotalData =
      selectedComparison.value === 'previous' ? totalData.previousTotal.exist : totalData.lastYearTotal.exist

    return { newTotalData, existTotalData }
  }, [selectedComparison])

  const mainPartnerChartData = useMemo(() => {
    return {
      data: selectedComapnyMainPartnerDatas
        ? selectedComapnyMainPartnerDatas.map((item) => {
            return {
              x:
                selectedAnalysisTarget.value === 'volume'
                  ? item.frequency ?? 0
                  : selectedAnalysisTarget.value === 'changeRate'
                  ? item.frequency ?? 0
                  : selectedAnalysisTarget.value === 'frequency'
                  ? item.volume ?? 0
                  : 0,
              y:
                selectedAnalysisTarget.value === 'volume'
                  ? (item.changeRate ?? 0) * 100
                  : selectedAnalysisTarget.value === 'changeRate'
                  ? item.volume ?? 0
                  : selectedAnalysisTarget.value === 'frequency'
                  ? (item.changeRate ?? 0) * 100
                  : 0,
              z:
                selectedAnalysisTarget.value === 'volume'
                  ? item.volume ?? 0
                  : selectedAnalysisTarget.value === 'changeRate'
                  ? (item.changeRate ?? 0) * 100
                  : selectedAnalysisTarget.value === 'frequency'
                  ? item.frequency ?? 0
                  : 0,
              name: item.partnersName,
            }
          })
        : [],
    }
  }, [selectedComapnyMainPartnerDatas, selectedAnalysisTarget])

  const mainPartnersListData = useMemo(() => {
    return selectedComapnyMainPartnerDatas
      ? selectedComapnyMainPartnerDatas.map((item) => ({
          partnersName: item.partnersName,
          prevVolume: item.prevVolume ?? 0,
          volume: item.volume ?? 0,
          changeRate: item.changeRate ?? 0,
        }))
      : []
  }, [selectedComapnyMainPartnerDatas])

  const chartLabels = useMemo(() => {
    return {
      xAxisTitle:
        selectedAnalysisTarget.value === 'volume'
          ? '取引回数(回)'
          : selectedAnalysisTarget.value === 'changeRate'
          ? '取引回数(回)'
          : selectedAnalysisTarget.value === 'frequency'
          ? '取引金額(円)'
          : '',
      yAxisTitle:
        selectedAnalysisTarget.value === 'volume'
          ? '変化率(%)'
          : selectedAnalysisTarget.value === 'changeRate'
          ? '取引金額(円)'
          : selectedAnalysisTarget.value === 'frequency'
          ? '変化率(%)'
          : '',
      zSizeTitle:
        selectedAnalysisTarget.value === 'volume'
          ? '取引金額(円)'
          : selectedAnalysisTarget.value === 'changeRate'
          ? '変化率(%)'
          : selectedAnalysisTarget.value === 'frequency'
          ? '取引回数(回)'
          : '',
    }
  }, [selectedAnalysisTarget])

  const newPartnersListData = useMemo(() => {
    return selectedComapnyNewPartnerDatas
      ? selectedComapnyNewPartnerDatas.map((item) => ({
          partnersName: item.partnersName,
          prevVolume: item.prevVolume ?? 0,
          prevFrequency: item.prevFrequency ?? 0,
          volume: item.volume ?? 0,
          frequency: item.frequency ?? 0,
          changeRate: (item.changeRate ?? 0) * 100,
        }))
      : []
  }, [selectedComapnyNewPartnerDatas])

  const existPartnersListData = useMemo(() => {
    return selectedComapnyExistPartnerDatas
      ? selectedComapnyExistPartnerDatas.map((item) => ({
          partnersName: item.partnersName,
          prevVolume: item.prevVolume ?? 0,
          prevFrequency: item.prevFrequency ?? 0,
          volume: item.volume ?? 0,
          frequency: item.frequency ?? 0,
          changeRate: (item.changeRate ?? 0) * 100,
          changeFreqRate: item.prevFrequency
            ? (((item.frequency ?? 0) - (item.prevFrequency ?? 0)) / item.prevFrequency) * 100
            : 0,
        }))
      : []
  }, [selectedComapnyExistPartnerDatas])

  const { mainPartnersTotalVolume, mainPartnersTotalPrevVolume } = useMemo(() => {
    const mainPartnersTotalVolume = mainPartnersListData.reduce((acc, item) => acc + item.volume, 0)
    const mainPartnersTotalPrevVolume = mainPartnersListData.reduce((acc, item) => acc + item.prevVolume, 0)

    return { mainPartnersTotalVolume, mainPartnersTotalPrevVolume }
  }, [mainPartnersListData])

  const { newPartnersTotalVolume, newPartnersTotalFrequency } = useMemo(() => {
    const newPartnersTotalVolume = newPartnersListData.reduce((acc, item) => acc + item.volume, 0)
    const newPartnersTotalFrequency = newPartnersListData.reduce((acc, item) => acc + item.frequency, 0)

    return { newPartnersTotalVolume, newPartnersTotalFrequency }
  }, [newPartnersListData])

  const {
    existPartnersTotalVolume,
    existPartnersTotalFrequency,
    existPartnersTotalPrevVolume,
    existPartnersTotalPrevFrequency,
    existPartnersTotalChangeRate,
    existPartnersTotalFreqChangeRate,
  } = useMemo(() => {
    const existPartnersTotalVolume = existPartnersListData.reduce((acc, item) => acc + item.volume, 0)
    const existPartnersTotalFrequency = existPartnersListData.reduce((acc, item) => acc + item.frequency, 0)
    const existPartnersTotalPrevVolume = existPartnersListData.reduce((acc, item) => acc + item.prevVolume, 0)
    const existPartnersTotalPrevFrequency = existPartnersListData.reduce((acc, item) => acc + item.prevFrequency, 0)
    const existPartnersTotalChangeRate =
      ((existPartnersTotalVolume - existPartnersTotalPrevVolume) / existPartnersTotalVolume) * 100
    const existPartnersTotalFreqChangeRate =
      ((existPartnersTotalFrequency - existPartnersTotalPrevFrequency) / existPartnersTotalFrequency) * 100

    return {
      existPartnersTotalVolume,
      existPartnersTotalFrequency,
      existPartnersTotalPrevVolume,
      existPartnersTotalPrevFrequency,
      existPartnersTotalChangeRate,
      existPartnersTotalFreqChangeRate,
    }
  }, [existPartnersListData])

  useEffect(() => {
    handleClickButton()

    return () => {
      dispatch(dashboardAction.setDashboardCompanyMainPartners(null))
      dispatch(dashboardAction.setDashboardCompanyNewPartners(null))
      dispatch(dashboardAction.setDashboardCompanyExistPartners(null))
    }
  }, [])

  const tableColumnDefObjs: DataTableColumnDefsType[] = [
    {
      dataKey: 'partnersName',
      label: '取引先名',
      flexRate: 1,
      headerAlign: 'center',
      headerBold: true,
    },
    {
      dataKey: 'prevVolume',
      label: '入金額合計(以前)',
      flexRate: 1,
      headerAlign: 'center',
      headerBold: true,
      headerTextWrap: true,
      cellAlign: 'right',
      enableSort: true,
      cellRenderer: (data) => {
        return (
          <span>{`${data.cellData} (${
            new Intl.NumberFormat('ko-KR', {
              maximumFractionDigits: 2,
            }).format(Number(data.rowData['prevFrequency'])) ?? 0
          }回)`}</span>
        )
      },
      cellDataFormat: (data) => {
        return data
          ? new Intl.NumberFormat('ko-KR', {
              maximumFractionDigits: 2,
            }).format(Number(data))
          : 0
      },
    },
    {
      dataKey: 'volume',
      label: '入金額合計(直近)',
      flexRate: 1,
      headerAlign: 'center',
      headerBold: true,
      headerTextWrap: true,
      cellAlign: 'right',
      enableSort: true,
      cellRenderer: (data) => {
        return (
          <span>{`${data.cellData} (${
            new Intl.NumberFormat('ko-KR', {
              maximumFractionDigits: 2,
            }).format(Number(data.rowData['frequency'])) ?? 0
          }回)`}</span>
        )
      },
      cellDataFormat: (data) => {
        return data
          ? new Intl.NumberFormat('ko-KR', {
              maximumFractionDigits: 2,
            }).format(Number(data))
          : 0
      },
    },
    {
      dataKey: 'changeRate',
      label: '変化率',
      width: 120,
      headerAlign: 'center',
      headerBold: true,
      cellAlign: 'center',
      enableSort: true,
      cellRenderer: (data) => {
        return (
          <span
            className={classNames({
              [styles.redFontColor]: data.cellData && +data.cellData > 0,
              [styles.blueFontColor]: data.cellData && +data.cellData < 0,
            })}
          >{`${data.cellData}% (${data.rowData['changeFreqRate'] ?? 0}%)`}</span>
        )
      },
      cellDataFormat: (data) => {
        return data
          ? new Intl.NumberFormat('ko-KR', {
              maximumFractionDigits: 2,
            }).format(Number(data))
          : 0
      },
    },
  ]

  function handleClickButton() {
    if (!selectedUnit || !selectedComparison || !selectedFrequency || !selectedTopN || !selectedAnalysisTarget) return

    if (dsCompany.companyName === '14509079e2475fdf19583f0181a73a4d1eb0c043f550f22bac2e6a73c18b4221') {
      dispatch(
        dashboardAction.setDashboardCompanyMainPartners(
          demoData.filter(
            (item) =>
              item.unitPeriod === selectedUnit.value &&
              item.comparison === selectedComparison.value &&
              item.frequency &&
              item.frequency >= +selectedFrequency.value &&
              item.changeRate !== undefined,
          ),
        ),
      )
      dispatch(
        dashboardAction.setDashboardCompanyNewPartners(
          demoData
            .filter(
              (item) =>
                item.unitPeriod === selectedUnit.value &&
                item.comparison === selectedComparison.value &&
                item.frequency &&
                item.frequency >= +selectedFrequency.value &&
                item.prevVolume === undefined,
            )
            .sort((a, b) => {
              const valueA = Number(a[selectedAnalysisTarget.value as 'volume' | 'changeRate' | 'frequency'])
              const valueB = Number(b[selectedAnalysisTarget.value as 'volume' | 'changeRate' | 'frequency'])
              return valueA - valueB
            })
            .sort((a, b) => (b.volume ?? 0) - (a.volume ?? 0)),
        ),
      )
      dispatch(
        dashboardAction.setDashboardCompanyExistPartners(
          demoData
            .filter(
              (item) =>
                item.unitPeriod === selectedUnit.value &&
                item.comparison === selectedComparison.value &&
                item.frequency &&
                item.frequency >= +selectedFrequency.value &&
                item.prevVolume !== undefined,
            )
            .sort((a, b) => (b.volume ?? 0) - (a.volume ?? 0)),
        ),
      )
    } else {
      dispatch(
        dashboardAction.fetchDashboardCompanyMainPartners.request({
          companyId: dsCompany.companyId,
          unitPeriod: selectedUnit?.value,
          comparison: selectedComparison?.value,
          frequency: selectedFrequency?.value,
          topN: selectedTopN?.value,
          analysisTarget: selectedAnalysisTarget?.value,
        }),
      )
      dispatch(
        dashboardAction.fetchDashboardCompanyNewPartners.request({
          companyId: dsCompany.companyId,
          unitPeriod: selectedUnit?.value,
          comparison: selectedComparison?.value,
          frequency: selectedFrequency?.value,
          topN: selectedTopN?.value,
          analysisTarget: 'volume',
          isNew: 'true',
        }),
      )
      dispatch(
        dashboardAction.fetchDashboardCompanyExistPartners.request({
          companyId: dsCompany.companyId,
          unitPeriod: selectedUnit?.value,
          comparison: selectedComparison?.value,
          frequency: selectedFrequency?.value,
          topN: selectedTopN?.value,
          analysisTarget: 'changeRate',
          isNew: 'false',
        }),
      )
    }
  }

  function handleItemClick(item: itemType) {
    setSelectedPartnerItem(item)
  }

  function handleChangeSelectedItem(changeItemName: string) {
    const changedItem = mainPartnersListData.find((item) => item.partnersName == changeItemName)
    setSelectedPartnerItem(changedItem)
  }

  return (
    <>
      <div className={styles.controlLabelArea}>
        <div />
        <div>{'対象集計期間'}</div>
        <div>{'比較対象'}</div>
        <div>{'集計対象とする最低入金回数'}</div>
        <div>{'表示対象'}</div>
        <div />
      </div>
      <div className={styles.controlArea}>
        <div />
        <DropdownSingleSelector
          sizeType='sm'
          placeholder={'対象集計期間'}
          options={unitOptions}
          selectedOption={selectedUnit}
          onChange={setSelectedUnit}
        />
        <DropdownSingleSelector
          sizeType='sm'
          placeholder={'比較対象'}
          options={comparisonOptions}
          selectedOption={selectedComparison}
          onChange={setSelectedComparison}
        />
        <DropdownSingleSelector
          sizeType='sm'
          placeholder={'集計対象とする最低入金回数'}
          options={depositFrequencyOptions}
          selectedOption={selectedFrequency}
          onChange={setSelectedFrequency}
        />
        <DropdownSingleSelector
          sizeType='sm'
          placeholder={'表示対象'}
          options={topNOptions}
          selectedOption={selectedTopN}
          onChange={setSelectedTopN}
        />
        <Button
          sizeType='sm'
          onClick={handleClickButton}
          disabled={
            !selectedUnit || !selectedComparison || !selectedFrequency || !selectedTopN || !selectedAnalysisTarget
          }
        >
          {'分析'}
        </Button>
      </div>
      <div className={styles.sectionTitle}>
        <div>{`主要取引先`}</div>
        <div className={styles.analysisTargetArea}>
          <div>{'バブルの対象'}</div>
          <DropdownSingleSelector
            sizeType='sm'
            placeholder={'バブルの対象'}
            options={analysisTargetOptions}
            selectedOption={selectedAnalysisTarget}
            onChange={setSelectedAnalysisTarget}
          />
        </div>
      </div>
      <div className={styles.chartArea}>
        <div className={styles.chartWrapper}>
          <ChartBubble
            xAxisTitle={chartLabels.xAxisTitle}
            yAxisTitle={chartLabels.yAxisTitle}
            zSizeTitle={chartLabels.zSizeTitle}
            series={mainPartnerChartData}
            itemColors={mainPartnersColors}
            selectedItemName={selectedPartnerItem?.partnersName}
            onChangeSelectedItem={handleChangeSelectedItem}
          />
        </div>
        <div className={styles.listArea}>
          <div className={styles.listHeader}>{`全体入金額: ${new Intl.NumberFormat('ko-KR', {
            maximumFractionDigits: 2,
          }).format(Number(existTotalData.prevVolume))} 円 → ${new Intl.NumberFormat('ko-KR', {
            maximumFractionDigits: 2,
          }).format(Number(existTotalData.volume))} 円`}</div>
          <ol className={styles.listWrapper}>
            {mainPartnersListData &&
              mainPartnersListData.map((item) => (
                <li
                  key={item.partnersName}
                  className={classNames(styles.listItem, {
                    [styles.activeItem]: selectedPartnerItem && selectedPartnerItem.partnersName === item.partnersName,
                  })}
                  onClick={() => handleItemClick(item)}
                >
                  <div className={styles.bullet} style={{ backgroundColor: mainPartnersColors[item.partnersName] }} />
                  <div className={styles.companyName}>{item.partnersName}</div>
                  <span>{`${new Intl.NumberFormat('ko-KR', {
                    maximumFractionDigits: 2,
                  }).format(Number(item.prevVolume))} 円 → ${new Intl.NumberFormat('ko-KR', {
                    maximumFractionDigits: 2,
                  }).format(Number(item.volume))} 円`}</span>
                </li>
              ))}
          </ol>
        </div>
      </div>
      <div className={styles.dataTableArea}>
        <div className={styles.newTable}>
          <div className={styles.tableTitle}>{'新たな取引先'}</div>
          <div className={styles.tableWrapper}>
            <DataTable
              dataColumnDefs={tableColumnDefObjs}
              dataList={newPartnersListData}
              tableHeight={460}
              rowHeight={40}
              headerHeight={60}
              theme='light'
              headerFontSize='md'
              headerPaddingH={10}
              bodyFontSize='sm'
              bodyPaddingH={10}
              isRank
            />
            <div className={styles.dataTableTotalRow}>
              <div className={styles.leftCell}></div>
              <div className={styles.leftCell}>{`合計`}</div>
              <div className={styles.rightCell}></div>
              <div className={styles.rightCell}>
                {`${new Intl.NumberFormat('ko-KR', {
                  maximumFractionDigits: 2,
                }).format(Number(newPartnersTotalVolume))} (${new Intl.NumberFormat('ko-KR', {
                  maximumFractionDigits: 2,
                }).format(Number(newPartnersTotalFrequency))}回)`}
              </div>
              <div className={styles.centerCell}></div>
            </div>
            <div className={styles.dataTableTotalRow}>
              <div className={styles.leftCell}></div>
              <div className={styles.leftCell}>{`全体`}</div>
              <div className={styles.rightCell}></div>
              <div className={styles.rightCell}>
                {`${new Intl.NumberFormat('ko-KR', {
                  maximumFractionDigits: 2,
                }).format(Number(newTotalData.volume))} (${new Intl.NumberFormat('ko-KR', {
                  maximumFractionDigits: 2,
                }).format(Number(newTotalData.frequency))}回)`}
              </div>
              <div className={styles.centerCell}></div>
            </div>
          </div>
        </div>
        <div className={styles.asisTable}>
          <div className={styles.tableTitle}>{'従来からの取引先'}</div>
          <div className={styles.tableWrapper}>
            <DataTable
              dataColumnDefs={tableColumnDefObjs}
              dataList={existPartnersListData}
              tableHeight={460}
              rowHeight={40}
              headerHeight={60}
              theme='light'
              headerFontSize='md'
              headerPaddingH={10}
              bodyFontSize='sm'
              bodyPaddingH={10}
              isRank
            />
            <div className={styles.dataTableTotalRow}>
              <div className={styles.leftCell}></div>
              <div className={styles.leftCell}>{`合計`}</div>
              <div className={styles.rightCell}>
                {`${new Intl.NumberFormat('ko-KR', {
                  maximumFractionDigits: 2,
                }).format(Number(existPartnersTotalPrevVolume))} (${new Intl.NumberFormat('ko-KR', {
                  maximumFractionDigits: 2,
                }).format(Number(existPartnersTotalPrevFrequency))}回)`}
              </div>
              <div className={styles.rightCell}>
                {`${new Intl.NumberFormat('ko-KR', {
                  maximumFractionDigits: 2,
                }).format(Number(existPartnersTotalVolume))} (${new Intl.NumberFormat('ko-KR', {
                  maximumFractionDigits: 2,
                }).format(Number(existPartnersTotalFrequency))}回)`}
              </div>
              <div
                className={classNames(styles.centerCell, {
                  [styles.redFontColor]: existPartnersTotalChangeRate > 0,
                  [styles.blueFontColor]: existPartnersTotalChangeRate < 0,
                })}
              >
                {`${new Intl.NumberFormat('ko-KR', {
                  maximumFractionDigits: 2,
                }).format(Number(existPartnersTotalChangeRate))}% (${new Intl.NumberFormat('ko-KR', {
                  maximumFractionDigits: 2,
                }).format(Number(existPartnersTotalFreqChangeRate))}%)`}
              </div>
            </div>
            <div className={styles.dataTableTotalRow}>
              <div className={styles.leftCell}></div>
              <div className={styles.leftCell}>{`全体`}</div>
              <div className={styles.rightCell}>
                {`${new Intl.NumberFormat('ko-KR', {
                  maximumFractionDigits: 2,
                }).format(Number(existTotalData.prevVolume))} (${new Intl.NumberFormat('ko-KR', {
                  maximumFractionDigits: 2,
                }).format(Number(existTotalData.prevFrequency))}回)`}
              </div>
              <div className={styles.rightCell}>
                {`${new Intl.NumberFormat('ko-KR', {
                  maximumFractionDigits: 2,
                }).format(Number(existTotalData.volume))} (${new Intl.NumberFormat('ko-KR', {
                  maximumFractionDigits: 2,
                }).format(Number(existTotalData.frequency))}回)`}
              </div>
              <div
                className={classNames(styles.centerCell, {
                  [styles.redFontColor]: existTotalData.volume - existTotalData.prevVolume > 0,
                  [styles.blueFontColor]: existTotalData.volume - existTotalData.prevVolume < 0,
                })}
              >
                {`${new Intl.NumberFormat('ko-KR', {
                  maximumFractionDigits: 2,
                }).format(
                  Number(((existTotalData.volume - existTotalData.prevVolume) / existTotalData.prevVolume) * 100),
                )}% (${new Intl.NumberFormat('ko-KR', {
                  maximumFractionDigits: 2,
                }).format(
                  Number(
                    ((existTotalData.frequency - existTotalData.prevFrequency) / existTotalData.prevFrequency) * 100,
                  ),
                )}%)`}
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  )
}

const unitOptions: OptionType[] = [
  {
    label: '1か月',
    value: '1',
  },
  {
    label: '3か月',
    value: '3',
  },
  {
    label: '6か月',
    value: '6',
  },
]

const comparisonOptions: OptionType[] = [
  {
    label: '前期間',
    value: 'previous',
  },
  {
    label: '1年前同期間',
    value: 'samePeriod12mAgo',
  },
]

const depositFrequencyOptions: OptionType[] = [
  {
    label: '全て',
    value: '0',
  },
  {
    label: '3回以上',
    value: '3',
  },
  {
    label: '5回以上',
    value: '5',
  },
]

const topNOptions: OptionType[] = [
  {
    label: '上位10',
    value: 10,
  },
  {
    label: '上位20',
    value: 20,
  },
  {
    label: '上位30',
    value: 30,
  },
]

const analysisTargetOptions: OptionType[] = [
  {
    label: '取引金額',
    value: 'volume',
  },
  {
    label: '取引回数',
    value: 'frequency',
  },
  {
    label: '変化率',
    value: 'changeRate',
  },
]
