import React, { useEffect } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { NavItemText } from 'src/component/molecule/nav-item-text/nav-item-text'
import { Header } from 'src/component/organism/header/header'
import { dashboardAction } from 'src/redux/dashboard/dashboard.action'
import { dashboardSelector } from 'src/redux/dashboard/dashboard.state'
import { notificationAction } from 'src/redux/notification/notification.action'
import { notificationSelector } from 'src/redux/notification/notification.state'
import { userSelector } from 'src/redux/user/user.state'
import { DashboardRoute } from './dashboard.route'
import styles from './dashboard.scss'

export const DashboardPage = () => {
  const dispatch = useDispatch()

  const user = useSelector(userSelector.user)
  const alertLogs = useSelector(notificationSelector.alertLogs)
  const notReadedLogIds = useSelector(notificationSelector.notReadedLogIds)

  const selectedBranchCode = useSelector(dashboardSelector.selectedContractBranchCode)
  const selectedLookbackPeriod = useSelector(dashboardSelector.selectedLookbackPeriod)

  const selectedIndustry1 = useSelector(dashboardSelector.selectedIndustry1)
  const selectedIndustry2 = useSelector(dashboardSelector.selectedIndustry2)
  const selectedIndustry3 = useSelector(dashboardSelector.selectedIndustry3)
  const selectedCorporateType = useSelector(dashboardSelector.selectedCorporateType)

  useEffect(() => {
    if (selectedCorporateType && !selectedIndustry1) {
      dispatch(dashboardAction.fetchDashboardIndustryFilterIndustry1s.request({ corporateType: selectedCorporateType }))
    }
  }, [selectedCorporateType])

  useEffect(() => {
    if (selectedBranchCode && selectedLookbackPeriod) {
      dispatch(
        dashboardAction.fetchDashboardContract.request({
          branchCode: selectedBranchCode,
          lookbackPeriod: selectedLookbackPeriod,
        }),
      )
    }
  }, [selectedBranchCode, selectedLookbackPeriod])

  useEffect(() => {
    if (selectedCorporateType) {
      dispatch(
        dashboardAction.fetchDashboardIndustry.request({
          corporateType: selectedCorporateType,
          industry1: selectedIndustry1,
          industry2: selectedIndustry2,
          industry3: selectedIndustry3,
        }),
      )
    }
  }, [selectedCorporateType, selectedIndustry1, selectedIndustry2, selectedIndustry3])

  function handleOpenNotification() {
    if (notReadedLogIds.length > 0) {
      dispatch(notificationAction.updateAlertLog.request())
    }
  }

  return (
    <div className={styles.root}>
      {user && (
        <>
          <Header user={user} alertLogs={alertLogs} onOpenNotification={handleOpenNotification}>
            <div className={styles.headerText}>{`Sales Insight`}</div>
            <div className={styles.dashboardNavItem}>
              <NavItemText navigationPath={'industry-ins'}>
                <div className={styles.navText}>{`業種インサイト`}</div>
              </NavItemText>
              <NavItemText navigationPath={'sales-ins'}>
                <div className={styles.navText}>{`営業インサイト`}</div>
              </NavItemText>
            </div>
          </Header>
        </>
      )}
      <DashboardRoute />
    </div>
  )
}
