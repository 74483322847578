import React, { useEffect, useState } from 'react'
import { useDispatch } from 'react-redux'
import { Tab } from 'src/component/molecule/tab/tab'
import { withSideModalPageWrapper } from 'src/hoc/with-side-modal-page-wrapper'
import { dashboardCompanySalesMotivesIntl } from 'src/intl/data/dashboard.key'
import { dashboardAction } from 'src/redux/dashboard/dashboard.action'
import { OptionType } from 'src/type/common.type'
import { DashboardCompanyType } from 'src/type/dashboard.type'
import styles from './company-detail.scss'
import { PartnerAnalysis } from './partner-analysis/partner-analysis'
import { TransactionAnalysis } from './transaction-analysis/transaction-analysis'

type PropsType = {
  dashboardCompany: DashboardCompanyType
}

const CompanyDetail = ({ dashboardCompany }: PropsType) => {
  const dispatch = useDispatch()

  const [tabItem, setTabItem] = useState(analysisItems[0])

  useEffect(() => {
    dispatch(
      dashboardAction.fetchDashboardCompanyRanks.request({
        companyId: dashboardCompany.companyId,
        isMainBank: dashboardCompany.isMainBank ?? '',
      }),
    )
    return () => {
      dispatch(dashboardAction.setDashboardCompanyRanks(null))
    }
  }, [dashboardCompany])

  return (
    <div className={styles.root}>
      <div className={styles.header}>
        <div className={styles.companyName}>{dashboardCompany.companyName}</div>
      </div>
      <div className={styles.companyInfo}>
        <div className={styles.defaultInfo}>
          <div className={styles.defaultTitle}>{`基本情報`}</div>
          <div className={styles.defaultDetail}>
            <div className={styles.detailLabel}>{`住所`}</div>
            <div className={styles.detailText}>{dashboardCompany.dsCompanyDetails?.postCode2}</div>
            <div className={styles.detailLabel}>{`業種(大)`}</div>
            <div className={styles.detailText}>{dashboardCompany.dsCompanyDetails?.industry1}</div>
            <div className={styles.detailLabel}>{`業種(中)`}</div>
            <div className={styles.detailText}>{dashboardCompany.dsCompanyDetails?.industry2}</div>
            <div className={styles.detailLabel}>{`業種(小)`}</div>
            <div className={styles.detailText}>{dashboardCompany.dsCompanyDetails?.industry3}</div>
            <div className={styles.detailLabel}>{`業歴`}</div>
            <div className={styles.detailText}>
              {dashboardCompany.dsCompanyDetails?.corporationAge
                ? `${dashboardCompany.dsCompanyDetails?.corporationAge}年`
                : ''}
            </div>
            <div className={styles.detailLabel}>{`代表者年齢`}</div>
            <div className={styles.detailText}>
              {dashboardCompany.dsCompanyDetails?.ceoAge ? `${dashboardCompany.dsCompanyDetails?.ceoAge}` : ''}
            </div>
            <div className={styles.detailLabel}>{`家族構成`}</div>
            <div className={styles.detailText}>{dashboardCompany.dsCompanyDetails?.spouse}</div>
            <div className={styles.detailLabel}>{`外部信用情報機関`}</div>
            <div className={styles.detailText}>{dashboardCompany.dsCompanyDetails?.descendant}</div>
            <div className={styles.detailLabel}>{`格付`}</div>
            <div className={styles.detailText}>{dashboardCompany.dsCompanyDetails?.creditBureauScore}</div>
          </div>
        </div>
        <div className={styles.adviceArea}>
          <div className={styles.adviceItem}>
            <div className={styles.adviceHeader}>
              <div className={styles.adviceTitle}>{`融資営業に関するインサイト`}</div>
              <div className={styles.loanGrades}>
                {`短期: ニーズ等級${dashboardCompany?.loan1Grade} / 長期: ニーズ等級${dashboardCompany?.loan2Grade}`}
              </div>
            </div>
            <ul className={styles.adviceList}>
              {dashboardCompany.dsCompanyDetails?.salesMotives?.loanBusiness.loanRules.rule1 === true && (
                <li>{dashboardCompanySalesMotivesIntl['loanRule1'] || `If grade of loan1 is 5, then True`}</li>
              )}
              {dashboardCompany.dsCompanyDetails?.salesMotives?.loanBusiness.loanRules.rule2 === true && (
                <li>{dashboardCompanySalesMotivesIntl['loanRule2'] || `If grade of loan2 is 5, then True`}</li>
              )}
              {dashboardCompany.dsCompanyDetails?.salesMotives?.loanBusiness.balanceRules.rule1 === true && (
                <li>
                  {dashboardCompanySalesMotivesIntl['balanceRule1'] || `Drops for 3 months in a row (>20% per month)`}
                </li>
              )}
              {dashboardCompany.dsCompanyDetails?.salesMotives?.loanBusiness.balanceRules.rule2 === true && (
                <li>
                  {dashboardCompanySalesMotivesIntl['balanceRule2'] || `Drops for 6 months in a row (>20% per month)`}
                </li>
              )}
              {dashboardCompany.dsCompanyDetails?.salesMotives?.loanBusiness.balanceRules.rule3 === true && (
                <li>
                  {dashboardCompanySalesMotivesIntl['balanceRule3'] ||
                    `Average balance for the last 6 months is <= (1/2) * avg over entire period`}
                </li>
              )}
              {dashboardCompany.dsCompanyDetails?.salesMotives?.loanBusiness.balanceRules.rule4 === true && (
                <li>
                  {dashboardCompanySalesMotivesIntl['balanceRule4'] ||
                    `average balance in the last 6 months is >= 2 * avg over entire period`}
                </li>
              )}
              {dashboardCompany.dsCompanyDetails?.salesMotives?.loanBusiness.balanceRules.rule5 === true && (
                <li>{dashboardCompanySalesMotivesIntl['balanceRule5'] || `vp month balance <= 0.1 * vp-1 balance`}</li>
              )}
              {dashboardCompany.dsCompanyDetails?.salesMotives?.loanBusiness.balanceRules.rule6 === true && (
                <li>
                  {dashboardCompanySalesMotivesIntl['balanceRule6'] ||
                    `balance decreased by more than 20% at least 2 times in last 6 months (compared to prev month)`}
                </li>
              )}
              {dashboardCompany.dsCompanyDetails?.salesMotives?.loanBusiness.depositRules.rule1 === true && (
                <li>
                  {dashboardCompanySalesMotivesIntl['depositRule1'] ||
                    `6 consecutive months of increase (>20% per month)`}
                </li>
              )}
              {dashboardCompany.dsCompanyDetails?.salesMotives?.loanBusiness.depositRules.rule2 === true && (
                <li>
                  {dashboardCompanySalesMotivesIntl['depositRule2'] ||
                    `6 consecutive months of decrease (>20% per month)`}
                </li>
              )}
              {dashboardCompany.dsCompanyDetails?.salesMotives?.loanBusiness.depositRules.rule3 === true && (
                <li>
                  {dashboardCompanySalesMotivesIntl['depositRule3'] ||
                    `avg deposit amount of recent 6m <= (1/2) * avg over entire period`}
                </li>
              )}
              {dashboardCompany.dsCompanyDetails?.salesMotives?.loanBusiness.depositRules.rule4 === true && (
                <li>
                  {dashboardCompanySalesMotivesIntl['depositRule4'] ||
                    `avg deposit amount of recent 6m >= 2 * avg over entire period`}
                </li>
              )}
              {dashboardCompany.dsCompanyDetails?.salesMotives?.loanBusiness.withdrawalRules.rule1 === true && (
                <li>
                  {dashboardCompanySalesMotivesIntl['withdrawalRule1'] ||
                    `avg withdrawal amount over recent 6m <= (1/2) * avg over entire period`}
                </li>
              )}
              {dashboardCompany.dsCompanyDetails?.salesMotives?.loanBusiness.withdrawalRules.rule2 === true && (
                <li>
                  {dashboardCompanySalesMotivesIntl['withdrawalRule2'] ||
                    `avg withdrawal amount over recent 6m >= 2 * avg over entire period`}
                </li>
              )}
              {dashboardCompany.dsCompanyDetails?.salesMotives?.loanBusiness.withdrawalRules.rule3 === true && (
                <li>
                  {dashboardCompanySalesMotivesIntl['withdrawalRule3'] ||
                    `withdrawal increased by more than 20% at viewpoint (compared to prev month)`}
                </li>
              )}
              {dashboardCompany.dsCompanyDetails?.salesMotives?.loanBusiness.gainRules.rule1 === true && (
                <li>
                  {dashboardCompanySalesMotivesIntl['gainRule1'] ||
                    `For the 3 consecutive month, earnings are negative `}
                </li>
              )}
              {dashboardCompany.dsCompanyDetails?.salesMotives?.loanBusiness.gainRules.rule2 === true && (
                <li>{dashboardCompanySalesMotivesIntl['gainRule2'] || `6 consecutive months of negative earnings `}</li>
              )}
              {dashboardCompany.dsCompanyDetails?.salesMotives?.loanBusiness.gainRules.rule3 === true && (
                <li>{dashboardCompanySalesMotivesIntl['gainRule3'] || `gain is negative (loss) at viewpoint`}</li>
              )}
              {dashboardCompany.dsCompanyDetails?.salesMotives?.loanBusiness.gainRules.rule4 === true && (
                <li>
                  {dashboardCompanySalesMotivesIntl['gainRule4'] ||
                    `gain is negative (loss) at least 2 times in the last 6 months`}
                </li>
              )}
            </ul>
            <div className={styles.adviceHeader}>
              <div className={styles.adviceTitle}>{`事業支援に関するインサイト`}</div>
            </div>
            <ul className={styles.adviceList}>
              {dashboardCompany.dsCompanyDetails?.salesMotives?.businessSupport.salaryRules.rule1 === true && (
                <li>
                  {dashboardCompanySalesMotivesIntl['salaryRule1'] ||
                    `avg monthly payment over recent 6m <= 0.7 * avg over entire period`}
                </li>
              )}
              {dashboardCompany.dsCompanyDetails?.salesMotives?.businessSupport.salaryRules.rule2 === true && (
                <li>
                  {dashboardCompanySalesMotivesIntl['salaryRule2'] ||
                    `avg monthly payment over recent 6m >= 1.3 * avg over entire period`}
                </li>
              )}
              {dashboardCompany.dsCompanyDetails?.salesMotives?.businessSupport.salaryRules.rule3 === true && (
                <li>
                  {dashboardCompanySalesMotivesIntl['salaryRule3'] ||
                    `avg monthly payments over recent 6m <= 0.7 * avg over same period 1y before (applicable only when 24 months of data are put in)`}
                </li>
              )}
              {dashboardCompany.dsCompanyDetails?.salesMotives?.businessSupport.salaryRules.rule4 === true && (
                <li>
                  {dashboardCompanySalesMotivesIntl['salaryRule4'] ||
                    `avg monthly payment over recent 6m >= 1.3 * avg over same period 1y before (applicable only when inputting 24-month data)`}
                </li>
              )}
              {dashboardCompany.dsCompanyDetails?.salesMotives?.businessSupport.insuranceFeeRules.rule1 === true && (
                <li>
                  {dashboardCompanySalesMotivesIntl['insuranceFeeRule1'] ||
                    `Total premium payments over recent 12m <= 0.7 * avg over same period 1y ago (applicable only when 24-month data is put in)`}
                </li>
              )}
              {dashboardCompany.dsCompanyDetails?.salesMotives?.businessSupport.insuranceFeeRules.rule2 === true && (
                <li>
                  {dashboardCompanySalesMotivesIntl['insuranceFeeRule2'] ||
                    `Total premium payments over recent 12m >= 1.3 * avg over same period 1y ago (applicable only when inputting 24-month data)`}
                </li>
              )}
              {dashboardCompany.dsCompanyDetails?.salesMotives?.businessSupport.corporateTaxRules.rule1 === true && (
                <li>
                  {dashboardCompanySalesMotivesIntl['corporateTaxRule1'] ||
                    `avg corporate tax rate for the last 12m >= 2 * industry avg corporate tax`}
                </li>
              )}
            </ul>
          </div>
        </div>
      </div>
      <Tab items={analysisItems} activeItem={tabItem} onChange={setTabItem} />
      <div className={styles.line} />
      {tabItem.value === 'TRANSACTION' && <TransactionAnalysis dsCompany={dashboardCompany} />}
      {tabItem.value === 'BUSINESS' && <PartnerAnalysis dsCompany={dashboardCompany} />}
    </div>
  )
}

export const CompanyDetailSideModal = withSideModalPageWrapper(CompanyDetail)

const analysisItems: OptionType<string>[] = [
  {
    label: '取引先分析',
    value: 'BUSINESS',
  },
  {
    label: '入出金トレンド分析',
    value: 'TRANSACTION',
  },
]
