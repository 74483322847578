import { createAction, createReducer } from '@reduxjs/toolkit'
import { createAsyncAction } from 'src/redux/redux.type'
import {
  ProjectDetailType,
  ProjectRecordsType,
  ProjectGoalType,
  ProjectStepEnum,
  ProjectType,
  SegmentType,
  TargetType,
  ResultTargetsType,
  TargetColumnName,
} from 'src/type/project.type'
import {
  CreateProjectParamsType,
  DownloadProjectResultTargetsParamsType,
  FetchProjectDetailParamsType,
  FetchProjectGoalParamsType,
  FetchProjectResultSegmentsParamsType,
  FetchProjectResultTargetsParamsType,
  TempCallCreateDashboardParamsType,
  TrainingProjectParamsType,
  UpdateProjectParamsType,
} from './project.service'

type ProjectStateType = {
  pendingCount: number
  projectRecords: ProjectRecordsType | null
  projectIds: number[] | null
  selectedProjectId: number | null
  selectedProjectStep: ProjectStepEnum
  selectedProjectDetail: ProjectDetailType | null
  selectedProjectGoal: ProjectGoalType[] | null
  selectedProjectTargetsColumns: TargetColumnName[] | null
  selectedProjectResultTargets: TargetType[] | null
  selectedProjectResultTargetLength: number | null
  selectedProjectResultSegments: SegmentType[] | null
  selectedProjectResultTargetsPrev: TargetType[] | null
}

const increasePendingCount = createAction('[project] increase async action pending count')
const decreasePendingCount = createAction('[project] dencrease async action pending count')

const fetchProjects = createAsyncAction('[project] fetch projects')
const fetchProjectsInProgress = createAsyncAction('[project] fetch projects in task progress')
const setProjects = createAction<ProjectType[]>('[project] set projects')
const initSelectedProjectId = createAction('[project] init selected project id')
const setSelectedProjectId = createAction<{ projectId: number }>('[project] set selected project id')

const createProject = createAsyncAction<CreateProjectParamsType>('[project] create projects')
const setProject = createAction<ProjectType>('[project] set project')
const deleteProject = createAsyncAction<{ ids: number[] }>('[project] delete project')
const removeProject = createAction<{ projectIds: number[] }>('[project] remove project state')
const updateProject = createAsyncAction<UpdateProjectParamsType>('[project] update project')

const fetchProjectDetail = createAsyncAction<FetchProjectDetailParamsType>('[project] fetch selected project detail')
const fetchProjectDetailInProgress = createAsyncAction<FetchProjectDetailParamsType>(
  '[project] fetch selected project detail in task progress',
)
const setProjectDetail = createAction<ProjectDetailType>('[project] set selected project detail')

const fetchProjectGoal = createAsyncAction<FetchProjectGoalParamsType>('[project] fetch selected project goal')
const setProjectGoal = createAction<ProjectGoalType[]>('[project] set selected project goal')

const updateProjectSetting = createAsyncAction<{
  projectId: number
  goalParams: { goalId: number; value: number | string }[]
  deleteGoalParams: { goalId: number }[]
}>('[project] update selected project setting')

const setProjectStep = createAction<ProjectStepEnum>('[project] set selected project step')

const trainingProject = createAsyncAction<TrainingProjectParamsType>('[project] training projects')

const fetchProjectResultTargets = createAsyncAction<FetchProjectResultTargetsParamsType>(
  '[project] fetch selected project result targets',
)
const fetchProjectResultTargetsInProgress = createAsyncAction<FetchProjectResultTargetsParamsType>(
  '[project] fetch selected project result targets in task progress',
)
const setProjectResultTargets = createAction<ResultTargetsType>('[project] set selected project result targets')

const fetchProjectResultTargetsPrev = createAsyncAction<FetchProjectResultTargetsParamsType>(
  '[project] fetch selected project result targets filter prev',
)
const setProjectResultTargetsPrev = createAction<ResultTargetsType>(
  '[project] set selected project result targets filter prev',
)

const fetchProjectResultSegments = createAsyncAction<FetchProjectResultSegmentsParamsType>(
  '[project] fetch selected project result segments',
)
const setProjectResultSegments = createAction<SegmentType[]>('[project] set selected project segments')

const downloadProjectResultTargets = createAsyncAction<DownloadProjectResultTargetsParamsType>(
  '[project] create and download result targets file',
)

// TEMPORARY
const tempCallCreateDashboard = createAsyncAction<TempCallCreateDashboardParamsType>(
  '[project] TEMP call create dashboard api',
)

export const projectAction = {
  increasePendingCount,
  decreasePendingCount,

  fetchProjects,
  fetchProjectsInProgress,
  setProjects,
  initSelectedProjectId,
  setSelectedProjectId,

  createProject,
  setProject,
  deleteProject,
  removeProject,
  updateProject,

  fetchProjectDetail,
  fetchProjectDetailInProgress,
  setProjectDetail,

  fetchProjectGoal,
  setProjectGoal,

  updateProjectSetting,

  setProjectStep,

  trainingProject,

  fetchProjectResultTargets,
  fetchProjectResultTargetsInProgress,
  setProjectResultTargets,

  fetchProjectResultTargetsPrev,
  setProjectResultTargetsPrev,

  fetchProjectResultSegments,
  setProjectResultSegments,

  downloadProjectResultTargets,
  tempCallCreateDashboard,
}

const initProjectState: ProjectStateType = {
  pendingCount: 0,
  projectRecords: null,
  projectIds: null,
  selectedProjectId: null,
  selectedProjectStep: ProjectStepEnum.NONE,
  selectedProjectDetail: null,
  selectedProjectGoal: null,
  selectedProjectTargetsColumns: null,
  selectedProjectResultTargets: null,
  selectedProjectResultTargetLength: null,
  selectedProjectResultSegments: null,
  selectedProjectResultTargetsPrev: null,
}

export const projectReducer = createReducer(initProjectState, (builder) =>
  builder
    .addCase(increasePendingCount, (state) => {
      state.pendingCount++
    })
    .addCase(decreasePendingCount, (state) => {
      state.pendingCount--
    })
    .addCase(setProjects, (state, { payload }) => {
      state.projectRecords = payload.reduce<ProjectRecordsType>((res, project) => {
        res[project.id] = project
        return res
      }, {})

      state.projectIds = payload.map((data) => data.id)
    })
    .addCase(initSelectedProjectId, (state) => {
      state.selectedProjectId = null
      state.selectedProjectStep = ProjectStepEnum.NONE
      state.selectedProjectDetail = null
      state.selectedProjectGoal = null
    })
    .addCase(setSelectedProjectId, (state, { payload }) => {
      state.selectedProjectId = payload.projectId
    })
    .addCase(setProject, (state, { payload }) => {
      if (!state.projectRecords) {
        state.projectRecords = {}
      }

      if (!state.projectIds) {
        state.projectIds = [payload.id]
      } else if (!state.projectRecords[payload.id]) {
        state.projectIds.push(payload.id)
      }

      state.projectRecords[payload.id] = { ...state.projectRecords[payload.id], ...payload }
    })
    .addCase(removeProject, (state, { payload }) => {
      if (state.projectIds) {
        state.projectIds = state.projectIds.filter((projectId) => !payload.projectIds.includes(projectId))
      }

      payload.projectIds.forEach((id) => {
        if (state.projectRecords) {
          delete state.projectRecords[id]
        }
      })
    })
    .addCase(setProjectDetail, (state, { payload }) => {
      state.selectedProjectDetail = payload
    })
    .addCase(setProjectGoal, (state, { payload }) => {
      state.selectedProjectGoal = payload
    })
    .addCase(setProjectStep, (state, { payload }) => {
      state.selectedProjectStep = payload
    })
    .addCase(setProjectResultTargets, (state, { payload }) => {
      state.selectedProjectTargetsColumns = payload.field
      state.selectedProjectResultTargets = payload.target
      state.selectedProjectResultTargetLength = payload.resultTargetLength
    })
    .addCase(setProjectResultTargetsPrev, (state, { payload }) => {
      state.selectedProjectResultTargetsPrev = payload.target
    })
    .addCase(setProjectResultSegments, (state, { payload }) => {
      state.selectedProjectResultSegments = payload.sort((a, b) => a.id - b.id)
    }),
)
