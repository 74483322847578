import classNames from 'classnames'
import React, { useEffect, useMemo, useState } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { ChartPie } from 'src/component/molecule/chart-pie/chart-pie'
import { CellRenderProps, DataTable, DataTableColumnDefsType } from 'src/component/molecule/data-table/data-table'
import { DropdownSingleSelector } from 'src/component/molecule/dropdown-single-selector/dropdown-single-selector'
import { dashboardContractDataIntl, dashboardContractIntl } from 'src/intl/data/dashboard.key'
import { branchSelector } from 'src/redux/branch/branch.state'
import { dashboardAction } from 'src/redux/dashboard/dashboard.action'
import { dashboardSelector } from 'src/redux/dashboard/dashboard.state'
import { OptionType } from 'src/type/common.type'
import { PieChartDataType } from 'src/type/dashboard.type'
import styles from './contract-trand.scss'

export const ContractTrand = () => {
  const dispatch = useDispatch()

  const [selectedIndustry, setSelectedIndustry] = useState<string>()

  const branchRecordsByCode = useSelector(branchSelector.branchRecordsByCode)
  const dashboardContract = useSelector(dashboardSelector.dashboardContract)
  const contractInderstyColor = useSelector(dashboardSelector.contractInderstyColor)
  const branchCodeOptions = useSelector(dashboardSelector.dsContractBranchCodeOptions)
  const lookbackPeriodOptions = useSelector(dashboardSelector.dsContractLookbackPeriodOptions)
  const selectedBranchCode = useSelector(dashboardSelector.selectedContractBranchCode)
  const selectedLookbackPeriod = useSelector(dashboardSelector.selectedLookbackPeriod)
  const unspecifiedIndustryCompanyCount = useSelector(dashboardSelector.unspecifiedIndustryCompanyCount)

  const unspecifiedIndustryCompanyRate = useSelector(dashboardSelector.unspecifiedIndustryCompanyRate)

  useEffect(() => {
    if (selectedBranchCode) {
      dispatch(dashboardAction.fetchDashboardCompanyUnspecifiedIndustry.request({ branchCode: selectedBranchCode }))
    }
  }, [selectedBranchCode])

  const customerBranchCodeOptions = useMemo(() => {
    return branchCodeOptions.map<OptionType>((bc) => ({
      label:
        branchRecordsByCode[bc.value]?.branchName ||
        (bc.value === 'allBranches' ? dashboardContractDataIntl['allBranches'] : bc.value),
      value: bc.value,
    }))
  }, [branchCodeOptions, branchRecordsByCode])

  const selectedBranchCodeOption = useMemo(
    () => customerBranchCodeOptions.find((op) => op.value === selectedBranchCode),
    [customerBranchCodeOptions, selectedBranchCode],
  )

  const selectedLookbackPeriodOption = useMemo(
    () => lookbackPeriodOptions.find((o) => o.value === selectedLookbackPeriod),
    [selectedLookbackPeriod],
  )

  const pieCharts = useMemo(() => {
    const pieContract: PieChartDataType[] = []
    const pieLoan1Count: PieChartDataType[] = []
    const pieLoan1Amount: PieChartDataType[] = []
    const pieLoan2Count: PieChartDataType[] = []
    const pieLoan2Amount: PieChartDataType[] = []

    dashboardContract?.forEach((item) => {
      item.depositContract &&
        pieContract.push({
          ...item.depositContract,
          label: dashboardContractDataIntl[item.depositContract.name],
        })
      item.contractLoan1Count &&
        pieLoan1Count.push({
          ...item.contractLoan1Count,
          label: dashboardContractDataIntl[item.contractLoan1Count.name],
        })
      item.contractLoan1Amount &&
        pieLoan1Amount.push({
          ...item.contractLoan1Amount,
          label: dashboardContractDataIntl[item.contractLoan1Amount.name],
        })
      item.contractLoan2Count &&
        pieLoan2Count.push({
          ...item.contractLoan2Count,
          label: dashboardContractDataIntl[item.contractLoan2Count.name],
        })
      item.contractLoan2Amount &&
        pieLoan2Amount.push({
          ...item.contractLoan2Amount,
          label: dashboardContractDataIntl[item.contractLoan2Amount.name],
        })
    })

    return { pieContract, pieLoan1Count, pieLoan1Amount, pieLoan2Count, pieLoan2Amount }
  }, [dashboardContract])

  const contractGridData = useMemo(() => {
    return dashboardContract?.map((item) => ({
      rank: item.rank,
      depositContract: item.depositContract?.y
        ? `${item.depositContract?.name} ${new Intl.NumberFormat('ko-KR', {
            maximumFractionDigits: 0,
          }).format(Number(item.depositContract?.y || 0))}件 / ${new Intl.NumberFormat('ko-KR', {
            maximumFractionDigits: 1,
          }).format(Number(item.depositContract?.percentage * 100))}%`
        : undefined,
      contractLoan1Count: item.contractLoan1Count?.y
        ? `${item.contractLoan1Count?.name} ${new Intl.NumberFormat('ko-KR', {
            maximumFractionDigits: 0,
          }).format(Number(item.contractLoan1Count?.y || 0))}件 / ${new Intl.NumberFormat('ko-KR', {
            maximumFractionDigits: 1,
          }).format(Number(item.contractLoan1Count?.percentage * 100))}%`
        : undefined,
      contractLoan1Amount: item.contractLoan1Amount?.y
        ? `${item.contractLoan1Amount?.name} ${new Intl.NumberFormat('ko-KR', {
            maximumFractionDigits: 0,
          }).format(Number((item.contractLoan1Amount?.y || 0) / 1000000))}百万円 / ${new Intl.NumberFormat('ko-KR', {
            maximumFractionDigits: 1,
          }).format(Number(item.contractLoan1Amount?.percentage * 100))}%`
        : undefined,
      contractLoan2Count: item.contractLoan2Count?.y
        ? `${item.contractLoan2Count?.name} ${new Intl.NumberFormat('ko-KR', {
            maximumFractionDigits: 0,
          }).format(Number(item.contractLoan2Count?.y || 0))}件 / ${new Intl.NumberFormat('ko-KR', {
            maximumFractionDigits: 1,
          }).format(Number(item.contractLoan2Count?.percentage * 100))}%`
        : undefined,
      contractLoan2Amount: item.contractLoan2Amount?.y
        ? `${item.contractLoan2Amount?.name} ${new Intl.NumberFormat('ko-KR', {
            maximumFractionDigits: 0,
          }).format(Number((item.contractLoan2Amount?.y || 0) / 1000000))}百万円 / ${new Intl.NumberFormat('ko-KR', {
            maximumFractionDigits: 1,
          }).format(Number(item.contractLoan2Amount?.percentage * 100))}%`
        : undefined,
    }))
  }, [dashboardContract])

  const dataColumnDefObjs: DataTableColumnDefsType[] = useMemo(
    () => [
      {
        dataKey: 'rank',
        label: dashboardContractIntl['rank'] || 'rank',
        width: 80,
        headerAlign: 'center',
        headerBold: true,
        cellAlign: 'center',
      },
      {
        dataKey: 'depositContract',
        label: dashboardContractIntl['depositContract'] || 'depositContract',
        flexRate: 1,
        headerBold: true,
        cellRenderer: industryColumnCellRenderer,
      },
      {
        dataKey: 'contractLoan1Count',
        label: dashboardContractIntl['contractLoan1Count'] || 'contractLoan1Count',
        flexRate: 1,
        headerBold: true,
        cellRenderer: industryColumnCellRenderer,
      },
      {
        dataKey: 'contractLoan1Amount',
        label: dashboardContractIntl['contractLoan1Amount'] || 'contractLoan1Amount',
        flexRate: 1,
        headerBold: true,
        cellRenderer: industryColumnCellRenderer,
      },
      {
        dataKey: 'contractLoan2Count',
        label: dashboardContractIntl['contractLoan2Count'] || 'contractLoan2Count',
        flexRate: 1,
        headerBold: true,
        cellRenderer: industryColumnCellRenderer,
      },
      {
        dataKey: 'contractLoan2Amount',
        label: dashboardContractIntl['contractLoan2Amount'] || 'contractLoan2Amount',
        flexRate: 1,
        headerBold: true,
        cellRenderer: industryColumnCellRenderer,
      },
    ],
    [contractInderstyColor, selectedIndustry],
  )

  function industryColumnCellRenderer(cellRenderProps: CellRenderProps) {
    const contractDataSplit = ((cellRenderProps.cellData || '') as string).split(' ')
    const industryString = contractDataSplit[0]
    contractDataSplit[0] = dashboardContractDataIntl[industryString] || industryString

    const translatedIndustryData = contractDataSplit.join(' ')

    return (
      <>
        {cellRenderProps.cellData ? (
          <div
            className={classNames(styles.industryCell, {
              [styles.notSelected]: selectedIndustry && selectedIndustry !== industryString,
            })}
            onClick={() => handleClickIndustry(industryString)}
          >
            <div className={styles.bullet} style={{ backgroundColor: contractInderstyColor[industryString] }} />
            {translatedIndustryData}
          </div>
        ) : (
          <>-</>
        )}
      </>
    )
  }

  function handleClickIndustry(industryString: string) {
    if (selectedIndustry !== industryString) {
      setSelectedIndustry(industryString)
    } else {
      setSelectedIndustry(undefined)
    }
  }

  function handleClickDisselectIndustry() {
    setSelectedIndustry(undefined)
  }

  function handleChangeSelectBranchCode(option: OptionType<string>) {
    dispatch(dashboardAction.setSelectedContractBranchCode(option.value))
  }

  function handleChangeSelectLookbackPeriod(option: OptionType<string>) {
    dispatch(dashboardAction.setSelectedLookbackPeriod(option.value))
  }

  return (
    <div className={styles.root}>
      <div className={styles.tabContentHeader}>
        <div>{`新規案件の成約が多い業種 Top 10`}</div>
        <div className={styles.branchController}>
          <div>{`支店名`}</div>
          <DropdownSingleSelector
            sizeType='lg'
            isNeededFilter
            placeholder={branchCodeOptions.length === 0 ? '支店なし' : '支店を選択'}
            disabled={branchCodeOptions.length === 0}
            options={customerBranchCodeOptions}
            selectedOption={selectedBranchCodeOption}
            onChange={handleChangeSelectBranchCode}
          />
        </div>
        <div className={styles.lookbackPeriodController}>
          <div>{`集計期間`}</div>
          <DropdownSingleSelector
            sizeType='lg'
            isNeededFilter={false}
            options={lookbackPeriodOptions}
            placeholder={lookbackPeriodOptions.length === 0 ? '集計期間なし' : '集計期間を選択'}
            disabled={lookbackPeriodOptions.length === 0}
            selectedOption={selectedLookbackPeriodOption}
            onChange={handleChangeSelectLookbackPeriod}
          />
        </div>
      </div>
      <div className={styles.isUnspecifiedIndustry}>
        {unspecifiedIndustryCompanyCount > 0 &&
          `法人および個人事業主全体のうち、${new Intl.NumberFormat('ko-KR', {
            maximumFractionDigits: 2,
          }).format(unspecifiedIndustryCompanyCount)}社 (${new Intl.NumberFormat('ko-KR', {
            maximumFractionDigits: 2,
          }).format(
            unspecifiedIndustryCompanyRate,
          )}%)にて業種情報が欠落しています。より安定的な分析結果を得るために業種情報を追加した上でデータアップロードすることを推奨します。`}
      </div>
      <div className={styles.chartWrapper}>
        <ChartPie
          height={300}
          pieSize={200}
          title={`預金口座開設`}
          yUnit={`件`}
          data={pieCharts.pieContract}
          colorSets={contractInderstyColor}
          selectedDataName={selectedIndustry}
          onClickItem={setSelectedIndustry}
          onClickOutside={handleClickDisselectIndustry}
        />
        <ChartPie
          height={300}
          pieSize={200}
          title={`短期（件数ベース）`}
          yUnit={`件`}
          data={pieCharts.pieLoan1Count}
          colorSets={contractInderstyColor}
          selectedDataName={selectedIndustry}
          onClickItem={setSelectedIndustry}
          onClickOutside={handleClickDisselectIndustry}
        />
        <ChartPie
          height={300}
          pieSize={200}
          title={`短期（残高ベース）`}
          yUnit={`円`}
          data={pieCharts.pieLoan1Amount}
          colorSets={contractInderstyColor}
          selectedDataName={selectedIndustry}
          onClickItem={setSelectedIndustry}
          onClickOutside={handleClickDisselectIndustry}
        />
        <ChartPie
          height={300}
          pieSize={200}
          title={`長期（件数ベース）`}
          yUnit={`件`}
          data={pieCharts.pieLoan2Count}
          colorSets={contractInderstyColor}
          selectedDataName={selectedIndustry}
          onClickItem={setSelectedIndustry}
          onClickOutside={handleClickDisselectIndustry}
        />
        <ChartPie
          height={300}
          pieSize={200}
          title={`長期（残高ベース）`}
          yUnit={`円`}
          data={pieCharts.pieLoan2Amount}
          colorSets={contractInderstyColor}
          selectedDataName={selectedIndustry}
          onClickItem={setSelectedIndustry}
          onClickOutside={handleClickDisselectIndustry}
        />
      </div>
      <div className={styles.tableWrapper}>
        <DataTable
          dataColumnDefs={dataColumnDefObjs}
          dataList={contractGridData || []}
          theme='light'
          headerHeight={70}
          rowHeight={70}
          columnSeperator
          rowSeperator={false}
          colorizedRow
        />
      </div>
    </div>
  )
}
