import React, { Fragment, useMemo } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { Button } from 'src/component/atom/button/button'
import { FileUploader } from 'src/component/molecule/file-uploader/file-uploader'
import { Tooltip } from 'src/component/molecule/tooltip/tooltip'
import { datasetLabelIntl } from 'src/intl/data/dataset.key'
import { datasetAction } from 'src/redux/dataset/dataset.action'
import { datasetSelector } from 'src/redux/dataset/dataset.state'
import { projectAction } from 'src/redux/project/project.action'
import { unionSelector } from 'src/redux/union/union.state'
import { DatasetTypesEnum, DatasetStatusEnum } from 'src/type/dataset.type'
import { ProjectStepEnum, ProjectType, ProjectTypesEnum } from 'src/type/project.type'
import { ProjectTypesProperties } from 'src/ui-variable/project.ui'
import styles from './upload.scss'

const FILE_SIZE_LIMIT_GB = 50

type PropsType = {
  project: ProjectType
}

export const UploadPanel = ({ project }: PropsType) => {
  const dispatch = useDispatch()

  const datasets = useSelector(datasetSelector.datasets)
  const datasetWithCategory = useSelector(datasetSelector.datasetWithDatasetTypes)
  const selectedProjectDatasetProgresses = useSelector(unionSelector.selectedProjectDatasetProgresses)

  const nextDisabled = useMemo(() => {
    return (
      datasets.some((dataset) => dataset.status !== DatasetStatusEnum.DESCRIBED) ||
      datasets.length < ProjectTypesProperties[project.type].datasetCategories.length
    )
  }, [datasets])

  function handleChangeUploadFile(category: DatasetTypesEnum, file?: File) {
    file &&
      dispatch(
        datasetAction.createDataset.request({
          file,
          type: category,
          projectId: project.id,
        }),
      )
  }

  function handleDeleteFileClick(datasetId: number) {
    dispatch(datasetAction.deleteDataset.request({ id: datasetId }))
  }

  function handlePrevButtonClick() {
    dispatch(projectAction.setProjectStep(ProjectStepEnum.SETTING))
  }

  function handleNextButtonClick() {
    dispatch(projectAction.setProjectStep(ProjectStepEnum.BLUE_PRINT))
  }

  return (
    <div className={styles.root}>
      <div className={styles.sectionTitle}>{`データセットをアップロードしてください`}</div>
      <small className={styles.sectionDesc}>{`クリックしてファイルをアップロードまたはドラック＆ドロップ`}</small>
      <ol className={styles.uploadSection}>
        {ProjectTypesProperties[project.type].datasetCategories.map((dsCategory) => (
          <div key={dsCategory} className={styles.uploadItem}>
            <li className={styles.uploadTitle}>
              <label className={styles.uploadLabel}>
                {datasetLabelIntl[project.type][dsCategory].title}
                {datasetLabelIntl[project.type][dsCategory].description && (
                  <Tooltip>
                    <pre className={styles.datasetInfo}>{datasetLabelIntl[project.type][dsCategory].description}</pre>
                  </Tooltip>
                )}
              </label>
            </li>
            <div className={styles.uploadBody}>
              <FileUploader
                fileName={datasetWithCategory[dsCategory]?.originalFileName}
                fileSize={datasetWithCategory[dsCategory]?.size}
                fileSizeLimitGB={FILE_SIZE_LIMIT_GB}
                uploadProgress={selectedProjectDatasetProgresses[datasetWithCategory[dsCategory]?.id]}
                layout={
                  project.type === ProjectTypesEnum.CORP_SALES_INSIGHTS &&
                  (dsCategory === DatasetTypesEnum.SHORT_TERM_LOAN || dsCategory === DatasetTypesEnum.LONG_TERM_LOAN)
                    ? 'horizonal'
                    : 'vertical'
                }
                onChange={(file) => handleChangeUploadFile(dsCategory, file)}
                onDeleteButtonClick={() =>
                  datasetWithCategory[dsCategory] && handleDeleteFileClick(datasetWithCategory[dsCategory].id)
                }
              />
            </div>
          </div>
        ))}
      </ol>
      <small
        className={styles.uploadDesc}
      >{`(アップロードできるファイルの最大サイズは ${FILE_SIZE_LIMIT_GB}GB です。)`}</small>
      <div className={styles.footer}>
        <a href='#' className={styles.outQnaLink}>
          {``}
        </a>
        <Button sizeType='lg' outlined onClick={handlePrevButtonClick}>
          {`前へ`}
        </Button>
        <Button sizeType='lg' disabled={nextDisabled} onClick={handleNextButtonClick}>{`次へ`}</Button>
      </div>
    </div>
  )
}
