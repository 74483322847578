import { DatasetTypesEnum } from 'src/type/dataset.type'
import { ProjectTypesEnum } from 'src/type/project.type'

export const datasetLabelIntl: Record<
  ProjectTypesEnum,
  { [datasetTypes: string]: { title: string; description: string | null } }
> = {
  [ProjectTypesEnum.CHURN_PREVENTION]: {
    [DatasetTypesEnum.CUSTOMER as string]: {
      title: `顧客データ`,
      description: null,
    },
    [DatasetTypesEnum.TRANSACTION as string]: {
      title: `取引履歴データ`,
      description: null,
    },
  },
  [ProjectTypesEnum.CROSS_SELLING]: {
    [DatasetTypesEnum.CUSTOMER as string]: {
      title: `顧客データ`,
      description: `例：預金商品の顧客に対し、\n投資信託の勧誘施策を打ちたいのであれば、\n「預金」の顧客属性データ`,
    },
    [DatasetTypesEnum.TARGET_CUSTOMER as string]: {
      title: `顧客データ(クロスセル対象商品)`,
      description: `例：預金商品の顧客に対し、\n投資信託の勧誘施策を打ちたいのであれば、\n「投資信託」の顧客属性データ`,
    },
    [DatasetTypesEnum.TRANSACTION as string]: {
      title: `取引履歴データ`,
      description: `例：預金商品の顧客に対し、\n投資信託の勧誘施策を打ちたいのであれば、\n「預金」の利用履歴データ)`,
    },
  },
  [ProjectTypesEnum.UP_SELLING]: {
    [DatasetTypesEnum.CUSTOMER as string]: {
      title: `顧客データ`,
      description: null,
    },
    [DatasetTypesEnum.TRANSACTION as string]: {
      title: `取引履歴データ`,
      description: null,
    },
  },
  [ProjectTypesEnum.CORP_LOAN_DEMAND]: {
    [DatasetTypesEnum.CUSTOMER as string]: {
      title: `顧客データ`,
      description: null,
    },
    [DatasetTypesEnum.LOAN as string]: {
      title: `融資実行データ`,
      description: null,
    },
    [DatasetTypesEnum.TRANSACTION as string]: {
      title: `取引履歴データ`,
      description: null,
    },
  },
  [ProjectTypesEnum.CORP_SALES_INSIGHTS]: {
    [DatasetTypesEnum.CUSTOMER as string]: {
      title: `企業属性データ`,
      description: null,
    },
    [DatasetTypesEnum.SHORT_TERM_LOAN as string]: {
      title: `短期性融資の実行データ`,
      description: null,
    },
    [DatasetTypesEnum.LONG_TERM_LOAN as string]: {
      title: `長期性融資の実行データ`,
      description: null,
    },
    [DatasetTypesEnum.TRANSACTION as string]: {
      title: `取引履歴データ`,
      description: null,
    },
  },
}
