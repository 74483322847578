export const dashboardContractIntl: Record<string, string> = {
  CONTRACT_TRAND: '新規契約トレンド',
  rank: '順位',
  depositContract: '預金口座開設（件数/シェア)',
  contractLoan1Count: '短期（件数/シェア)',
  contractLoan1Amount: '短期（残高/シェア）',
  contractLoan2Count: '長期（件数/シェア)',
  contractLoan2Amount: '長期（残高/シェア）',
}

export const dashboardContractDataIntl: Record<string, string> = {
  allBranches: '全支店',
  remaining: 'その他',
  remaining_RGT: 'その他_RGT',
}

export const dashboardIndustryDataIntl: Record<string, string> = {
  unspecificIndustry: '業種情報欠落',
}

export const dashboardCompanyDataIntl: Record<string, string> = {
  allBranches: '全支店',
}

export const dashboardCompanySalesMotivesIntl: Record<string, string> = {
  loanRule1: 'Rule1-1 : AIが予測した結果、短期に対する需要が非常に高いと出ています。資金ニーズの確認をしましょう。',
  loanRule2: 'Rule1-2 : AIが予測した結果、長期に対する需要が非常に高いと出ています。資金ニーズの確認をしましょう。',
  balanceRule1:
    'Rule2-1 : 3か月連続で残高が20%以上減少しています。残高が減少している要因と借入ニーズの確認をしましょう。',
  balanceRule2:
    'Rule2-2 : 6か月連続で残高が20%以上減少しています。当社の経営状況に問題がないか一度ヒアリングしましょう。',
  balanceRule3:
    'Rule2-3 : 直近6か月の平残が過去の平残水準と比べて半分以下になっています。資金流出が大きく発生している要因を確認しましょう。',
  balanceRule4:
    'Rule2-4 : 直近6か月の平残が過去の平残水準と比べて2倍以上となっています。資金の流入が続いている要因や今後の成長戦略を確認しましょう。',
  balanceRule5:
    'Rule2-5 : 直近で残高が90%以上減少しています。単なる入金タイミングのズレなのか、それとも他の要因なのかをヒアリングしましょう。',
  balanceRule6:
    'Rule2-6 : 直近6か月において、残高が前の月と比べ20%以上減少するケースが2回以上ありました。短期的な資金需要を確認してみましょう。',
  depositRule1:
    'Rule3-1 : 毎月20%以上、6か月連続で月次入金額が増加しています。入金（売上）が増加している要因や当社が今後目指す方向性を確認しましょう。',
  depositRule2:
    'Rule3-2 : 毎月20%以上、6か月連続で月次入金額が減少しています。入金（売上）が減りつづけている要因や今後の戦略を確認しましょう。',
  depositRule3:
    'Rule3-3 : 直近6か月の平均月次入金額が全体期間の平均月次入金額と比べ、半分以下の水準となっています。売上が減少している要因や今後の資金繰りを確認しましょう。',
  depositRule4:
    'Rule3-4 : 直近6か月の平均月次入金額が全体期間の平均月次入金額と比べ、2倍以上の水準となっています。今後も同様のトレンドが見込まれるのか、更なる成長資金のニーズはないか確認してみましょう。',
  withdrawalRule1:
    'Rule4-1 : 直近6か月の平均月次出金額が全体期間の平均月次出金額と比べ、半分以下の水準となっています。出金が継続的に減少している要因をまずは確認してみましょう。',
  withdrawalRule2:
    'Rule4-2 : 直近6か月の平均月次出金額が全体期間の平均月次出金額と比べ、2倍以上の水準となっています。将来に向けた投資を行っているのか等、出金が増加している要因のヒアリングや更なる成長資金の必要性を確認および検討してみましょう。',
  withdrawalRule3:
    'Rule4-3 : 直近月にて出金額が前々月と比べ20%以上増加しています。一時的な状況なのかを確認しましょう。',
  gainRule1:
    'Rule5-1 : 3か月連続で月次出金額が月次入金額を上回る状況が続いています。近々の資金繰りおよび短期借入のニーズがないかを確認しましょう。',
  gainRule2:
    'Rule5-2 : 6か月連続で月次出金額が月次入金額を上回る状況が続いています。経営上、大きな問題が生じていないかや、今後の資金繰り計画をディスカッションしましょう。',
  gainRule3: 'Rule5-3 : 直近月にて出金額が入金額を超過しています。グラフで期間全体のキャッシュフローを確認しましょう。',
  gainRule4:
    'Rule5-4 : 直近6か月において、出金額が入金額を超過した月が2回以上ありました。グラフで期間全体のキャッシュフローを確認しましょう。',
  salaryRule1:
    'Rule7-1 : 直近6か月の給与支払平均額が全体期間の平均額と比べ30%以上減少しています。従業員の流出が続いている等の経営上の問題がないか確認しましょう。',
  salaryRule2:
    'Rule7-2 : 直近6か月の給与支払平均額が全体期間の平均額と比べ30%以上増加しています。更なる事業成長に向けた積極的な採用を進めているか等、まずは要因を確認しましょう。',
  salaryRule3:
    'Rule7-3 : 直近6か月の給与支払平均額が前年の同時期と比べ30%以上減少しています。従業員の流出が続いている等の経営上の問題がないか確認しましょう。',
  salaryRule4:
    'Rule7-4 : 直近6か月の給与支払平均額が前年の同時期と比べ30%以上増加しています。更なる事業成長に向けた積極的な採用を進めているか等、まずは要因を確認しましょう。',
  insuranceFeeRule1:
    'Rule8-1 : 直近12か月の社会保険料の支払額が前年同時期と比べ30%以上減少しています。従業員の減少によるものなのか、他金融機関を通じて支払いがなされたものなのか等を確認してみましょう。',
  insuranceFeeRule2:
    'Rule8-2 : 直近12か月の社会保険料の支払額が前年同時期と比べ30%以上増加しています。従業員の増加によるものなのか等、要因を確認しましょう。',
  corporateTaxRule1:
    'Rule9-1 : 直近12か月の月次損益に対する法人税の支払い比率が同業他社と比べ2倍以上の水準となっています。税金対策はきちんと出来ているのか等をヒアリングしましょう。',
}
