import { Action } from '@reduxjs/toolkit'
import { Epic, combineEpics } from 'redux-observable'
import { Observable, catchError, filter, map, mergeMap, of, switchMap, tap } from 'rxjs'
import { httpErrorhandling } from '../redux.util'
import { dashboardAction } from './dashboard.action'
import { dashboardService } from './dashboard.service'

const pendingStartEpic: Epic = (actions$: Observable<Action>) =>
  actions$.pipe(
    filter((action) =>
      [
        dashboardAction.createDashboardCompany.request.type,
        dashboardAction.createDashboardIndustry.request.type,
        dashboardAction.createDashboardContract.request.type,
        dashboardAction.fetchDashboardCompanyDownload.request.type,
        dashboardAction.fetchDashboardCompanyList.request.type,
        dashboardAction.fetchDashboardCompanyGradeDatas.request.type,
        dashboardAction.fetchDashboardCompanyUnspecifiedIndustry.request.type,
        dashboardAction.fetchDashboardIndustry.request.type,
        dashboardAction.fetchDashboardIndustryFilterCorporateTypes.request.type,
        dashboardAction.fetchDashboardIndustryFilterIndustry1s.request.type,
        dashboardAction.fetchDashboardIndustryFilterIndustry2s.request.type,
        dashboardAction.fetchDashboardIndustryFilterIndustry3s.request.type,
        dashboardAction.fetchDashboardContract.request.type,
        dashboardAction.createDsCompanyDoc.request.type,
        dashboardAction.downloadDsCompanyList.request.type,
        dashboardAction.downloadDsContractDoc.request.type,
        dashboardAction.downloadDsIndustryDoc.request.type,
        dashboardAction.fetchDashboardCompanyRanks.request.type,
        dashboardAction.fetchDashboardCompanyGradeCumulativeRates.request.type,
        dashboardAction.fetchDashboardCompanyMainPartners.request.type,
        dashboardAction.fetchDashboardCompanyNewPartners.request.type,
        dashboardAction.fetchDashboardCompanyExistPartners.request.type,
        dashboardAction.fetchDashboardCompanyGradeInfos.request.type,
      ].includes(action.type),
    ),
    map(() => dashboardAction.increasePendingCount()),
  )

const pendingEndEpic: Epic = (actions$: Observable<Action>) =>
  actions$.pipe(
    filter((action) =>
      [
        dashboardAction.createDashboardCompany.success.type,
        dashboardAction.createDashboardCompany.failure.type,
        dashboardAction.createDashboardCompany.cancelled.type,
        dashboardAction.createDashboardIndustry.success.type,
        dashboardAction.createDashboardIndustry.failure.type,
        dashboardAction.createDashboardIndustry.cancelled.type,
        dashboardAction.createDashboardContract.success.type,
        dashboardAction.createDashboardContract.failure.type,
        dashboardAction.createDashboardContract.cancelled.type,
        dashboardAction.fetchDashboardCompanyDownload.success.type,
        dashboardAction.fetchDashboardCompanyDownload.failure.type,
        dashboardAction.fetchDashboardCompanyDownload.cancelled.type,
        dashboardAction.fetchDashboardCompanyGradeDatas.success.type,
        dashboardAction.fetchDashboardCompanyGradeDatas.failure.type,
        dashboardAction.fetchDashboardCompanyGradeDatas.cancelled.type,
        dashboardAction.fetchDashboardCompanyList.success.type,
        dashboardAction.fetchDashboardCompanyList.failure.type,
        dashboardAction.fetchDashboardCompanyList.cancelled.type,
        dashboardAction.fetchDashboardCompanyUnspecifiedIndustry.success.type,
        dashboardAction.fetchDashboardCompanyUnspecifiedIndustry.failure.type,
        dashboardAction.fetchDashboardCompanyUnspecifiedIndustry.cancelled.type,
        dashboardAction.fetchDashboardIndustry.success.type,
        dashboardAction.fetchDashboardIndustry.failure.type,
        dashboardAction.fetchDashboardIndustry.cancelled.type,
        dashboardAction.fetchDashboardIndustryFilterCorporateTypes.success.type,
        dashboardAction.fetchDashboardIndustryFilterCorporateTypes.failure.type,
        dashboardAction.fetchDashboardIndustryFilterCorporateTypes.cancelled.type,
        dashboardAction.fetchDashboardIndustryFilterIndustry1s.success.type,
        dashboardAction.fetchDashboardIndustryFilterIndustry1s.failure.type,
        dashboardAction.fetchDashboardIndustryFilterIndustry1s.cancelled.type,
        dashboardAction.fetchDashboardIndustryFilterIndustry2s.success.type,
        dashboardAction.fetchDashboardIndustryFilterIndustry2s.failure.type,
        dashboardAction.fetchDashboardIndustryFilterIndustry2s.cancelled.type,
        dashboardAction.fetchDashboardIndustryFilterIndustry3s.success.type,
        dashboardAction.fetchDashboardIndustryFilterIndustry3s.failure.type,
        dashboardAction.fetchDashboardIndustryFilterIndustry3s.cancelled.type,
        dashboardAction.fetchDashboardContract.success.type,
        dashboardAction.fetchDashboardContract.failure.type,
        dashboardAction.fetchDashboardContract.cancelled.type,
        dashboardAction.createDsCompanyDoc.success.type,
        dashboardAction.createDsCompanyDoc.failure.type,
        dashboardAction.createDsCompanyDoc.cancelled.type,
        dashboardAction.downloadDsCompanyList.success.type,
        dashboardAction.downloadDsCompanyList.failure.type,
        dashboardAction.downloadDsCompanyList.cancelled.type,
        dashboardAction.downloadDsContractDoc.success.type,
        dashboardAction.downloadDsContractDoc.failure.type,
        dashboardAction.downloadDsContractDoc.cancelled.type,
        dashboardAction.downloadDsIndustryDoc.success.type,
        dashboardAction.downloadDsIndustryDoc.failure.type,
        dashboardAction.downloadDsIndustryDoc.cancelled.type,
        dashboardAction.fetchDashboardCompanyRanks.success.type,
        dashboardAction.fetchDashboardCompanyRanks.failure.type,
        dashboardAction.fetchDashboardCompanyRanks.cancelled.type,
        dashboardAction.fetchDashboardCompanyGradeCumulativeRates.success.type,
        dashboardAction.fetchDashboardCompanyGradeCumulativeRates.failure.type,
        dashboardAction.fetchDashboardCompanyGradeCumulativeRates.cancelled.type,
        dashboardAction.fetchDashboardCompanyMainPartners.success.type,
        dashboardAction.fetchDashboardCompanyMainPartners.failure.type,
        dashboardAction.fetchDashboardCompanyMainPartners.cancelled.type,
        dashboardAction.fetchDashboardCompanyNewPartners.success.type,
        dashboardAction.fetchDashboardCompanyNewPartners.failure.type,
        dashboardAction.fetchDashboardCompanyNewPartners.cancelled.type,
        dashboardAction.fetchDashboardCompanyExistPartners.success.type,
        dashboardAction.fetchDashboardCompanyExistPartners.failure.type,
        dashboardAction.fetchDashboardCompanyExistPartners.cancelled.type,
        dashboardAction.fetchDashboardCompanyGradeInfos.success.type,
        dashboardAction.fetchDashboardCompanyGradeInfos.failure.type,
        dashboardAction.fetchDashboardCompanyGradeInfos.cancelled.type,
      ].includes(action.type),
    ),
    map(() => dashboardAction.decreasePendingCount()),
  )

const failureEpic: Epic = (actions$: Observable<{ type: string; payload: Error }>) =>
  actions$.pipe(
    filter((action) =>
      [
        dashboardAction.createDashboardCompany.failure.type,
        dashboardAction.createDashboardIndustry.failure.type,
        dashboardAction.createDashboardContract.failure.type,
        dashboardAction.fetchDashboardCompanyDownload.failure.type,
        dashboardAction.fetchDashboardCompanyList.failure.type,
        dashboardAction.fetchDashboardCompanyGradeDatas.failure.type,
        dashboardAction.fetchDashboardCompanyUnspecifiedIndustry.failure.type,
        dashboardAction.fetchDashboardIndustry.failure.type,
        dashboardAction.fetchDashboardIndustryFilterCorporateTypes.failure.type,
        dashboardAction.fetchDashboardIndustryFilterIndustry1s.failure.type,
        dashboardAction.fetchDashboardIndustryFilterIndustry2s.failure.type,
        dashboardAction.fetchDashboardIndustryFilterIndustry3s.failure.type,
        dashboardAction.fetchDashboardContract.failure.type,
        dashboardAction.createDsCompanyDoc.failure.type,
        dashboardAction.downloadDsCompanyList.failure.type,
        dashboardAction.downloadDsContractDoc.failure.type,
        dashboardAction.downloadDsIndustryDoc.failure.type,
        dashboardAction.fetchDashboardCompanyRanks.failure.type,
        dashboardAction.fetchDashboardCompanyGradeCumulativeRates.failure.type,
        dashboardAction.fetchDashboardCompanyMainPartners.failure.type,
        dashboardAction.fetchDashboardCompanyNewPartners.failure.type,
        dashboardAction.fetchDashboardCompanyExistPartners.failure.type,
        dashboardAction.fetchDashboardCompanyGradeInfos.failure.type,
      ].includes(action.type),
    ),
    mergeMap(({ payload }) => httpErrorhandling(payload)),
  )

const fetchDashboardCompanyEpic: Epic = (actions$: Observable<Action>) =>
  actions$.pipe(
    filter(dashboardAction.fetchDashboardCompany.request.match),
    switchMap(({ payload }) =>
      dashboardService.fetchDashboardCompany(payload).pipe(
        mergeMap(({ response }) => [
          dashboardAction.fetchDashboardCompany.success(),
          dashboardAction.setDashboardCompany(response),
        ]),
        catchError((err) => of(dashboardAction.fetchDashboardCompany.failure(err))),
      ),
    ),
  )

const fetchDashboardCompanyListEpic: Epic = (actions$: Observable<Action>) =>
  actions$.pipe(
    filter(dashboardAction.fetchDashboardCompanyList.request.match),
    switchMap(({ payload }) =>
      dashboardService.fetchDashboardCompanyList(payload).pipe(
        mergeMap(({ response }) => [
          dashboardAction.fetchDashboardCompanyList.success(),
          dashboardAction.setDashboardCompanyList(response),
        ]),
        catchError((err) => of(dashboardAction.fetchDashboardCompanyList.failure(err))),
      ),
    ),
  )

const fetchDashboardCompanyGradeDatasEpic: Epic = (actions$: Observable<Action>) =>
  actions$.pipe(
    filter(dashboardAction.fetchDashboardCompanyGradeDatas.request.match),
    switchMap(({ payload }) =>
      dashboardService.fetchDashboardCompanyGradeDatas(payload).pipe(
        mergeMap(({ response }) => [
          dashboardAction.fetchDashboardCompanyGradeDatas.success(),
          dashboardAction.setDashboardCompanyGradeDatas(response),
        ]),
        catchError((err) => of(dashboardAction.fetchDashboardCompanyGradeDatas.failure(err))),
      ),
    ),
  )
const fetchDashboardCompanyUnspecifiedIndustryEpic: Epic = (actions$: Observable<Action>) =>
  actions$.pipe(
    filter(dashboardAction.fetchDashboardCompanyUnspecifiedIndustry.request.match),
    switchMap(({ payload }) =>
      dashboardService.fetchDashboardCompanyUnspecifiedIndustry(payload).pipe(
        mergeMap(({ response }) => [
          dashboardAction.fetchDashboardCompanyUnspecifiedIndustry.success(),
          dashboardAction.setDashboardCompanyUnspecifiedIndustry(response),
        ]),
        catchError((err) => of(dashboardAction.fetchDashboardCompanyUnspecifiedIndustry.failure(err))),
      ),
    ),
  )

const fetchDashboardIndustryEpic: Epic = (actions$: Observable<Action>) =>
  actions$.pipe(
    filter(dashboardAction.fetchDashboardIndustry.request.match),
    switchMap(({ payload }) =>
      dashboardService.fetchDashboardIndustry(payload).pipe(
        mergeMap(({ response }) => [
          dashboardAction.fetchDashboardIndustry.success(),
          dashboardAction.setDashboardIndustry(response),
        ]),
        catchError((err) => of(dashboardAction.fetchDashboardIndustry.failure(err))),
      ),
    ),
  )

const fetchDashboardContractEpic: Epic = (actions$: Observable<Action>) =>
  actions$.pipe(
    filter(dashboardAction.fetchDashboardContract.request.match),
    switchMap(({ payload }) =>
      dashboardService.fetchDashboardContract(payload).pipe(
        mergeMap(({ response }) => [
          dashboardAction.fetchDashboardContract.success(),
          dashboardAction.setDashboardContract(response),
        ]),
        catchError((err) => of(dashboardAction.fetchDashboardContract.failure(err))),
      ),
    ),
  )

const createDashboardCompanyEpic: Epic = (actions$: Observable<Action>) =>
  actions$.pipe(
    filter(dashboardAction.createDashboardCompany.request.match),
    switchMap(({ payload }) =>
      dashboardService.createDashboardCompany(payload).pipe(
        mergeMap(() => [dashboardAction.createDashboardCompany.success()]),
        catchError((err) => of(dashboardAction.createDashboardCompany.failure(err))),
      ),
    ),
  )

const createDashboardIndustryEpic: Epic = (actions$: Observable<Action>) =>
  actions$.pipe(
    filter(dashboardAction.createDashboardIndustry.request.match),
    switchMap(({ payload }) =>
      dashboardService.createDashboardIndustry(payload).pipe(
        mergeMap(() => [
          dashboardAction.createDashboardIndustry.success(),
          dashboardAction.fetchDashboardIndustryFilterCorporateTypes.request(),
        ]),
        catchError((err) => of(dashboardAction.createDashboardIndustry.failure(err))),
      ),
    ),
  )

const createDashboardContractEpic: Epic = (actions$: Observable<Action>) =>
  actions$.pipe(
    filter(dashboardAction.createDashboardContract.request.match),
    switchMap(({ payload }) =>
      dashboardService.createDashboardContract(payload).pipe(
        mergeMap(() => [
          dashboardAction.createDashboardContract.success(),
          dashboardAction.fetchDashboardContractFilterItems.request(),
        ]),
        catchError((err) => of(dashboardAction.createDashboardContract.failure(err))),
      ),
    ),
  )

const downloadDsCompanyListEpic: Epic = (actions$: Observable<Action>) =>
  actions$.pipe(
    filter(dashboardAction.downloadDsCompanyList.request.match),
    switchMap(({ payload }) =>
      dashboardService.downloadDsCompanyList(payload).pipe(
        mergeMap(() => [dashboardAction.downloadDsCompanyList.success()]),
        tap(() => {
          window.location.href = `/api/dashboard/downloaddscompanylistfile`
        }),
        catchError((err) => of(dashboardAction.downloadDsCompanyList.failure(err))),
      ),
    ),
  )

const createDashboadCompanyDocEpic: Epic = (actions$: Observable<Action>) =>
  actions$.pipe(
    filter(dashboardAction.createDsCompanyDoc.request.match),
    switchMap(({ payload }) =>
      dashboardService.downloadDsCompanyDoc(payload).pipe(
        mergeMap(() => [
          dashboardAction.createDsCompanyDoc.success(),
          dashboardAction.fetchDashboardCompanyDownload.request(),
        ]),
        catchError((err) => of(dashboardAction.createDsCompanyDoc.failure(err))),
      ),
    ),
  )

const fetchDashboardCompanyDownloadEpic: Epic = (actions$: Observable<Action>) =>
  actions$.pipe(
    filter(dashboardAction.fetchDashboardCompanyDownload.request.match),
    switchMap(() =>
      dashboardService.fetchDashboardCompanyDownload().pipe(
        mergeMap(({ response }) => [
          dashboardAction.fetchDashboardCompanyDownload.success(),
          dashboardAction.setDashboardCompanyDownload(response),
        ]),
        catchError((err) => of(dashboardAction.fetchDashboardCompanyDownload.failure(err))),
      ),
    ),
  )

const fetchDashboardCompanyDownloadBackgroundEpic: Epic = (actions$: Observable<Action>) =>
  actions$.pipe(
    filter(dashboardAction.fetchDashboardCompanyDownloadBackground.request.match),
    switchMap(() =>
      dashboardService.fetchDashboardCompanyDownload().pipe(
        mergeMap(({ response }) => [
          dashboardAction.fetchDashboardCompanyDownloadBackground.success(),
          dashboardAction.setDashboardCompanyDownload(response),
        ]),
        catchError((err) => of(dashboardAction.fetchDashboardCompanyDownloadBackground.failure(err))),
      ),
    ),
  )

const downloadDsContractDocEpic: Epic = (actions$: Observable<Action>) =>
  actions$.pipe(
    filter(dashboardAction.downloadDsContractDoc.request.match),
    switchMap(({ payload }) =>
      dashboardService.downloadDsContractDoc(payload).pipe(
        mergeMap(() => [dashboardAction.downloadDsContractDoc.success()]),
        tap(() => {
          window.location.href = `/api/dashboard/downloaddscontractdoc`
        }),
        catchError((err) => of(dashboardAction.downloadDsContractDoc.failure(err))),
      ),
    ),
  )

const downloadDsIndustryDocEpic: Epic = (actions$: Observable<Action>) =>
  actions$.pipe(
    filter(dashboardAction.downloadDsIndustryDoc.request.match),
    switchMap(({ payload }) =>
      dashboardService.downloadDsIndustryDoc(payload).pipe(
        mergeMap(() => [dashboardAction.downloadDsIndustryDoc.success()]),
        tap(() => {
          window.location.href = `/api/dashboard/downloaddsindustrydoc`
        }),
        catchError((err) => of(dashboardAction.downloadDsIndustryDoc.failure(err))),
      ),
    ),
  )

const fetchDashboardContractFilterItemsEpic: Epic = (actions$: Observable<Action>) =>
  actions$.pipe(
    filter(dashboardAction.fetchDashboardContractFilterItems.request.match),
    switchMap(() =>
      dashboardService.fetchDashboardContractFilterItems().pipe(
        mergeMap(({ response }) => [
          dashboardAction.fetchDashboardContractFilterItems.success(),
          dashboardAction.setDashboardContractFilterItems(response),
        ]),
        catchError((err) => of(dashboardAction.fetchDashboardContractFilterItems.failure(err))),
      ),
    ),
  )

const fetchDashboardIndustryFilterCorporateTypesEpic: Epic = (actions$: Observable<Action>) =>
  actions$.pipe(
    filter(dashboardAction.fetchDashboardIndustryFilterCorporateTypes.request.match),
    switchMap(() =>
      dashboardService.fetchDashboardIndustryFilterCorporateType().pipe(
        mergeMap(({ response }) => [
          dashboardAction.fetchDashboardIndustryFilterCorporateTypes.success(),
          dashboardAction.setDashboardIndustryFilterCorporateTypes(response),
        ]),
        catchError((err) => of(dashboardAction.fetchDashboardIndustryFilterCorporateTypes.failure(err))),
      ),
    ),
  )

const fetchDashboardIndustryFilterIndustry1sEpic: Epic = (actions$: Observable<Action>) =>
  actions$.pipe(
    filter(dashboardAction.fetchDashboardIndustryFilterIndustry1s.request.match),
    switchMap(({ payload }) =>
      dashboardService.fetchDashboardIndustryFilterIndustry1s(payload).pipe(
        mergeMap(({ response }) => [
          dashboardAction.fetchDashboardIndustryFilterIndustry1s.success(),
          dashboardAction.setDashboardIndustryFilterIndustry1s(response),
        ]),
        catchError((err) => of(dashboardAction.fetchDashboardIndustryFilterIndustry1s.failure(err))),
      ),
    ),
  )

const fetchDashboardIndustryFilterIndustry2sEpic: Epic = (actions$: Observable<Action>) =>
  actions$.pipe(
    filter(dashboardAction.fetchDashboardIndustryFilterIndustry2s.request.match),
    switchMap(({ payload }) =>
      dashboardService.fetchDashboardIndustryFilterIndustry2s(payload).pipe(
        mergeMap(({ response }) => [
          dashboardAction.fetchDashboardIndustryFilterIndustry2s.success(),
          dashboardAction.setDashboardIndustryFilterIndustry2s(response),
        ]),
        catchError((err) => of(dashboardAction.fetchDashboardIndustryFilterIndustry2s.failure(err))),
      ),
    ),
  )

const fetchDashboardIndustryFilterIndustry3sEpic: Epic = (actions$: Observable<Action>) =>
  actions$.pipe(
    filter(dashboardAction.fetchDashboardIndustryFilterIndustry3s.request.match),
    switchMap(({ payload }) =>
      dashboardService.fetchDashboardIndustryFilterIndustry3s(payload).pipe(
        mergeMap(({ response }) => [
          dashboardAction.fetchDashboardIndustryFilterIndustry3s.success(),
          dashboardAction.setDashboardIndustryFilterIndustry3s(response),
        ]),
        catchError((err) => of(dashboardAction.fetchDashboardIndustryFilterIndustry3s.failure(err))),
      ),
    ),
  )

const fetchDashboardCompanyFilterItemsEpic: Epic = (actions$: Observable<Action>) =>
  actions$.pipe(
    filter(dashboardAction.fetchDashboardCompanyFilterItems.request.match),
    switchMap(() =>
      dashboardService.fetchDashboardCompanyFilterItems().pipe(
        mergeMap(({ response }) => [
          dashboardAction.fetchDashboardCompanyFilterItems.success(),
          dashboardAction.setDashboardCompanyFilterItems(response),
        ]),
        catchError((err) => of(dashboardAction.fetchDashboardCompanyFilterItems.failure(err))),
      ),
    ),
  )

const fetchDashboardCompanyRanksEpic: Epic = (actions$: Observable<Action>) =>
  actions$.pipe(
    filter(dashboardAction.fetchDashboardCompanyRanks.request.match),
    switchMap(({ payload }) =>
      dashboardService.fetchDsCompanyRanks(payload).pipe(
        mergeMap(({ response }) => [
          dashboardAction.fetchDashboardCompanyRanks.success(),
          dashboardAction.setDashboardCompanyRanks(response),
        ]),
        catchError((err) => of(dashboardAction.fetchDashboardCompanyRanks.failure(err))),
      ),
    ),
  )

const fetchDashboardCompanyGradeCumulativeRatesEpic: Epic = (actions$: Observable<Action>) =>
  actions$.pipe(
    filter(dashboardAction.fetchDashboardCompanyGradeCumulativeRates.request.match),
    switchMap(() =>
      dashboardService.fetchDsCompanyGradeCumulativeRates().pipe(
        mergeMap(({ response }) => [
          dashboardAction.fetchDashboardCompanyGradeCumulativeRates.success(),
          dashboardAction.setDashboardCompanyGradeCumulativeRates(response),
        ]),
        catchError((err) => of(dashboardAction.fetchDashboardCompanyGradeCumulativeRates.failure(err))),
      ),
    ),
  )

const fetchDashboardCompanyMainPartnersEpic: Epic = (actions$: Observable<Action>) =>
  actions$.pipe(
    filter(dashboardAction.fetchDashboardCompanyMainPartners.request.match),
    switchMap(({ payload }) =>
      dashboardService.fetchDsCompanyPartners(payload).pipe(
        mergeMap(({ response }) => [
          dashboardAction.fetchDashboardCompanyMainPartners.success(),
          dashboardAction.setDashboardCompanyMainPartners(response),
        ]),
        catchError((err) => of(dashboardAction.fetchDashboardCompanyMainPartners.failure(err))),
      ),
    ),
  )

const fetchDashboardCompanyNewPartnersEpic: Epic = (actions$: Observable<Action>) =>
  actions$.pipe(
    filter(dashboardAction.fetchDashboardCompanyNewPartners.request.match),
    switchMap(({ payload }) =>
      dashboardService.fetchDsCompanyPartners(payload).pipe(
        mergeMap(({ response }) => [
          dashboardAction.fetchDashboardCompanyNewPartners.success(),
          dashboardAction.setDashboardCompanyNewPartners(response),
        ]),
        catchError((err) => of(dashboardAction.fetchDashboardCompanyNewPartners.failure(err))),
      ),
    ),
  )

const fetchDashboardCompanyExistPartnersEpic: Epic = (actions$: Observable<Action>) =>
  actions$.pipe(
    filter(dashboardAction.fetchDashboardCompanyExistPartners.request.match),
    switchMap(({ payload }) =>
      dashboardService.fetchDsCompanyPartners(payload).pipe(
        mergeMap(({ response }) => [
          dashboardAction.fetchDashboardCompanyExistPartners.success(),
          dashboardAction.setDashboardCompanyExistPartners(response),
        ]),
        catchError((err) => of(dashboardAction.fetchDashboardCompanyExistPartners.failure(err))),
      ),
    ),
  )

const fetchDashboardCompanyGradeInfosEpic: Epic = (actions$: Observable<Action>) =>
  actions$.pipe(
    filter(dashboardAction.fetchDashboardCompanyGradeInfos.request.match),
    switchMap(() =>
      dashboardService.fetchDsCompanyGradeInfos().pipe(
        mergeMap(({ response }) => [
          dashboardAction.fetchDashboardCompanyGradeInfos.success(),
          dashboardAction.setDashboardCompanyGradeInfos(response),
        ]),
        catchError((err) => of(dashboardAction.fetchDashboardCompanyGradeInfos.failure(err))),
      ),
    ),
  )

export const dashboardEpic = combineEpics(
  pendingStartEpic,
  pendingEndEpic,
  failureEpic,

  createDashboardCompanyEpic,
  createDashboardIndustryEpic,
  createDashboardContractEpic,

  fetchDashboardCompanyEpic,
  fetchDashboardCompanyListEpic,
  fetchDashboardCompanyGradeDatasEpic,
  fetchDashboardCompanyUnspecifiedIndustryEpic,

  fetchDashboardIndustryEpic,

  fetchDashboardContractEpic,

  downloadDsContractDocEpic,
  downloadDsIndustryDocEpic,
  createDashboadCompanyDocEpic,
  fetchDashboardCompanyDownloadEpic,
  fetchDashboardCompanyDownloadBackgroundEpic,
  downloadDsCompanyListEpic,

  fetchDashboardContractFilterItemsEpic,
  fetchDashboardIndustryFilterCorporateTypesEpic,
  fetchDashboardIndustryFilterIndustry1sEpic,
  fetchDashboardIndustryFilterIndustry2sEpic,
  fetchDashboardIndustryFilterIndustry3sEpic,
  fetchDashboardCompanyFilterItemsEpic,

  fetchDashboardCompanyRanksEpic,
  fetchDashboardCompanyMainPartnersEpic,
  fetchDashboardCompanyNewPartnersEpic,
  fetchDashboardCompanyExistPartnersEpic,

  fetchDashboardCompanyGradeCumulativeRatesEpic,
  fetchDashboardCompanyGradeInfosEpic,
)
