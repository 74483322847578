import { createAction, createReducer } from '@reduxjs/toolkit'
import {
  DashboardIndustryType,
  DashboardContractType,
  DashboardCompanyRecordsType,
  DashboardCompanyType,
  DashboardContractFilterItemsType,
  DashboardCompanyFilterItemsType,
  DsCompanyGradeDatasType,
  DashboardIndustryFilterCorporateTypesType,
  DashboardIndustryFilterIndustry1sType,
  DashboardIndustryFilterIndustry2sType,
  DashboardIndustryFilterIndustry3sType,
  DashboardCompanyUnspecifiedIndustry,
  DashboardCompanyDownloadType,
  DashboardCompanyRanksType,
  DashboardCompanyGradeCumulativeRatesType,
  DashboardCompanyDetailPartnerType,
  DashboardCompanyGradeInfosType,
  DashboardCompanyGradeInfoRecordsType,
  GradeInfoType,
} from 'src/type/dashboard.type'
import { createAsyncAction } from '../redux.type'
import {
  CreateDashboardCompanyParamsType,
  CreateDashboardContractParamsType,
  CreateDashboardIndustryParamsType,
  DownloadDsCompanyDocParamsType,
  DownloadDsCompanyListParamsType,
  DownloadDsContractDocParamsType,
  DownloadDsIndustryDocParamsType,
  FetchDashboardCompanyListParamsType,
  FetchDashboardCompanyParamsType,
  FetchDashboardCompanyGradeDatasParamsType,
  FetchDashboardCompanyUnspecifiedIndustryParamsType,
  FetchDashboardContractParamsType,
  FetchDashboardIndustryFilterIndustry1sParamsType,
  FetchDashboardIndustryFilterIndustry2sParamsType,
  FetchDashboardIndustryFilterIndustry3sParamsType,
  FetchDashboardIndustryParamsType,
  FetchDsCompanyRanksParamsType,
  FetchDsCompanyPartnersParamsType,
} from './dashboard.service'

type DashboardStateType = {
  pendingCount: number

  dsContractBranchCodeItems: string[] | null
  dsContractLookbackPeriodItems: string[] | null
  selectedContractBranchCode: string
  selectedLookbackPeriod: string

  dsIndustryCorporateTypeItems: string[] | null
  dsIndustryIndustry1Items: string[] | null
  dsIndustryIndustry2Items: string[] | null
  dsIndustryIndustry3Items: string[] | null
  selectedCorporateType: string
  selectedIndustry1: string
  selectedIndustry2: string
  selectedIndustry3: string

  dsCompanyBranchCodeItems: string[] | null
  selectedCompanyBranchCode: string

  dashboardCompanyGradeDatas: DsCompanyGradeDatasType | null
  dashboardCompanyPage: number
  dashboardCompanyRecords: DashboardCompanyRecordsType | null
  dashboardCompanyIds: number[] | null

  dashboardCompanyGradeInfoRecords: DashboardCompanyGradeInfoRecordsType | null
  dashboardCompanyRanks: DashboardCompanyRanksType | null
  dashboardCompanyGradeCumuldativeRates: DashboardCompanyGradeCumulativeRatesType | null

  dashboardCompanyMainPartnerDatas: DashboardCompanyDetailPartnerType[] | null
  dashboardCompanyNewPartnerDatas: DashboardCompanyDetailPartnerType[] | null
  dashboardCompanyExistPartnerDatas: DashboardCompanyDetailPartnerType[] | null

  dashboardCompanyDownloadStatus: DashboardCompanyDownloadType[]

  unspecifiedIndustryCompanyCount: number
  totalCompanyCount: number

  dashboardContract: DashboardContractType[] | null
  dashboardIndustry: DashboardIndustryType | null

  contractInderstyColor: { [name: string]: string } | null
}

const increasePendingCount = createAction('[dashboard] increase async action pending count')
const decreasePendingCount = createAction('[dashboard] dencrease async action pending count')

const createDashboardCompany = createAsyncAction<CreateDashboardCompanyParamsType>(
  '[dashboard] create comopany dashboard',
)
const createDashboardContract = createAsyncAction<CreateDashboardContractParamsType>(
  '[dashboard] create contract dashboard',
)
const createDashboardIndustry = createAsyncAction<CreateDashboardIndustryParamsType>(
  '[dashboard] create industry dashboard',
)

const fetchDashboardCompanyDownload = createAsyncAction('[dashboard] fetch dashboard company download')
const fetchDashboardCompanyDownloadBackground = createAsyncAction(
  '[dashboard] fetch dashboard company download in background',
)
const setDashboardCompanyDownload = createAction<DashboardCompanyDownloadType[]>(
  '[dashboard] set dashboard company download',
)

const fetchDashboardContractFilterItems = createAsyncAction('[dashboard] fetch contract dashboard filter items')
const setDashboardContractFilterItems = createAction<DashboardContractFilterItemsType>(
  '[dashboard] set contract dashboard filter items',
)
const fetchDashboardIndustryFilterCorporateTypes = createAsyncAction(
  '[dashboard] fetch industry dashboard filter corporateTypes',
)
const setDashboardIndustryFilterCorporateTypes = createAction<DashboardIndustryFilterCorporateTypesType>(
  '[dashboard] set industry dashboard filter corporateTypes',
)
const fetchDashboardIndustryFilterIndustry1s = createAsyncAction<FetchDashboardIndustryFilterIndustry1sParamsType>(
  '[dashboard] fetch industry dashboard filter industry1s',
)
const setDashboardIndustryFilterIndustry1s = createAction<DashboardIndustryFilterIndustry1sType>(
  '[dashboard] set industry dashboard filter industry1s',
)
const fetchDashboardIndustryFilterIndustry2s = createAsyncAction<FetchDashboardIndustryFilterIndustry2sParamsType>(
  '[dashboard] fetch industry dashboard filter industry2s',
)
const setDashboardIndustryFilterIndustry2s = createAction<DashboardIndustryFilterIndustry2sType>(
  '[dashboard] set industry dashboard filter industry2s',
)
const fetchDashboardIndustryFilterIndustry3s = createAsyncAction<FetchDashboardIndustryFilterIndustry3sParamsType>(
  '[dashboard] fetch industry dashboard filter industry3s',
)
const setDashboardIndustryFilterIndustry3s = createAction<DashboardIndustryFilterIndustry3sType>(
  '[dashboard] set industry dashboard filter industry3s',
)
const fetchDashboardCompanyFilterItems = createAsyncAction('[dashboard] fetch company dashboard filter items')
const setDashboardCompanyFilterItems = createAction<DashboardCompanyFilterItemsType>(
  '[dashboard] set company dashboard filter items',
)

const fetchDashboardCompany = createAsyncAction<FetchDashboardCompanyParamsType>('[dashboard] fetch comopany dashboard')
const setDashboardCompany = createAction<DashboardCompanyType[]>('[dashboard] set comopany dashboard')

const fetchDashboardCompanyList = createAsyncAction<FetchDashboardCompanyListParamsType>(
  '[dashboard] fetch comopany dashboard list',
)
const setDashboardCompanyList = createAction<DashboardCompanyType[]>('[dashboard] set comopany dashboard list')
const setDashboardCompanyListPage = createAction<number>('[dashboard] set comopany dashboard page number')

const fetchDashboardCompanyGradeDatas = createAsyncAction<FetchDashboardCompanyGradeDatasParamsType>(
  '[dashboard] fetch comopany dashboard grade datas',
)
const setDashboardCompanyGradeDatas = createAction<DsCompanyGradeDatasType>(
  '[dashboard] set comopany dashboard grade datas',
)

const fetchDashboardCompanyUnspecifiedIndustry = createAsyncAction<FetchDashboardCompanyUnspecifiedIndustryParamsType>(
  '[dashboard] fetch comopany dashboard unspecified industry',
)
const setDashboardCompanyUnspecifiedIndustry = createAction<DashboardCompanyUnspecifiedIndustry>(
  '[dashboard] set comopany dashboard unspecified industry',
)

const fetchDashboardContract = createAsyncAction<FetchDashboardContractParamsType>(
  '[dashboard] fetch contract dashboard',
)
const setDashboardContract = createAction<DashboardContractType[]>('[dashboard] set contract dashboard')

const fetchDashboardIndustry = createAsyncAction<FetchDashboardIndustryParamsType>(
  '[dashboard] fetch industry dashboard',
)
const setDashboardIndustry = createAction<DashboardIndustryType>('[dashboard] set industry dashboard')

const downloadDsContractDoc = createAsyncAction<DownloadDsContractDocParamsType>(
  '[dashboard] create and download dashboard contract doc',
)
const downloadDsIndustryDoc = createAsyncAction<DownloadDsIndustryDocParamsType>(
  '[dashboard] create and download dashboard industry doc',
)
const createDsCompanyDoc = createAsyncAction<DownloadDsCompanyDocParamsType>('[dashboard] create dashboard company doc')
const downloadDsCompanyList = createAsyncAction<DownloadDsCompanyListParamsType>(
  '[dashboard] create and download dashboard company list csv',
)

const fetchDashboardCompanyRanks = createAsyncAction<FetchDsCompanyRanksParamsType>('[dashboard] fetch company ranks')
const setDashboardCompanyRanks = createAction<DashboardCompanyRanksType | null>('[dashboard] set company ranks')

const fetchDashboardCompanyMainPartners = createAsyncAction<FetchDsCompanyPartnersParamsType>(
  '[dashboard] fetch company partners',
)
const setDashboardCompanyMainPartners = createAction<DashboardCompanyDetailPartnerType[] | null>(
  '[dashboard] set company partners',
)

const fetchDashboardCompanyNewPartners = createAsyncAction<FetchDsCompanyPartnersParamsType>(
  '[dashboard] fetch company new partners',
)
const setDashboardCompanyNewPartners = createAction<DashboardCompanyDetailPartnerType[] | null>(
  '[dashboard] set company new partners',
)

const fetchDashboardCompanyExistPartners = createAsyncAction<FetchDsCompanyPartnersParamsType>(
  '[dashboard] fetch company exist partners',
)
const setDashboardCompanyExistPartners = createAction<DashboardCompanyDetailPartnerType[] | null>(
  '[dashboard] set company exist partners',
)

const fetchDashboardCompanyGradeCumulativeRates = createAsyncAction('[dashboard] fetch company grade cumulative rates')
const setDashboardCompanyGradeCumulativeRates = createAction<DashboardCompanyGradeCumulativeRatesType | null>(
  '[dashboard] set company grade cumulative rates',
)

const fetchDashboardCompanyGradeInfos = createAsyncAction('[dashboard] fetch company grade infos')
const setDashboardCompanyGradeInfos = createAction<DashboardCompanyGradeInfosType | null>(
  '[dashboard] set company grade infos',
)

const setSelectedContractBranchCode = createAction<string>('[dashboard] set selected contract branch code')
const setSelectedLookbackPeriod = createAction<string>('[dashboard] set selected lookback period')
const setSelectedCorporateType = createAction<string>('[dashboard] set selected corporate type')
const setSelectedIndustry1 = createAction<string>('[dashboard] set selected industry1')
const setSelectedIndustry2 = createAction<string>('[dashboard] set selected industry2')
const setSelectedIndustry3 = createAction<string>('[dashboard] set selected industry3')
const setSelectedCompanyBranchCode = createAction<string>('[dashboard] set selected company branch code')

export const dashboardAction = {
  increasePendingCount,
  decreasePendingCount,

  createDashboardCompany,
  createDashboardContract,
  createDashboardIndustry,

  fetchDashboardCompanyDownload,
  fetchDashboardCompanyDownloadBackground,
  setDashboardCompanyDownload,

  fetchDashboardContractFilterItems,
  setDashboardContractFilterItems,

  fetchDashboardIndustryFilterCorporateTypes,
  setDashboardIndustryFilterCorporateTypes,

  fetchDashboardIndustryFilterIndustry1s,
  setDashboardIndustryFilterIndustry1s,

  fetchDashboardIndustryFilterIndustry2s,
  setDashboardIndustryFilterIndustry2s,

  fetchDashboardIndustryFilterIndustry3s,
  setDashboardIndustryFilterIndustry3s,

  fetchDashboardCompanyFilterItems,
  setDashboardCompanyFilterItems,

  fetchDashboardCompany,
  setDashboardCompany,

  fetchDashboardCompanyList,
  setDashboardCompanyList,
  setDashboardCompanyListPage,

  fetchDashboardCompanyGradeDatas,
  setDashboardCompanyGradeDatas,

  fetchDashboardCompanyGradeCumulativeRates,
  setDashboardCompanyGradeCumulativeRates,

  fetchDashboardCompanyGradeInfos,
  setDashboardCompanyGradeInfos,

  fetchDashboardCompanyMainPartners,
  setDashboardCompanyMainPartners,

  fetchDashboardCompanyNewPartners,
  setDashboardCompanyNewPartners,

  fetchDashboardCompanyExistPartners,
  setDashboardCompanyExistPartners,

  fetchDashboardCompanyUnspecifiedIndustry,
  setDashboardCompanyUnspecifiedIndustry,

  fetchDashboardContract,
  setDashboardContract,

  fetchDashboardIndustry,
  setDashboardIndustry,

  downloadDsContractDoc,
  downloadDsIndustryDoc,
  createDsCompanyDoc,
  downloadDsCompanyList,

  fetchDashboardCompanyRanks,
  setDashboardCompanyRanks,

  setSelectedContractBranchCode,
  setSelectedLookbackPeriod,
  setSelectedCorporateType,
  setSelectedIndustry1,
  setSelectedIndustry2,
  setSelectedIndustry3,
  setSelectedCompanyBranchCode,
}

const initDashboardState: DashboardStateType = {
  pendingCount: 0,

  dsContractBranchCodeItems: null,
  dsContractLookbackPeriodItems: null,
  selectedContractBranchCode: '',
  selectedLookbackPeriod: '',

  dsIndustryCorporateTypeItems: null,
  dsIndustryIndustry1Items: null,
  dsIndustryIndustry2Items: null,
  dsIndustryIndustry3Items: null,
  selectedCorporateType: '',
  selectedIndustry1: '',
  selectedIndustry2: '',
  selectedIndustry3: '',

  dsCompanyBranchCodeItems: null,
  selectedCompanyBranchCode: '',

  dashboardCompanyGradeDatas: null,
  dashboardCompanyPage: 1,
  dashboardCompanyRecords: null,
  dashboardCompanyIds: null,

  dashboardCompanyGradeInfoRecords: null,
  dashboardCompanyRanks: null,
  dashboardCompanyGradeCumuldativeRates: null,

  dashboardCompanyMainPartnerDatas: null,
  dashboardCompanyNewPartnerDatas: null,
  dashboardCompanyExistPartnerDatas: null,

  dashboardCompanyDownloadStatus: [],

  unspecifiedIndustryCompanyCount: 0,
  totalCompanyCount: 0,

  dashboardContract: null,
  dashboardIndustry: null,

  contractInderstyColor: null,
}

export const dashboardReducer = createReducer(initDashboardState, (builder) =>
  builder
    .addCase(increasePendingCount, (state) => {
      state.pendingCount++
    })
    .addCase(decreasePendingCount, (state) => {
      state.pendingCount--
    })
    .addCase(setDashboardCompany, (state, { payload }) => {
      state.dashboardCompanyRecords = payload.reduce<DashboardCompanyRecordsType>((res, dscompany) => {
        res[dscompany.id] = dscompany
        return res
      }, {})

      state.dashboardCompanyIds = payload.map((data) => data.id)
    })
    .addCase(setDashboardCompanyList, (state, { payload }) => {
      if (state.dashboardCompanyPage === 1) {
        state.dashboardCompanyRecords = payload.reduce<DashboardCompanyRecordsType>((res, dscompany) => {
          res[dscompany.id] = dscompany
          return res
        }, {})

        state.dashboardCompanyIds = payload.map((data) => data.id)
      } else {
        state.dashboardCompanyRecords = payload.reduce<DashboardCompanyRecordsType>((res, dscompany) => {
          res[dscompany.id] = dscompany
          return res
        }, state.dashboardCompanyRecords || {})

        state.dashboardCompanyIds?.push(...payload.map((data) => data.id))
      }
    })
    .addCase(setDashboardCompanyGradeDatas, (state, { payload }) => {
      state.dashboardCompanyGradeDatas = payload
    })
    .addCase(setDashboardCompanyListPage, (state, { payload }) => {
      state.dashboardCompanyPage = payload
    })
    .addCase(setDashboardCompanyUnspecifiedIndustry, (state, { payload }) => {
      state.unspecifiedIndustryCompanyCount = payload.unspecifiedIndustryCompanyCount
      state.totalCompanyCount = payload.totalCompanyCount
    })
    .addCase(setDashboardContract, (state, { payload }) => {
      state.dashboardContract = payload
    })
    .addCase(setDashboardIndustry, (state, { payload }) => {
      state.dashboardIndustry = payload
    })
    .addCase(setDashboardContractFilterItems, (state, { payload }) => {
      state.dsContractBranchCodeItems = payload.branchCodeItems
      state.dsContractLookbackPeriodItems = payload.lookbackPeriodItems.sort()
      if (state.selectedContractBranchCode === '') {
        state.selectedContractBranchCode =
          payload.branchCodeItems.find((bc) => bc === 'allBranches') || payload.branchCodeItems[0] || ''
      }
      if (state.selectedLookbackPeriod === '') {
        state.selectedLookbackPeriod = payload.lookbackPeriodItems[0] || ''
      }
    })
    .addCase(setDashboardIndustryFilterCorporateTypes, (state, { payload }) => {
      state.dsIndustryCorporateTypeItems = payload.corporateTypeItems

      if (state.selectedCorporateType === '') {
        state.selectedCorporateType = payload.corporateTypeItems[0] || ''
      }

      state.selectedIndustry1 = ''
      state.selectedIndustry2 = ''
      state.selectedIndustry3 = ''
    })
    .addCase(setDashboardIndustryFilterIndustry1s, (state, { payload }) => {
      state.dsIndustryIndustry1Items = payload.industry1Items
      if (state.selectedIndustry1 !== '') {
        state.selectedIndustry1 = ''
        state.selectedIndustry2 = ''
        state.selectedIndustry3 = ''
      }
    })
    .addCase(setDashboardIndustryFilterIndustry2s, (state, { payload }) => {
      state.dsIndustryIndustry2Items = payload.industry2Items
      if (state.selectedIndustry2 !== '') {
        state.selectedIndustry2 = ''
        state.selectedIndustry3 = ''
      }
    })
    .addCase(setDashboardIndustryFilterIndustry3s, (state, { payload }) => {
      state.dsIndustryIndustry3Items = payload.industry3Items
      if (state.selectedIndustry3 !== '') {
        state.selectedIndustry3 = ''
      }
    })
    .addCase(setDashboardCompanyFilterItems, (state, { payload }) => {
      state.dsCompanyBranchCodeItems = payload.branchCodeItems
      if (state.selectedCompanyBranchCode === '') {
        state.selectedCompanyBranchCode = 'allBranches'
      }
    })
    .addCase(setSelectedContractBranchCode, (state, { payload }) => {
      state.selectedContractBranchCode = payload
    })
    .addCase(setSelectedLookbackPeriod, (state, { payload }) => {
      state.selectedLookbackPeriod = payload
    })
    .addCase(setSelectedCorporateType, (state, { payload }) => {
      state.selectedCorporateType = payload
    })
    .addCase(setSelectedIndustry1, (state, { payload }) => {
      state.selectedIndustry1 = payload
    })
    .addCase(setSelectedIndustry2, (state, { payload }) => {
      state.selectedIndustry2 = payload
    })
    .addCase(setSelectedIndustry3, (state, { payload }) => {
      state.selectedIndustry3 = payload
    })
    .addCase(setSelectedCompanyBranchCode, (state, { payload }) => {
      state.selectedCompanyBranchCode = payload
    })
    .addCase(setDashboardCompanyDownload, (state, { payload }) => {
      state.dashboardCompanyDownloadStatus = payload
    })
    .addCase(setDashboardCompanyRanks, (state, { payload }) => {
      state.dashboardCompanyRanks = payload
    })
    .addCase(setDashboardCompanyGradeCumulativeRates, (state, { payload }) => {
      state.dashboardCompanyGradeCumuldativeRates = payload
    })
    .addCase(setDashboardCompanyMainPartners, (state, { payload }) => {
      state.dashboardCompanyMainPartnerDatas = payload
    })
    .addCase(setDashboardCompanyNewPartners, (state, { payload }) => {
      state.dashboardCompanyNewPartnerDatas = payload
    })
    .addCase(setDashboardCompanyExistPartners, (state, { payload }) => {
      state.dashboardCompanyExistPartnerDatas = payload
    })
    .addCase(setDashboardCompanyGradeInfos, (state, { payload }) => {
      if (!payload) {
        state.dashboardCompanyGradeInfoRecords = null
      } else {
        state.dashboardCompanyGradeInfoRecords = {
          longTermGrades: payload.longTermSolutionGrades.reduce<{ [grade: string]: GradeInfoType }>((res, item) => {
            res[item.grade] = item
            return res
          }, {}),
          shortTermGrades: payload.shortTermSolutionGrades.reduce<{ [grade: string]: GradeInfoType }>((res, item) => {
            res[item.grade] = item
            return res
          }, {}),
        }
      }
    }),
)
