import { ProjectTypesEnum } from 'src/type/project.type'

export const blueprintIntl: Record<
  ProjectTypesEnum,
  { [blueprintKey: string]: { name: string; description: string; priPlaceholder?: string; subPlaceholder?: string } }
> = {
  [ProjectTypesEnum.CHURN_PREVENTION]: {
    key: {
      name: `Key`,
      description: `顧客ID等のユニーク値。\n顧客データとトランザクションデータを統合するためのキー値となる`,
    },
    secondaryKey: {
      name: `Key変数（複数ある場合）`,
      description: `顧客ID等のユニーク値。\n顧客データとトランザクションデータを統合するためのキー値となる`,
    },
    transactionDate: {
      name: `取引日付`,
      description: `取引が行われた日付`,
    },
    transactionAmount: {
      name: `取引金額`,
      description: `取引金額\n金額単位も設定してください。（例：千円、百万円単位等）`,
    },
    gender: {
      name: `性別`,
      description: `顧客の性別を表すコード`,
    },
    birthday: {
      name: `生年月日`,
      description: `生まれた年月日。`,
    },
    industry: {
      name: `職種`,
      description: `公務員、会社員等の職種を表す`,
    },
    subscriptionDate: {
      name: `商品加入日`,
      description: `顧客が商品に加入した日`,
    },
    salaryCode: {
      name: `取引コード`,
      description: `取引コードのうち、給与振り込みに関連する取引コードを選択\nメインバンクとして利用しているかどうかを判別するための情報として利用`,
      subPlaceholder: `給与振込コードを選択してください。`,
    },
    depositCode: {
      name: `入金コード`,
      description: `入金を表すコード`,
    },
    withdrawalCode: {
      name: `出金コード`,
      description: `出金を表すコード`,
    },
    balance: {
      name: `残高`,
      description: `月末時点の残高\n金額単位も設定してください。（例：千円、百万円単位等）`,
    },
  },
  [ProjectTypesEnum.CROSS_SELLING]: {
    key: {
      name: `Key`,
      description: `顧客ID等のユニーク値。\n顧客データとトランザクションデータを統合するためのキー値となる`,
    },
    secondaryKey: {
      name: `Key変数（複数ある場合）`,
      description: `顧客ID等のユニーク値。\n顧客データとトランザクションデータを統合するためのキー値となる`,
    },
    transactionDate: {
      name: `取引日付`,
      description: `取引が行われた日付`,
    },
    targetProductSubscriptionDate: {
      name: `追加販売商品加入日`,
      description: `追加商品に加入した日付\n（例：預金のお客様に対し、\n    住宅ローンをクロスセルさせたい場合には、住宅ローンに加入した日付を記載）`,
    },
    transactionAmount: {
      name: `取引金額`,
      description: `取引金額\n金額単位も設定してください。（例：千円、百万円単位等）`,
    },
    standardProductSubscriptionDate: {
      name: `既存商品加入日`,
      description: `既存商品に加入した日付\n（例：預金のお客様に対し、\n    住宅ローンをクロスセルさせたい場合には、預金に加入した日付を記載）`,
    },
    gender: {
      name: `性別`,
      description: `顧客の性別を表すコード`,
    },
    birthday: {
      name: `生年月日`,
      description: `生まれた年月日。`,
    },
    industry: {
      name: `職種`,
      description: `公務員、会社員等の職種を表す`,
    },
    depositCode: {
      name: `入金コード`,
      description: `入金を表すコード`,
    },
    withdrawalCode: {
      name: `出金コード`,
      description: `出金を表すコード`,
    },
    balance: {
      name: `残高`,
      description: `月末時点の残高\n金額単位も設定してください。（例：千円、百万円単位等）`,
    },
    salaryCode: {
      name: `取引コード`,
      description: `取引コードのうち、給与振り込みに関連する取引コードを選択\nメインバンクとして利用しているかどうかを判別するための情報として利用`,
      subPlaceholder: `給与振込コードを選択してください。`,
    },
  },
  [ProjectTypesEnum.UP_SELLING]: {
    key: {
      name: `Key`,
      description: `顧客ID等のユニーク値。\n顧客データとトランザクションデータを統合するためのキー値となる`,
    },
    secondaryKey: {
      name: `Key変数（複数ある場合）`,
      description: `顧客ID等のユニーク値。\n顧客データとトランザクションデータを統合するためのキー値となる`,
    },
    transactionDate: {
      name: `取引日付`,
      description: `取引が行われた日付`,
    },
    limitAmount: {
      name: `限度額`,
      description: `当該サービスで設定している限度額（あるいは極度額）`,
    },
    balance: {
      name: `残高`,
      description: `"当該サービスの月末基準の残高\n金額単位を設定します。（例：千円、百万円単位等）"`,
    },
    transactionAmount: {
      name: `取引金額`,
      description: `取引金額\n金額単位を設定します。（例：千円、百万円単位等）`,
    },
    gender: {
      name: `性別`,
      description: `顧客の性別を表すコード`,
    },
    birthday: {
      name: `生年月日`,
      description: `生まれた年月日。`,
    },
    industry: {
      name: `職種`,
      description: `公務員、会社員等の職種を表す`,
    },
    subscriptionDate: {
      name: `商品加入日`,
      description: `顧客が商品に加入した日`,
    },
    salaryCode: {
      name: `取引コード`,
      description: `取引コードのうち、給与振り込みに関連する取引コードを選択\nメインバンクとして利用しているかどうかを判別するための情報として利用`,
      subPlaceholder: `給与振込コードを選択してください。`,
    },
    depositCode: {
      name: `入金コード`,
      description: `入金を表すコード`,
    },
    withdrawalCode: {
      name: `出金コード`,
      description: `出金を表すコード`,
    },
  },
  [ProjectTypesEnum.CORP_LOAN_DEMAND]: {
    key: {
      name: `Key`,
      description: `顧客ID等のユニーク値。\n顧客データとトランザクションデータを統合するためのキー値となる`,
    },
    secondaryKey: {
      name: `Key変数（複数ある場合）`,
      description: `顧客ID等のユニーク値。\n顧客データとトランザクションデータを統合するためのキー値となる`,
    },
    transactionDate: {
      name: `取引日付`,
      description: `取引が行われた日付`,
    },
    loanDate: {
      name: `貸出実行日`,
      description: `貸出実行日`,
    },
    transactionAmount: {
      name: `取引金額`,
      description: `取引金額\n金額単位も設定してください。（例：千円、百万円単位等）`,
    },
    balance: {
      name: `残高`,
      description: `月末時点の残高\n金額単位も設定してください。（例：千円、百万円単位等）`,
    },
    depositCode: {
      name: `入金コード`,
      description: `入金を表すコード`,
    },
    withdrawalCode: {
      name: `出金コード`,
      description: `出金を表すコード`,
    },
    salaryCode: {
      name: `給与振込コード`,
      description: `取引コードのうち、給与振り込みに関連する取引コードを選択\nメインバンクとして利用しているかどうかを判別するための情報として利用`,
      subPlaceholder: `給与振込コードを選択してください。`,
    },
    paymentCode: {
      name: `返済コード`,
      description: `返済取引に関するコード`,
    },
    isListed: {
      name: `株式公開`,
      description: `企業が公開されているかどうかを表す`,
    },
    industry: {
      name: `業種`,
      description: `製造業や小売業等の業種を表すコード`,
    },
    postCode1: {
      name: `住所(都道府県)`,
      description: `都道府県等の大分類（例：東京都）`,
    },
    postCode2: {
      name: `住所(市区郡町村)`,
      description: `市区郡町村等の中分類（例：千代田区）`,
    },
    postCode3: {
      name: `住所(町村以下)`,
      description: `それ以下の小分類（例：霞が関◯丁目◯番地）`,
    },
    foundationYear: {
      name: `設立日`,
      description: `企業の設立日`,
    },
    numberOfEmployees: {
      name: `従業員数`,
      description: `企業の従業員数`,
    },
    ratingScore: {
      name: `直近格付`,
      description: `企業の直近格付`,
    },
    creditBureauScore: {
      name: `外部信用機関評点`,
      description: `外部信用機関が出している評点`,
    },
    officeNumber: {
      name: `事業所数`,
      description: `企業が有する事務所の個数`,
    },
  },
  [ProjectTypesEnum.CORP_SALES_INSIGHTS]: {
    key: {
      name: `Key`,
      description: `顧客ID等のユニーク値。\n顧客データとトランザクションデータを統合するためのキー値となる`,
    },
    secondaryKey: {
      name: `Key変数（複数ある場合）`,
      description: `顧客ID等のユニーク値。\n顧客データとトランザクションデータを統合するためのキー値となる`,
    },
    transactionDate: {
      name: `取引日付`,
      description: `取引が行われた日付`,
    },
    loanDate1: {
      name: `貸出実行日1`,
      description: `短期が実行された日付`,
    },
    loanDate2: {
      name: `貸出実行日2`,
      description: `長期が実行された日付`,
    },
    loanAmount1: {
      name: `貸出金額1`,
      description: `短期の実行金額`,
    },
    loanAmount2: {
      name: `貸出金額2`,
      description: `長期の実行金額`,
    },
    transactionAmount: {
      name: `取引金額`,
      description: `取引金額\n金額単位も設定してください。（例：千円、百万円単位等）`,
    },
    balance: {
      name: `残高`,
      description: `月末時点の残高\n金額単位も設定してください。（例：千円、百万円単位等）`,
    },
    depositCode: {
      name: `入金コード`,
      description: `入金を表すコード`,
    },
    withdrawalCode: {
      name: `出金コード`,
      description: `出金を表すコード`,
    },
    salaryCode: {
      name: `給与振込コード`,
      description: `取引コードのうち、給与振り込みに関連する取引コードを選択\nメインバンクとして利用しているかどうかを判別するための情報として利用`,
      subPlaceholder: `給与振込コードを選択してください。`,
    },
    paymentCode: {
      name: `返済コード`,
      description: `返済コード`,
    },
    ceoName: {
      name: `代表者氏名`,
      description: `代表取締役等、法人代表者の氏名`,
    },
    ceoBirthday: {
      name: `代表者生年月日`,
      description: `代表取締役等、法人代表者の生年月日`,
    },
    depositSubscriptionDate: {
      name: `預金口座開設日`,
      description: `当該法人が預金口座を開設した日`,
    },
    branchCode: {
      name: `支店コード`,
      description: `支店毎に割り振られたコード`,
    },
    corporateType: {
      name: `法人格`,
      description: `法人（会社）か個人事業主かを区別するコード`,
    },
    companyName: {
      name: `会社名`,
      description: `法人の場合は会社名、個人事業主の場合は当該個人名`,
    },
    industry1: {
      name: `業種（大分類）`,
      description: `大きな単位で区分された業種`,
    },
    industry2: {
      name: `業種（中分類）`,
      description: `中単位で区分された業種`,
    },
    industry3: {
      name: `業種（小分類）`,
      description: `小単位で区分された業種`,
    },
    postCode1: {
      name: `住所（都道府県）`,
      description: `都道府県等、大きな単位での住所区分`,
    },
    postCode2: {
      name: `住所（市区郡町村）`,
      description: `市区郡町村等、中単位での住所区分`,
    },
    postCode3: {
      name: `住所（町村以下）`,
      description: `町村以下の小単位での住所区分`,
    },
    foundationYear: {
      name: `設立日`,
      description: `企業の設立日`,
    },
    isListed: {
      name: `株式公開`,
      description: `企業が公開されているかどうかを表す`,
    },
    numberOfEmployees: {
      name: `従業員数`,
      description: `企業の従業員数`,
    },
    ratingScore: {
      name: `直近格付`,
      description: `企業の直近格付`,
    },
    creditBureauScore: {
      name: `外部信用機関評点`,
      description: `外部信用機関が出している評点`,
    },
    officeNumber: {
      name: `事業所数`,
      description: `企業が有する事務所の個数`,
    },
    corporateTaxCode: {
      name: `法人税支払関連コード`,
      description: `税金支払取引に紐づくコード番号`,
    },
    insuranceFeeCode: {
      name: `社会保険料支払い関連コード`,
      description: `社会保険料の取引に紐づくコード番号`,
    },
    spouse: {
      name: `配偶者有無`,
      description: `配偶者が存在するかどうかを表すフラグ情報`,
    },
    descendant: {
      name: `子供の有無`,
      description: `子女が存在するかどうかを表すフラグ情報`,
    },
    partnerName: {
      name: `送金人名`,
      description: `送金をした人物または会社の名前`,
    },
  },
}
