import { AlertLogType, TASK_ALERT_STATUS } from 'src/type/notification.type'
import { ProjectResultType, ProjectTypesEnum } from 'src/type/project.type'
import { TaskProgressType, TASKS } from 'src/type/task.type'

function datasetCreateTaskProgress(taskProgress?: TaskProgressType) {
  if (taskProgress) {
    if (taskProgress.name === TASKS.EXTRACT_DATA) {
      return 0.1
    } else if (taskProgress.name === TASKS.VALIDATE_DATA) {
      return taskProgress.progress.current * 0.3 || 0.1
    } else if (taskProgress.name === TASKS.DESCRIBE_DATA) {
      return 30 + taskProgress.progress.current * 0.7
    }
  }
  return 0
}

function trainingProjectTaskProgress(projectType: ProjectTypesEnum, resultLength = 0, task?: TaskProgressType) {
  // FIXME: multiple progress
  if (task) {
    const resultProgress = projectType === ProjectTypesEnum.CORP_SALES_INSIGHTS ? resultLength * 45 : 0
    const progressRatio = projectType === ProjectTypesEnum.CORP_SALES_INSIGHTS ? 0.5 : 1

    if (task.name === TASKS.PREPROCESS_DATA) {
      return resultProgress + 0.1 * progressRatio
    } else if (task.name === TASKS.VALIDATE_DATA) {
      return resultProgress + (task.progress.current * 0.1 || 0.1) * progressRatio
    } else if (task.name === TASKS.DESCRIBE_DATA || task.name === TASKS.SORT_DATA) {
      return resultProgress + (10 + task.progress.current * 0.1) * progressRatio
    } else if (task.name === TASKS.CREATE_EXPERIMENT_PATH) {
      return resultProgress + (20 + task.progress.current * 0.1) * progressRatio
    } else if (task.name === TASKS.TRAIN) {
      return resultProgress + (30 + task.progress.current * 0.5) * progressRatio
    } else if (task.name === TASKS.TEST) {
      return resultProgress + (80 + task.progress.current * 0.1) * progressRatio
    } else if (task.name === TASKS.CREATE_SEGMENTS) {
      return 90 + task.progress.current * 0.1
    } else if (task.name === TASKS.PREPARE_DASHBOARD) {
      return 90 + task.progress.current * 0.1
    }
  }
  return 0
}

function resultKPIOptionalvalue(projectResult: ProjectResultType) {
  const res: Record<string, number> = {}
  if (projectResult.actualProfit) {
    res['actualProfit'] = projectResult.actualProfit
  }
  if (projectResult.expectedEarn) {
    res['expectedEarn'] = projectResult.expectedEarn
  }
  return res
}

function formattingDataSize(byteSize: number) {
  let size = byteSize
  let loopCnt = 0
  while (size / 1024 > 1) {
    size = size / 1024
    loopCnt++
  }

  const decimalFormatSize = +(size + Number.EPSILON).toFixed(2)

  switch (loopCnt) {
    case 6:
      return `${decimalFormatSize} EB` // exa byte
    case 5:
      return `${decimalFormatSize} PB` // peta byte
    case 4:
      return `${decimalFormatSize} TB` // tera byte
    case 3:
      return `${decimalFormatSize} GB` // giga byte
    case 2:
      return `${decimalFormatSize} MB` // mega byte
    case 1:
      return `${decimalFormatSize} KB` // kilo byte
    default:
      return `${decimalFormatSize} byte` // byte
  }
}

function taskErrorInfoLogText(alertItem: AlertLogType) {
  switch (alertItem.status) {
    case TASK_ALERT_STATUS.EXTRACT_DATA:
    case TASK_ALERT_STATUS.VALIDATE_DATA:
    case TASK_ALERT_STATUS.SORT_DATA:
    case TASK_ALERT_STATUS.DESCRIBE_DATA:
      return `dataset task was failed (project id: ${alertItem.projectId} / ${alertItem.status})`
    case TASK_ALERT_STATUS.CREATE_EXPERIMENT_PATH:
    case TASK_ALERT_STATUS.PREPROCESS_DATA:
    case TASK_ALERT_STATUS.TRAIN:
    case TASK_ALERT_STATUS.TEST:
    case TASK_ALERT_STATUS.PREPARE_DASHBOARD:
    case TASK_ALERT_STATUS.CREATE_SEGMENTS:
      return `project task (id: ${alertItem.projectId} / ${alertItem.status}) was failed`
    case TASK_ALERT_STATUS.FILE_UPLOAD:
      return `dataset upload was succeed (project id: ${alertItem.projectId})`
    default:
      return `project task (id: ${alertItem.projectId}) was succeed`
  }
}

export const util = {
  datasetCreateTaskProgress,
  trainingProjectTaskProgress,
  resultKPIOptionalvalue,
  formattingDataSize,
  taskErrorInfoLogText,
}
