import { CommonIdType } from './common.type'

export enum DatasetStatusEnum {
  NONE = 'NONE',
  VALIDATED = 'VALIDATED',
  DESCRIBED = 'DESCRIBED',
}

export enum FieldTypesEnum {
  NUMBER = 'NUMBER',
  CATEGORY = 'CATEGORY',
  DATE = 'DATE',
  TEXT = 'TEXT',
  NOTHING = 'NOTHING',
}

export enum DatasetTypesEnum {
  CUSTOMER = 'CUSTOMER',
  TRANSACTION = 'TRANSACTION',
  TARGET_CUSTOMER = 'TARGET_CUSTOMER',
  LOAN = 'LOAN',
  SHORT_TERM_LOAN = 'SHORT_TERM_LOAN',
  LONG_TERM_LOAN = 'LONG_TERM_LOAN',
  TRAIN = 'TRAIN',
  TEST = 'TEST',
  DASHBOARD = 'DASHBOARD',
}

export type DatasetType = {
  id: number
  filePath: string
  size: number
  originalFileName: string
  type: DatasetTypesEnum
  status: DatasetStatusEnum
  project: CommonIdType
  fields?: FieldType[]
  createdAt: string
  updatedAt: string
}

export type DatasetDictType = { [id: number]: DatasetType }

export type FieldType = {
  id: number
  createdAt: string
  updatedAt: string
  name: string
  type: FieldTypesEnum
  category: {
    values: string[]
  } | null
  number: {
    min: number
    max: number
  } | null
  date: {
    min: string
    max: string
    values: string[]
  } | null
}
