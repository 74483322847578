import { ProjectTypesEnum } from 'src/type/project.type'

export const ProjectTypesIntl: Record<
  ProjectTypesEnum,
  { name: string; title: string; defaultProjectName: string; description: string }
> = {
  [ProjectTypesEnum.CHURN_PREVENTION]: {
    name: `休眠顧客の予測`,
    title: `顧客の休眠防止`,
    defaultProjectName: `休眠防止プロジェクト`,
    description: `大切な顧客を失ってしまう前に事前に察知できるなら！Ready AIが休眠しそうな顧客を事前に予測します。`,
  },
  [ProjectTypesEnum.CROSS_SELLING]: {
    name: `他商品への加入予測`,
    title: `他商品への加入予測`,
    defaultProjectName: `他商品への加入ニーズ予測プロジェクト`,
    description: `自動車ローンやカードローン、投信等の商品を必要とする顧客が隠れていたら…。Ready AIを使って、顧客の隠れたニーズを探してみましょう!`,
  },
  [ProjectTypesEnum.UP_SELLING]: {
    name: `アップセルニーズ予測`,
    title: `アップセルニーズ予測`,
    defaultProjectName: `アップセルニーズ予測`,
    description: `さらなる利用ニーズがある顧客は誰か? Ready AIを活用し、各種サービスの残高を伸ばしましょう!`,
  },
  [ProjectTypesEnum.CORP_LOAN_DEMAND]: {
    name: `法人融資ニーズ予測`,
    title: `法人融資ニーズ予測`,
    defaultProjectName: `法人融資ニーズ予測プロジェクト`,
    description: `融資のニーズがある法人取引先が察知できるなら！Ready AIを使って、他行に先駆けて融資提案をしましょう！`,
  },
  [ProjectTypesEnum.CORP_SALES_INSIGHTS]: {
    name: `セールスインサイト`,
    title: `セールスインサイト`,
    defaultProjectName: `セールスインサイト`,
    description: `法人取引先、そして業種毎にどのようにアプローチすべきかを知ることができれば！Ready AIを利用して取引先と共に成長してみましょう！`,
  },
}

export const projectGoalIntl: Record<ProjectTypesEnum, { [key: string]: { prefix: string; suffix: string } }> = {
  [ProjectTypesEnum.CHURN_PREVENTION]: {
    targetNumberOfMonths: {
      prefix: '',
      suffix: 'カ月以上取引がない顧客を「休眠」と定義します。',
    },
    targetNumberOfSamples: {
      prefix: '',
      suffix: '名の顧客に対し、休眠防止施策を行います。',
    },
    expectedProfitPerSample: {
      prefix: '顧客1名あたりから得られる期待収益は',
      suffix: 'です。',
    },
    expenses: {
      prefix: 'この施策の予算は',
      suffix: 'です。',
    },
  },
  [ProjectTypesEnum.CROSS_SELLING]: {
    dateRange: {
      prefix: '学習に使うデータ期間',
      suffix: 'か月',
    },
    targetNumberOfMonths: {
      prefix: '',
      suffix: 'か月以内にクロスセル対象商品に加入する顧客をターゲティングします。',
    },
    targetNumberOfSamples: {
      prefix: '',
      suffix: '名の顧客を対象にプロモーションを実施します。',
    },
    expectedProfitPerSample: {
      prefix: '加入した顧客1名あたりから得られる期待収益は',
      suffix: 'です。',
    },
    expenses: {
      prefix: 'この施策の予算は',
      suffix: 'です。',
    },
  },
  [ProjectTypesEnum.UP_SELLING]: {
    targetNumberOfMonths: {
      prefix: '',
      suffix: 'か月以内にアップセルしそうな顧客をターゲティングします。',
    },
    targetNumberOfSamples: {
      prefix: '',
      suffix: '名の顧客を対象にアップセル促進の施策を実施します。',
    },
    minBalanceDifference: {
      prefix: '残高が',
      suffix: '以上増える顧客をターゲティングします。',
    },
    averageInterest: {
      prefix: 'このサービスを利用する顧客から得られる平均金利/手数料は',
      suffix: 'です。',
    },
    expenses: {
      prefix: 'この施策の予算は',
      suffix: 'です。',
    },
  },
  [ProjectTypesEnum.CORP_LOAN_DEMAND]: {
    targetNumberOfMonths: {
      prefix: '',
      suffix: 'か月以内に借入ニーズがある法人取引先をターゲティングします。',
    },
    targetNumberOfSamples: {
      prefix: '',
      suffix: '社を営業対象先としてリストアップします。',
    },
    dateRange: {
      prefix: '学習に使うデータ期間',
      suffix: 'か月',
    },
  },
  [ProjectTypesEnum.CORP_SALES_INSIGHTS]: {
    targetNumberOfMonths1: {
      prefix: '',
      suffix: 'か月以内に短期性融資の需要が見込まれる企業を探したい',
    },
    targetNumberOfMonths2: {
      prefix: '',
      suffix: 'か月以内に長期性融資の需要が見込まれる企業を探したい',
    },
    dateRange: {
      prefix: '学習に使うデータ期間',
      suffix: 'か月',
    },
  },
}
