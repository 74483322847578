import { last } from 'lodash'
import { DashboardCompanyDetailPartnerType } from 'src/type/dashboard.type'

export const demoData: DashboardCompanyDetailPartnerType[] = [
  { unitPeriod: '3', comparison: 'samePeriod12mAgo', partnersName: 'ｲｼﾊﾞｼｹﾝｾﾂ(ｶ', volume: 1200000, frequency: 1 },
  { unitPeriod: '3', comparison: 'samePeriod12mAgo', partnersName: 'ﾕ)ｼｷｼﾏﾓｸｻﾞｲｺｳｷﾞｮｳ', volume: 950000, frequency: 2 },
  { unitPeriod: '3', comparison: 'samePeriod12mAgo', partnersName: 'ｵｶﾀﾞﾓｸｻﾞｲ(ﾕ', volume: 245000, frequency: 1 },
  { unitPeriod: '3', comparison: 'samePeriod12mAgo', partnersName: 'ｱｶｵｼｮｳｼﾞ(ｶ', volume: 60000, frequency: 2 },
  { unitPeriod: '3', comparison: 'samePeriod12mAgo', partnersName: 'ｵｻﾞﾜｻﾝｼｮｳ(ｶ', volume: 38000, frequency: 3 },
  { unitPeriod: '3', comparison: 'samePeriod12mAgo', partnersName: 'ｵｶﾞｻﾜﾗｹﾝｾﾂ(ｶ', volume: 22000, frequency: 1 },
  { unitPeriod: '3', comparison: 'samePeriod12mAgo', partnersName: 'ﾏｴｷ(ｶ', volume: 19875, frequency: 1 },
  { unitPeriod: '3', comparison: 'samePeriod12mAgo', partnersName: 'ﾀｲｼｮｳﾄﾞｳ(ｶ', volume: 16500, frequency: 3 },
  { unitPeriod: '3', comparison: 'samePeriod12mAgo', partnersName: 'ｶ)ｷｽｷﾞ', volume: 15467, frequency: 1 },
  { unitPeriod: '3', comparison: 'samePeriod12mAgo', partnersName: 'ｶ)ﾜｰﾙﾄﾞ', volume: 14584, frequency: 2 },
  {
    unitPeriod: '3',
    comparison: 'samePeriod12mAgo',
    partnersName: 'ｶ) ｳｯﾄﾞｼｮｯﾌﾟｾｷｸﾞﾁ',
    volume: 1350000,
    frequency: 1,
    changeRate: 0.227272727272727,
    prevVolume: 1100000,
    prevFrequency: 1,
  },
  {
    unitPeriod: '3',
    comparison: 'samePeriod12mAgo',
    partnersName: 'ﾏﾙｷ (ｶ',
    volume: 1100000,
    frequency: 3,
    changeRate: -0.214285714285714,
    prevVolume: 1400000,
    prevFrequency: 4,
  },
  {
    unitPeriod: '3',
    comparison: 'samePeriod12mAgo',
    partnersName: 'ﾅｶﾔﾏｾｲｻﾞｲｼｮ(ﾕ',
    volume: 870000,
    frequency: 2,
    changeRate: 0.16,
    prevVolume: 750000,
    prevFrequency: 1,
  },
  {
    unitPeriod: '3',
    comparison: 'samePeriod12mAgo',
    partnersName: 'ﾌｼﾞｲﾓｸｻﾞｲ(ｶ',
    volume: 600000,
    frequency: 2,
    changeRate: 0,
    prevVolume: 600000,
    prevFrequency: 2,
  },
  {
    unitPeriod: '3',
    comparison: 'samePeriod12mAgo',
    partnersName: 'ｳｯﾄﾞﾜﾝ(ｶ',
    volume: 650000,
    frequency: 1,
    changeRate: 0.444444444444444,
    prevVolume: 450000,
    prevFrequency: 1,
  },
  {
    unitPeriod: '3',
    comparison: 'samePeriod12mAgo',
    partnersName: 'ﾕ)ｺｲｹｾｲｻﾞｲｼｮ',
    volume: 320000,
    frequency: 2,
    changeRate: -0.157894736842105,
    prevVolume: 380000,
    prevFrequency: 2,
  },
  {
    unitPeriod: '3',
    comparison: 'samePeriod12mAgo',
    partnersName: 'ﾖｼﾀﾞｻﾝｷﾞｮｳ(ｶ',
    volume: 255000,
    frequency: 1,
    changeRate: 0.287878787878788,
    prevVolume: 198000,
    prevFrequency: 1,
  },
  {
    unitPeriod: '3',
    comparison: 'samePeriod12mAgo',
    partnersName: 'ｽｽﾞｷｼｮｳｻﾞｲ(ｶ',
    volume: 170000,
    frequency: 1,
    changeRate: 0,
    prevVolume: 170000,
    prevFrequency: 1,
  },
  {
    unitPeriod: '3',
    comparison: 'samePeriod12mAgo',
    partnersName: 'ｶ) ｶﾝﾀﾞｾｲｻｸｼﾞｮ',
    volume: 155000,
    frequency: 3,
    changeRate: -0.0606060606060606,
    prevVolume: 165000,
    prevFrequency: 3,
  },
  {
    unitPeriod: '3',
    comparison: 'samePeriod12mAgo',
    partnersName: 'ｶ) ｻｻｷ',
    volume: 138000,
    frequency: 1,
    changeRate: 0,
    prevVolume: 138000,
    prevFrequency: 1,
  },
  { unitPeriod: '3', comparison: 'previous', partnersName: 'ｲｼﾊﾞｼｹﾝｾﾂ(ｶ', volume: 1200000, frequency: 1 },
  { unitPeriod: '3', comparison: 'previous', partnersName: 'ｳｯﾄﾞﾜﾝ(ｶ', volume: 650000, frequency: 3 },
  { unitPeriod: '3', comparison: 'previous', partnersName: 'ﾖｼﾀﾞｻﾝｷﾞｮｳ(ｶ', volume: 255000, frequency: 1 },
  { unitPeriod: '3', comparison: 'previous', partnersName: 'ｵｶﾀﾞﾓｸｻﾞｲ(ﾕ', volume: 245000, frequency: 1 },
  { unitPeriod: '3', comparison: 'previous', partnersName: 'ｶ)ｳｯﾄﾞｽﾄｯｸ', volume: 42300, frequency: 1 },
  { unitPeriod: '3', comparison: 'previous', partnersName: 'ﾅｶﾞﾇﾏｼｮｳｼﾞ(ｶ', volume: 32100, frequency: 1 },
  { unitPeriod: '3', comparison: 'previous', partnersName: 'ｶ)ﾀｲｼｮｳｹﾝｾﾂ', volume: 25400, frequency: 3 },
  { unitPeriod: '3', comparison: 'previous', partnersName: 'ﾏｴｷ(ｶ', volume: 19875, frequency: 1 },
  { unitPeriod: '3', comparison: 'previous', partnersName: 'ﾆﾎﾝｻﾝｼｮｳ(ｶ', volume: 16500, frequency: 1 },
  { unitPeriod: '3', comparison: 'previous', partnersName: 'ｶ)ﾜｰﾙﾄﾞ', volume: 14584, frequency: 2 },
  {
    unitPeriod: '3',
    comparison: 'previous',
    partnersName: 'ｶ) ｳｯﾄﾞｼｮｯﾌﾟｾｷｸﾞﾁ',
    volume: 1350000,
    frequency: 1,
    changeRate: -0.15625,
    prevVolume: 1600000,
    prevFrequency: 1,
  },
  {
    unitPeriod: '3',
    comparison: 'previous',
    partnersName: 'ﾏﾙｷ (ｶ',
    volume: 1100000,
    frequency: 3,
    changeRate: -0.388888888888889,
    prevVolume: 1800000,
    prevFrequency: 5,
  },
  {
    unitPeriod: '3',
    comparison: 'previous',
    partnersName: 'ﾕ)ｼｷｼﾏﾓｸｻﾞｲｺｳｷﾞｮｳ',
    volume: 950000,
    frequency: 2,
    changeRate: 0.0555555555555556,
    prevVolume: 900000,
    prevFrequency: 2,
  },
  {
    unitPeriod: '3',
    comparison: 'previous',
    partnersName: 'ﾅｶﾔﾏｾｲｻﾞｲｼｮ(ﾕ',
    volume: 870000,
    frequency: 2,
    changeRate: 0,
    prevVolume: 870000,
    prevFrequency: 2,
  },
  {
    unitPeriod: '3',
    comparison: 'previous',
    partnersName: 'ﾌｼﾞｲﾓｸｻﾞｲ(ｶ',
    volume: 600000,
    frequency: 2,
    changeRate: 0,
    prevVolume: 600000,
    prevFrequency: 2,
  },
  {
    unitPeriod: '3',
    comparison: 'previous',
    partnersName: 'ﾕ)ｺｲｹｾｲｻﾞｲｼｮ',
    volume: 320000,
    frequency: 2,
    changeRate: 0,
    prevVolume: 320000,
    prevFrequency: 2,
  },
  {
    unitPeriod: '3',
    comparison: 'previous',
    partnersName: 'ｽｽﾞｷｼｮｳｻﾞｲ(ｶ',
    volume: 170000,
    frequency: 1,
    changeRate: 0,
    prevVolume: 170000,
    prevFrequency: 1,
  },
  {
    unitPeriod: '3',
    comparison: 'previous',
    partnersName: 'ｶ) ｶﾝﾀﾞｾｲｻｸｼﾞｮ',
    volume: 155000,
    frequency: 3,
    changeRate: 0.148148148148148,
    prevVolume: 135000,
    prevFrequency: 3,
  },
  {
    unitPeriod: '3',
    comparison: 'previous',
    partnersName: 'ｶ) ｻｻｷ',
    volume: 138000,
    frequency: 1,
    changeRate: 0,
    prevVolume: 138000,
    prevFrequency: 1,
  },
  {
    unitPeriod: '3',
    comparison: 'previous',
    partnersName: 'ｱｶｵｼｮｳｼﾞ(ｶ',
    volume: 60000,
    frequency: 1,
    changeRate: -0.14,
    prevVolume: 112300,
    prevFrequency: 2,
  },
]

export const totalData = {
  lastYearTotal: {
    new: {
      volume: 2685410,
      frequency: 25,
    },
    exist: {
      volume: 5844590,
      prevVolume: 7327120,
      frequency: 35,
      prevFrequency: 32,
    },
  },
  previousTotal: {
    new: {
      volume: 2602100,
      frequency: 20,
    },
    exist: {
      volume: 5844590,
      prevVolume: 18919210,
      frequency: 35,
      prevFrequency: 55,
    },
  },
}
