import classNames from 'classnames'
import React, { useEffect, useMemo, useState } from 'react'
import InlineSVG from 'react-inlinesvg'
import { useDispatch, useSelector } from 'react-redux'
import { CellRenderProps, DataTable, DataTableColumnDefsType } from 'src/component/molecule/data-table/data-table'
import { DropdownSingleSelector } from 'src/component/molecule/dropdown-single-selector/dropdown-single-selector'
import { Tab } from 'src/component/molecule/tab/tab'
import { dashboardCompanyDataIntl } from 'src/intl/data/dashboard.key'
import { CompanyDetailSideModal } from 'src/modal/company-detail/company-detail.modal'
import { branchSelector } from 'src/redux/branch/branch.state'
import { dashboardAction } from 'src/redux/dashboard/dashboard.action'
import { dashboardSelector } from 'src/redux/dashboard/dashboard.state'
import IconDownload from 'src/style/icon/icon-download.svg'
import { OptionType } from 'src/type/common.type'
import styles from './sales-insight-info.scss'

export const SalesInsightInfo = () => {
  const dispatch = useDispatch()

  const [detailModalVisible, setDetailModalVisible] = useState<string>()
  const [selectedTabItem, setSelectedTabItem] = useState<OptionType<string>>(tabItems[0])

  const selectedCompanyBranchCode = useSelector(dashboardSelector.selectedCompanyBranchCode)
  const dashboardCompany = useSelector(dashboardSelector.dashboardCompanies)
  const dashboardCompanyRecords = useSelector(dashboardSelector.dashboardCompanyRecords)
  const branchCodeOptions = useSelector(dashboardSelector.dsCompanyBranchCodeOptions)
  const branchRecordsByCode = useSelector(branchSelector.branchRecordsByCode)
  const selectedBranchCode = useSelector(dashboardSelector.selectedCompanyBranchCode)
  const dashboardCompanyPage = useSelector(dashboardSelector.dashboardCompanyPage)
  const dashboardCompanyGradeDatas = useSelector(dashboardSelector.dashboardCompanyGradeDatas)
  const dashboardCompanyGradeInfoRecords = useSelector(dashboardSelector.dashboardCompanyGradeInfoRecords)

  const customerBranchCodeOptions = useMemo(() => {
    return branchCodeOptions.map<OptionType>((bc) => ({
      label:
        branchRecordsByCode[bc.value]?.branchName ||
        (bc.value === 'allBranches' ? dashboardCompanyDataIntl['allBranches'] : bc.value),
      value: bc.value,
    }))
  }, [branchCodeOptions, branchRecordsByCode])

  const selectedBranchCodeOption = useMemo(
    () => customerBranchCodeOptions.find((op) => op.value === selectedBranchCode),
    [customerBranchCodeOptions, selectedBranchCode],
  )

  const dataColumnDefs = useMemo(
    () =>
      companyDataColumnDefObjs.map((column) => ({
        ...column,
        cellRenderer:
          column.dataKey === 'companyName'
            ? (cell: CellRenderProps) => (
                <div className={styles.companyLink} onClick={() => setDetailModalVisible(`${cell.rowData.id}`)}>
                  {cell.cellData}
                </div>
              )
            : undefined,
      })),
    [companyDataColumnDefObjs],
  )

  const companyDataList = useMemo(() => {
    return dashboardCompany?.map((c) => ({
      id: c.id,
      companyName: c.companyName,
      postCode: c.postCode,
      isMainBank: c.isMainBank === 'true' ? 'あり' : c.isMainBank === 'false' ? 'なし' : '-',
      loan1Grade: c.loan1Grade,
      loan2Grade: c.loan2Grade,
      mnaGrade: '-',
      successionGrade: '-',
      ITSubsidyGrade: '-',
      numSalesMotives: c.numSalesMotives && ['None', 'null'].includes(c.numSalesMotives) ? '-' : c.numSalesMotives,
    }))
  }, [dashboardCompany])

  useEffect(() => {
    dispatch(dashboardAction.fetchDashboardCompanyGradeCumulativeRates.request())
    dispatch(dashboardAction.fetchDashboardCompanyGradeInfos.request())
  }, [])

  useEffect(() => {
    if (selectedCompanyBranchCode) {
      dispatch(dashboardAction.setDashboardCompanyListPage(1))
      dispatch(
        dashboardAction.fetchDashboardCompanyList.request({
          branchCode: selectedCompanyBranchCode,
          isMainBank: selectedTabItem.value,
          page: 1,
          pageSize: 20,
        }),
      )
      dispatch(
        dashboardAction.fetchDashboardCompanyGradeDatas.request({
          branchCode: selectedCompanyBranchCode,
          isMainBank: selectedTabItem.value,
        }),
      )
    }
  }, [selectedCompanyBranchCode, selectedTabItem])

  function handleChangeSelectBranchCode(option: OptionType<string>) {
    dispatch(dashboardAction.setSelectedCompanyBranchCode(option.value))
  }

  function handleDownloadButtonClick() {
    dispatch(dashboardAction.downloadDsCompanyList.request({ branchCode: selectedBranchCode }))
  }

  function handleClickLoadMore() {
    dispatch(dashboardAction.setDashboardCompanyListPage(dashboardCompanyPage + 1))
    dispatch(
      dashboardAction.fetchDashboardCompanyList.request({
        branchCode: selectedBranchCode,
        isMainBank: selectedTabItem.value,
        page: dashboardCompanyPage + 1,
        pageSize: 20,
      }),
    )
  }

  return (
    <div className={styles.root}>
      <div className={styles.tabWrapper}>
        <Tab items={tabItems} activeItem={selectedTabItem} onChange={setSelectedTabItem} />
        <div className={styles.tabContentHeaderController}>
          <div>{`支店名`}</div>
          <DropdownSingleSelector
            sizeType='lg'
            isNeededFilter
            options={customerBranchCodeOptions}
            placeholder={branchCodeOptions.length === 1 ? 'No Branches' : 'Select Branch'}
            disabled={branchCodeOptions.length === 1}
            selectedOption={selectedBranchCodeOption}
            onChange={handleChangeSelectBranchCode}
          />
        </div>
      </div>
      <div className={styles.gradeWrapper}>
        <div className={styles.grades}>
          <div className={styles.gradeOrderHeader}>{`等級`}</div>
          {[10, 9, 8, 7, 6, 5, 4, 3, 2, 1].map((gradeNo, idx) => (
            <div
              key={gradeNo}
              className={classNames(styles.gradeOrderRow, {
                [styles.gradeRowEven]: idx % 2 === 1,
                [styles.gradeRowOdd]: idx % 2 === 0,
              })}
            >
              {gradeNo}
            </div>
          ))}
        </div>
        <div className={styles.grades}>
          <div className={classNames(styles.gradeHeader, styles.grade1Color)}>
            <div>{`短期融資`}</div>
            <div>{`期待成約率`}</div>
            <div>{`該当数(割合)`}</div>
          </div>
          {dashboardCompanyGradeDatas?.loan1Datas
            ? dashboardCompanyGradeDatas.loan1Datas.map((gradeData, idx) => (
                <div
                  key={gradeData.grade}
                  className={classNames(styles.gradeRow, {
                    [styles.gradeRowEven]: idx % 2 === 1,
                    [styles.gradeRowOdd]: idx % 2 === 0,
                  })}
                >
                  <div className={styles.fixedColumnColor}>
                    {dashboardCompanyGradeInfoRecords?.shortTermGrades[gradeData.grade]?.expectedRatio !== null ||
                    dashboardCompanyGradeInfoRecords?.shortTermGrades[gradeData.grade]?.expectedRatio !== undefined
                      ? `${new Intl.NumberFormat('ko-KR', {
                          maximumFractionDigits: 2,
                        }).format(
                          Number(dashboardCompanyGradeInfoRecords?.shortTermGrades[gradeData.grade]?.expectedRatio) *
                            100,
                        )} %`
                      : '-'}
                  </div>
                  <div>{`${new Intl.NumberFormat('ko-KR', {
                    maximumFractionDigits: 2,
                  }).format(Number(gradeData.count))} (${gradeData.percentage ?? 0} %)`}</div>
                </div>
              ))
            : [1, 2, 3, 4, 5, 6, 7, 8, 9, 10].map((gradeData, idx) => (
                <div
                  key={gradeData}
                  className={classNames(styles.gradeRow, {
                    [styles.gradeRowEven]: idx % 2 === 1,
                    [styles.gradeRowOdd]: idx % 2 === 0,
                  })}
                >
                  <div className={styles.fixedColumnColor}>{'-'}</div>
                  <div>{'-'}</div>
                </div>
              ))}
        </div>
        <div className={styles.grades}>
          <div className={classNames(styles.gradeHeader, styles.grade2Color)}>
            <div>{`長期融資`}</div>
            <div>{`期待成約率`}</div>
            <div>{`該当数(割合)`}</div>
          </div>
          {dashboardCompanyGradeDatas?.loan2Datas
            ? dashboardCompanyGradeDatas.loan2Datas.map((gradeData, idx) => (
                <div
                  key={gradeData.grade}
                  className={classNames(styles.gradeRow, {
                    [styles.gradeRowEven]: idx % 2 === 1,
                    [styles.gradeRowOdd]: idx % 2 === 0,
                  })}
                >
                  <div className={styles.fixedColumnColor}>
                    {dashboardCompanyGradeInfoRecords?.longTermGrades[gradeData.grade]?.expectedRatio !== null ||
                    dashboardCompanyGradeInfoRecords?.longTermGrades[gradeData.grade]?.expectedRatio !== undefined
                      ? `${new Intl.NumberFormat('ko-KR', {
                          maximumFractionDigits: 2,
                        }).format(
                          Number(dashboardCompanyGradeInfoRecords?.longTermGrades[gradeData.grade]?.expectedRatio) *
                            100,
                        )} %`
                      : '-'}
                  </div>
                  <div>{`${new Intl.NumberFormat('ko-KR', {
                    maximumFractionDigits: 2,
                  }).format(Number(gradeData.count))} (${gradeData.percentage ?? 0} %)`}</div>
                </div>
              ))
            : [1, 2, 3, 4, 5, 6, 7, 8, 9, 10].map((gradeData, idx) => (
                <div
                  key={gradeData}
                  className={classNames(styles.gradeRow, {
                    [styles.gradeRowEven]: idx % 2 === 1,
                    [styles.gradeRowOdd]: idx % 2 === 0,
                  })}
                >
                  <div className={styles.fixedColumnColor}>{'-'}</div>
                  <div>{'-'}</div>
                </div>
              ))}
        </div>
        <div className={styles.grades}>
          <div className={classNames(styles.gradeHeader, styles.grade3Color)}>
            <div>{`M&A`}</div>
            <div>{`期待成約率`}</div>
            <div>{`該当数(割合)`}</div>
          </div>
          {dashboardCompanyGradeDatas?.mnaDatas
            ? dashboardCompanyGradeDatas.mnaDatas.map((gradeData, idx) => (
                <div
                  key={gradeData.grade}
                  className={classNames(styles.gradeRow, {
                    [styles.gradeRowEven]: idx % 2 === 1,
                    [styles.gradeRowOdd]: idx % 2 === 0,
                  })}
                >
                  <div className={styles.fixedColumnColor}>{gradeData.expectedRatio ?? '-'}</div>
                  <div>{`${gradeData.count} (${gradeData.percentage} %)`}</div>
                </div>
              ))
            : [1, 2, 3, 4, 5, 6, 7, 8, 9, 10].map((gradeData, idx) => (
                <div
                  key={gradeData}
                  className={classNames(styles.gradeRow, {
                    [styles.gradeRowEven]: idx % 2 === 1,
                    [styles.gradeRowOdd]: idx % 2 === 0,
                  })}
                >
                  <div className={styles.fixedColumnColor}>{'-'}</div>
                  <div>{'-'}</div>
                </div>
              ))}
        </div>
        <div className={styles.grades}>
          <div className={classNames(styles.gradeHeader, styles.grade4Color)}>
            <div>{`事業承継`}</div>
            <div>{`期待成約率`}</div>
            <div>{`該当数(割合)`}</div>
          </div>
          {dashboardCompanyGradeDatas?.successionDatas
            ? dashboardCompanyGradeDatas.successionDatas.map((gradeData, idx) => (
                <div
                  key={gradeData.grade}
                  className={classNames(styles.gradeRow, {
                    [styles.gradeRowEven]: idx % 2 === 1,
                    [styles.gradeRowOdd]: idx % 2 === 0,
                  })}
                >
                  <div className={styles.fixedColumnColor}>{gradeData.expectedRatio ?? '-'}</div>
                  <div>{`${gradeData.count} (${gradeData.percentage} %)`}</div>
                </div>
              ))
            : [1, 2, 3, 4, 5, 6, 7, 8, 9, 10].map((gradeData, idx) => (
                <div
                  key={gradeData}
                  className={classNames(styles.gradeRow, {
                    [styles.gradeRowEven]: idx % 2 === 1,
                    [styles.gradeRowOdd]: idx % 2 === 0,
                  })}
                >
                  <div className={styles.fixedColumnColor}>{'-'}</div>
                  <div>{'-'}</div>
                </div>
              ))}
        </div>
        <div className={styles.grades}>
          <div className={classNames(styles.gradeHeader, styles.grade5Color)}>
            <div>{`補助金`}</div>
            <div>{`期待成約率`}</div>
            <div>{`該当数(割合)`}</div>
          </div>
          {dashboardCompanyGradeDatas?.ITSubsidyDatas
            ? dashboardCompanyGradeDatas.ITSubsidyDatas.map((gradeData, idx) => (
                <div
                  key={gradeData.grade}
                  className={classNames(styles.gradeRow, {
                    [styles.gradeRowEven]: idx % 2 === 1,
                    [styles.gradeRowOdd]: idx % 2 === 0,
                  })}
                >
                  <div className={styles.fixedColumnColor}>{gradeData.expectedRatio ?? '-'}</div>
                  <div>{`${gradeData.count} (${gradeData.percentage} %)`}</div>
                </div>
              ))
            : [1, 2, 3, 4, 5, 6, 7, 8, 9, 10].map((gradeData, idx) => (
                <div
                  key={gradeData}
                  className={classNames(styles.gradeRow, {
                    [styles.gradeRowEven]: idx % 2 === 1,
                    [styles.gradeRowOdd]: idx % 2 === 0,
                  })}
                >
                  <div className={styles.fixedColumnColor}>{'-'}</div>
                  <div>{'-'}</div>
                </div>
              ))}
        </div>
      </div>
      <div className={styles.gradeDescription}>
        {`※ 上記、期待成約率はReady AI内の検証データ上の結果であり、実運用時の成約率を約束するものではありません。あくまでどの程度の成約可能性があるかの参考情報としてご利用ください。`}
      </div>
      <div className={styles.tableHeader}>
        <div className={styles.downloadButton} onClick={handleDownloadButtonClick}>
          <span>{`企業リストダウンロード`}</span>
          <div className={styles.downloadIconWrapper}>
            <InlineSVG className={styles.downloadIcon} src={IconDownload} />
          </div>
        </div>
      </div>
      <div className={styles.tableWrapper}>
        <DataTable
          dataColumnDefs={dataColumnDefs}
          dataList={companyDataList || []}
          theme='light'
          headerHeight={70}
          rowHeight={70}
          rowSeperator={false}
          tableHeight={400}
          colorizedRow
          onClickLastRow={handleClickLoadMore}
        />
      </div>
      {detailModalVisible && (
        <CompanyDetailSideModal
          modalVisible={!!detailModalVisible}
          dashboardCompany={dashboardCompanyRecords[+detailModalVisible]}
          onClose={() => setDetailModalVisible(undefined)}
        />
      )}
    </div>
  )
}

const companyDataColumnDefObjs: DataTableColumnDefsType[] = [
  {
    dataKey: 'companyName',
    label: '会社名',
    flexRate: 1,
    headerBold: true,
  },
  {
    dataKey: 'postCode',
    label: '会社所在地',
    flexRate: 1,
    headerBold: true,
  },
  {
    dataKey: 'isMainBank',
    label: '直近返済実績',
    width: 125,
    headerBold: true,
  },
  {
    dataKey: 'loan1Grade',
    label: '短期融資ニーズ等級',
    width: 170,
    headerBold: true,
  },
  {
    dataKey: 'loan2Grade',
    label: '長期融資ニーズ等級',
    width: 170,
    headerBold: true,
  },
  {
    dataKey: 'mnaGrade',
    label: 'M&Aニーズ等級',
    width: 170,
    headerBold: true,
  },
  {
    dataKey: 'successionGrade',
    label: '事業承継ニーズ等級',
    width: 170,
    headerBold: true,
  },
  {
    dataKey: 'ITSubsidyGrade',
    label: '補助金ニーズ等級',
    width: 170,
    headerBold: true,
  },
  {
    dataKey: 'numSalesMotives',
    label: '営業のネタ',
    width: 110,
    headerBold: true,
  },
]

const tabItems: OptionType<string>[] = [
  {
    label: '融資取引あり',
    value: 'true',
  },
  {
    label: '融資取引なし',
    value: 'false',
  },
]
